export function exceptionInit() {
    if (process.env.NODE_ENV == "development") {
        window.addEventListener('error', (event) => {
            console.log(`event caught in console = ${event.type}:${event.message}`);
        })
    } else {
        window.addEventListener('error', (event) => {
            //TODO: Send crash report to sentry and show service down popup;
            console.log(`event caught in console = ${event.type}:${event.message}`);
        })
    }
}