export const GET_DIT_REPORT_LIST = "GET_DIT_REPORT_LIST";
export const GET_DIT_REPORT_LIST_RESPONSE = "GET_DIT_REPORT_LIST_RESPONSE";
export const GET_DIT_REPORT_LIST_FAILED = "GET_DIT_REPORT_LIST_FAILED";

export const SUBMIT_DIT_REPORT = "SUBMIT_DIT_REPORT";
export const SUBMIT_DIT_REPORT_RESPONSE = "SUBMIT_DIT_REPORT_RESPONSE";
export const SUBMIT_DIT_REPORT_FAILED = "SUBMIT_DIT_REPORT_FAILED";

export const ADD_REPORT_ISSUE = "ADD_REPORT_ISSUE";
export const ADD_REPORT_ISSUE_RESPONSE = "ADD_REPORT_ISSUE_RESPONSE";
export const ADD_REPORT_ISSUE_FAILED = "ADD_REPORT_ISSUE_FAILED";

export const ADD_SUGGESTION = "ADD_SUGGESTION";
export const ADD_SUGGESTION_RESPONSE = "ADD_SUGGESTION_RESPONSE";
export const ADD_SUGGESTION_FAILED = "ADD_SUGGESTION_FAILED";

export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_RESPONSE = "FILE_UPLOAD_RESPONSE";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";
