import { takeLatest, put, call, delay, select, takeEvery } from 'redux-saga/effects';
import {
    PASSIVE_CATEGORY_PENDING, PASSIVE_CATEGORY_SUCCESS, PASSIVE_CATEGORY_FAILURE, PASSIVE_DETAIL_PENDING, PASSIVE_DETAIL_SUCCESS, PASSIVE_DETAIL_FAILURE
} from '../redux/types/preloginType';

import Api from '../services/preLoginApi';

let allApi = new Api();
function CreateDataSet(dropFilter, staticData) {
    let allKey = Object.keys(dropFilter);
    let modifyArray = [];

    allKey.forEach(element => {
        let arrayData = [];
        staticData[element].map((curr) => {
            arrayData.push(curr.fundoptionmerge)
            if (curr.fundoptionmerge === dropFilter[element]) {
                modifyArray.push({ ...curr, selectBox: arrayData })
            }

        })
    });
    // console.log(modifyArray, 'modifyArray')
    return modifyArray;
}

function* getCatData() {
    let payload = {
        "api_name": "FundCategory",
        "categoryType": "IF"
    };
    let apiresponse = yield allApi.Category(payload);
    console.log(apiresponse, 'passiveFund')
    if (apiresponse.data.success) {
        //let dataset = apiresponse.data.data;
        let dataset = {
            "BI": [
                {
                    "schid": 1,
                    "amccode": "127",
                    "schcode": "BI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Bank Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 1,
                    "prodcode": "BIGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 569755000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 569755000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 1,
                    "amccode": "127",
                    "schcode": "BI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Bank Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 2,
                    "prodcode": "BIGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 288375000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 288375000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ],
            "MI": [
                {
                    "schid": 14,
                    "amccode": "127",
                    "schcode": "MI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Midcap 150 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 68,
                    "prodcode": "MIGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 636123000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 636123000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 14,
                    "amccode": "127",
                    "schcode": "MI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Midcap 150 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 69,
                    "prodcode": "MIGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 276385000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 276385000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ],
            "NI": [
                {
                    "schid": 17,
                    "amccode": "127",
                    "schcode": "NI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty 50 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 73,
                    "prodcode": "NIGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 285296000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 285296000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 17,
                    "amccode": "127",
                    "schcode": "NI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty 50 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 74,
                    "prodcode": "NIGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 235667000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 235667000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ],
            "NN": [
                {
                    "schid": 18,
                    "amccode": "127",
                    "schcode": "NN",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Next 50 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 75,
                    "prodcode": "NNGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 347645000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 347645000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 18,
                    "amccode": "127",
                    "schcode": "NN",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Next 50 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 76,
                    "prodcode": "NNGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 157157000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 157157000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ],
            "NT": [
                {
                    "schid": 19,
                    "amccode": "127",
                    "schcode": "NT",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty 500 Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 77,
                    "prodcode": "NTGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 463468000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 463468000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 19,
                    "amccode": "127",
                    "schcode": "NT",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty 500 Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 78,
                    "prodcode": "NTGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 374382000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 374382000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ],
            "SI": [
                {
                    "schid": 20,
                    "amccode": "127",
                    "schcode": "SI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Smallcap 250 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 79,
                    "prodcode": "SIGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 635908000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 635909000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 20,
                    "amccode": "127",
                    "schcode": "SI",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "Nifty Smallcap 250 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 80,
                    "prodcode": "SIGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 237921000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 237921000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ],
            "SP": [
                {
                    "schid": 21,
                    "amccode": "127",
                    "schcode": "SP",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "S and P 500 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Direct - Growth",
                    "navid": 81,
                    "prodcode": "SPGD",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 4168610000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 4168610000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Direct",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                },
                {
                    "schid": 21,
                    "amccode": "127",
                    "schcode": "SP",
                    "amcname": "Motilal Oswal Mutual Fund",
                    "schname": "S and P 500 Index Fund",
                    "fundcategory": "Index Fund",
                    "sebicategory": "Equity Fund",
                    "fundoptionmerge": "Regular - Growth",
                    "navid": 82,
                    "prodcode": "SPGP",
                    "nav": 0,
                    "navrecdt": "2020-12-21",
                    "navchng": 1.2,
                    "latestaum": 3027000000,
                    "latestaumasondt": "2021-01-15",
                    "monthlyaum": 3027000000,
                    "monthlyaumasondt": "2020-12-31",
                    "plan": "Regular",
                    "objective": "Wealth Creation",
                    "risk": "Very High",
                    "fundoption": "Growth",
                    "divfreq": null,
                    "fundtype": "Open Ended",
                    "marketcap": null,
                    "schdesc": "This is Scheme Description"
                }
            ]
        };
        console.log(dataset, 'datasetDaga')
        let enObj = {};
        for (let [key, value] of Object.entries(dataset)) {
            value.map((curr, index) => {
                if (curr.schcode === key) {
                    enObj[key] = curr.fundoptionmerge;

                }
            })

            // SetDropFilter(enObj)
        }
        yield delay(100)
        let modiFyData = CreateDataSet(enObj, dataset)
        console.log(enObj, 'enObjSaga', modiFyData)

        //yield put(MFCatSuccuess(dataset))
        yield put({ type: PASSIVE_CATEGORY_SUCCESS, payload: { dropdown: enObj, modifyCat: modiFyData, CatData: dataset } })
    } else {
        yield put({ type: PASSIVE_CATEGORY_FAILURE, payload: { dropdown: {}, modifyCat: [], CatData: null } })
    }
}


export function* PassiveCategory() {
    yield takeLatest(PASSIVE_CATEGORY_PENDING, getCatData)
}


// passiveDetail
async function getFetch(data) {
    console.log(data, 'detailpassive')
    let ResData = await allApi.fundDetail(data);
    return ResData
}


function* fetchData(action) {
    console.log('mfDetailSaga', action)
    let payload = action.paylaod;
    console.log(payload, "passivepayload")
    let apiresponse = yield call(getFetch, payload);
    console.log(apiresponse, 'apiresponse')
    if (apiresponse.data.success) {
        yield put({ type: PASSIVE_DETAIL_SUCCESS, payload: apiresponse.data.data })
    } else {
        yield put({ type: PASSIVE_DETAIL_FAILURE, payload: null })
    }
    // yield put({ type: ABOUTUS_DATA_SUCCESS, payload: apiresponse.data })
}

export function* PassiveDetail() {
    console.info("PassiveDetail")
    yield takeLatest(PASSIVE_DETAIL_PENDING, fetchData)
}