export async function registerServiceWorker() {
  if (typeof window !== "undefined") {
    if (!("serviceWorker" in window.navigator)) {
      throw Error("Service workers are not supported by this browser");
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    const response = await navigator?.serviceWorker?.register(swUrl);
    console.log("response", response);
  }
}

export async function getReadyServiceWorker() {
  if (typeof window !== "undefined") {
    if (!("serviceWorker" in window.navigator)) {
      throw Error("Service workers are not supported by this browser");
    }

    return navigator.serviceWorker.ready;
  }
}
