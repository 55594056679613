import { takeLatest, put } from 'redux-saga/effects';
import { SET_BANK_DETAILS, SET_FOLIO_DETAILS, GET_FOLIO_DETAILS, SET_FOLIO_VALUES } from '../redux/types/manageBank'
import Api from '../services/trans/trans'

let allApi = new Api();

export function* setBankDetails(data) {
    yield put({ type: SET_BANK_DETAILS, payload: data })
}

export function* setFolioValue(data) {
    yield put({ type: SET_FOLIO_VALUES, payload: data })
}

function* fetchData(data) {
    let apiresponse = yield allApi.commonGet(data.payload)
    yield put({ type: SET_FOLIO_DETAILS, payload: apiresponse.data.data })
}

export function* getFolioDetails() {
    yield takeLatest(GET_FOLIO_DETAILS, fetchData)
}