import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router';
import UserApi from "../services/userApi";
import {
    INIT_APP_FAIL, VARIFY_OTP_RESPONSE, VARIFY_OTP_RESPONSE_FAIL,
    MENU_ROLE_WISE_RESPONSE, MENU_ROLE_WISE_RESPONSE_FAIL

} from '../redux/types/initApp';
// import { AUTHENTICATE_USER_RESPONSE } from '../redux/types/authenticate';
let api = new UserApi();

export function* initApp({ type, resolve, payload }) {
    let responsePayload = {};

    try {

        let { data } = yield api.init(payload);

        if (data.success) {
            console.log('response data in Init saga', data.data)
            // let response = data.data.pmsProducts;
            // responsePayload.pmsList = formatPMSList(response);
            // yield put({ type: INIT_APP_RESPONSE, payload: responsePayload })

            resolve && resolve()
        }

    } catch (e) {
        yield put({ type: INIT_APP_FAIL })
    }
}


export function* varifyOtp({ type, resolve, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield api.verifyOtp(payload);
        if (data.success) {
            yield put(push('/dashboard'));
            responsePayload = data.data;
            yield put({ type: VARIFY_OTP_RESPONSE, payload: responsePayload })

            resolve && resolve()
        }
    } catch (e) {
        yield put({ type: VARIFY_OTP_RESPONSE_FAIL })
    }
}




export function* menuRoleWise({ type, resolve, payload }) {
    let responsePayload = {};
    let List = [];
    console.log("menuRoleList saga", payload)
    try {
        let { data } = yield api.menuRoleWise(payload);
        if (data.success) {

            responsePayload = data.data;

            yield put({ type: MENU_ROLE_WISE_RESPONSE, payload: responsePayload })
            resolve && resolve(responsePayload)
        }

    } catch (e) {
        //  yield put(push('/'));
        console.log("menuRoleList error", e)
        yield put({ type: MENU_ROLE_WISE_RESPONSE_FAIL })
        resolve && resolve(e)

    }
}
// Utils

function formatPMSList(data) {
    let processedList = data.map(({ pms_Code, instrument_Name }) => {
        return { label: instrument_Name, value: pms_Code }
    })
    return processedList;
}