import { ABOUTUS_DATA_FAILURE, ABOUTUS_DATA_PENDING, ABOUTUS_DATA_SUCCESS } from '../types/preloginType';

let init = {
    aboutUs: null,
    loading: false,
    navStatus: false,
    navData: null
};

export default function AboutUs(state = init, action) {
    switch (action.type) {
        case ABOUTUS_DATA_PENDING:
            console.info("pending")
            return {
                ...state,
                loading: true,
            }

        case ABOUTUS_DATA_SUCCESS:
            console.info("success")
            return {
                ...state,
                loading: false,
                aboutUs: action.payload
            }

        case ABOUTUS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                aboutUs: action.payload
            }

        default:
            return state
    }
}