

import {
    VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE
} from '../types/redeemotp'


// const  ValidateRedeemOTP = (payload) => ({
//     type: VERIFY_OTP,
//     payload
// })
// export default ValidateRedeemOTP;
let init = {

    data: ""
};

export default function redeemOtp(state = init, action) {
    switch (action.type) {
        case VERIFY_OTP:
            console.info("pending")
            return {
                ...state,
                data: "PENDING",
            }

        case VERIFY_OTP_SUCCESS:
            console.info("success")
            return {
                ...state,
                data: action.payload,
                // aboutUs: action.payload
            }

        case VERIFY_OTP_FAILURE:
            return {
                ...state,
                data: "VALIDATE OTP FALIED",
                // aboutUs: action.payload
            }

        default:
            return state
    }
}