import { PASSIVE_CATEGORY_PENDING, PASSIVE_CATEGORY_SUCCESS, PASSIVE_CATEGORY_FAILURE, SINGLE_SELECTION_PASSIVE, PASSIVE_DETAIL_PENDING, PASSIVE_DETAIL_SUCCESS, PASSIVE_DETAIL_FAILURE } from '../types/preloginType';



let init = {
    passiveFund: null,
    passiveCustom: [],
    passiveDropDown: null,
    passiveCatStatus: false,
    passiveSingle: null,
    passiveDetail: null,
    passiveDetailStatus: false,
    catWiseFund: null,
    dropDownPassive: null,
    passiveList: null,


};

export default function PassiveDetail(state = init, action) {
    switch (action.type) {
        case PASSIVE_DETAIL_PENDING:

            return {
                ...state,
                passiveDetailStatus: true,
            }

        case PASSIVE_DETAIL_SUCCESS:
            console.log(action.payload, 'passive<==>fund')
            return {
                ...state,
                passiveDetailStatus: false,
                passiveDetail: action.payload,
                passiveList: action.payload
            }

        case PASSIVE_DETAIL_FAILURE:
            return {
                ...state,
                passiveDetailStatus: false,
                passiveDetail: action.payload,
                passiveList: action.payload
            }
        case PASSIVE_CATEGORY_PENDING:

            return {
                ...state,
                passiveCatStatus: true,
            }

        case PASSIVE_CATEGORY_SUCCESS:
            let { dropdown, modifyCat, CatData } = action.payload
            return {
                ...state,
                passiveCatStatus: false,
                passiveFund: CatData,
                passiveCustom: modifyCat,
                passiveDropDown: dropdown,
            }

        case PASSIVE_CATEGORY_FAILURE:
            return {
                ...state,
                passiveCatStatus: false,
                passiveFund: null,
                passiveCustom: [],
                passiveDropDown: null,
            }
        case SINGLE_SELECTION_PASSIVE:
            let { passiveFund } = { ...state };
            // let passiveDetailDrop = passiveFund[action.payload];
            // let modefy = []
            // passiveDetailDrop.map((curr) => {
            //     modefy.push({ label: curr.schname + "-" + curr.fundoptionmerge, value: curr.prodcode })
            // })
           // console.log(modefy, 'data')
            return {
                ...state,
                passiveSingle: action.payload,
                // catWiseFund: passiveDetailDrop,
                // dropDownPassive: modefy,
            }

        default:
            return state
    }
}