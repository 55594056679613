import { AUTHENTICATE_USER_RESPONSE, AUTHENTICATE_USER_FAILED } from "../types/authenticate";
import { LOGGED_IN_SUCCESS } from "../types/init";

const initialState = false;

export default function authenticateReducer(state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATE_USER_RESPONSE:
            // case LOGGED_IN_SUCCESS:
            return true;
        case AUTHENTICATE_USER_FAILED:
            return false;
        default:
            return state;
    }
}