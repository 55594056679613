import { put, call } from 'redux-saga/effects';
import appApi from "../services/appApi";
import { INIT_GET_FOLIO_LIST_RESPONSE, INIT_GET_FOLIO_LIST_FAILED } from '../redux/types/init';
import { AUTHENTICATE_USER_RESPONSE, AUTHENTICATE_USER_FAILED } from '../redux/types/authenticate';
import { push } from 'connected-react-router';
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError';
import { responseError } from '../helper/constant';


let api = new appApi();

export function* initGetFolioList({ type, payload }) {
    //console.log('init saga');

    let responsePayload = {};
    try {
        let { data } = yield api.initFolioList(payload);

        //console.log(data, 'yield folio data');

        if (data.success) {
            //console.log('sagaInit')
            responsePayload.folioList = formatFolioList(data.data);
            responsePayload.schemeList = formatSchemeList(data.data, responsePayload.folioList);
            yield put({ type: AUTHENTICATE_USER_RESPONSE })
            yield put({ type: INIT_GET_FOLIO_LIST_RESPONSE, payload: responsePayload })
            // yield put(push('/dashboard'));

        }
        else {
            yield put(push('/'));
            yield put({ type: AUTHENTICATE_USER_FAILED })
            yield put({ type: INIT_GET_FOLIO_LIST_FAILED })
        }
    } catch (e) {
        console.log('e', e)
        // yield put(push('/'));
        // yield put({ type: INIT_GET_FOLIO_LIST_FAILED })
        responsePayload = { open: true, severity: "error", message: responseError.requestFail }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

function formatFolioList(data) {
    let extracted = data.map(value => value.foliono)
    let removeDup = removeDuplicateFolio(extracted);
    let processedList = removeDup.map((list) => {
        let dematAccount = data.find(obj => obj.foliono === list).dematAccount;
        return { label: list, value: list, dematAccount }
    })
    return processedList;
}

function removeDuplicateFolio(data) {
    return data.filter((a, b) => data.indexOf(a) === b)
}

function formatSchemeList(serverObj, folioList) {
    let schemeObj = {};

    folioList.map((folioItem, id) => {

        schemeObj[folioItem.value] = [];

        serverObj.map((objItem, id) => {

            if (folioItem.value === objItem.foliono) {
                let schemeItemObj = { label: objItem.schemename, value: objItem.schemecode }
                schemeObj[folioItem.value].push(schemeItemObj)
            }
        })

    })

    return schemeObj;
}

export function* loggedInSuccess() {
    yield put({ type: AUTHENTICATE_USER_RESPONSE })
    yield put(push('/business-dashboard'));
}