import Api from '.';

export default class triggerAPI extends Api {

    triggerAPI(data, endpoint) {
        console.log('====endpoint',endpoint)
        let url = this.buildUrl(endpoint);    
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

}