import Api from '.';
import { endpoints } from './endpoints';

export default class LoginApi extends Api {

    generateOtp(data) {
        console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.login.generateOtp)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    verifyOtp(data) {
        let url = this.buildUrl(endpoints.login.verifyOtp)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    logout() {
        let url = this.buildUrl(endpoints.login.logout)
        return this.fetch(url, "POST").then(response => response);
    }
    common(data) {
        let url = this.buildUrl(endpoints.login.common, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    
    removeCookie(data) {
        let url = this.buildUrl(endpoints.logout.removeCookie);
        return this.fetch(url, "POST", JSON.stringify(data)).then(
            (response) => response
        );
    }
}