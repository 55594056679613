import Api from '.';
import { endpoints } from './endpoints';

export default class PreLoginApi extends Api {
    aboutUs(data) {
        let url = this.buildUrl(endpoints.preLogin.aboutUs, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    InterBusiness(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.internationalBusiness, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getFaq(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.faq, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    NavCall(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.NavDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    NavHistoricalfilter(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.HistoricalFilter, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    MFhome(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.MFHome, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    Category(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.FundCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    CategoryNew(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.FundCategoryNew, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getMFfilter(data) {

        let url = this.buildUrl(endpoints.preLogin.GetFundsForFilter, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    fundDetail(data) {
        // console.log("In generateOtp", data)


        let url = this.buildUrl(endpoints.preLogin.fundDetail, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    CompareFunds(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.CompareFunds, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    passiveHome(data) {
        // console.log("In generateOtp", data)

        let url = this.buildUrl(endpoints.preLogin.passiveHome, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    download(data) {

        let url = this.buildUrl(endpoints.preLogin.download, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    SearchDownloads(data) {
        let url = this.buildUrl(endpoints.preLogin.SearchDownloads, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    ContactUs(data) {
        let url = this.buildUrl(endpoints.preLogin.ContactUs, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    enquiry(data) {
        let url = this.buildUrl(endpoints.preLogin.enquiry, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    perforamceGraph(data) {
        let url = this.buildUrl(endpoints.preLogin.perforamceGraph, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }
    perforamceGraphNew(data) {
        let url = this.buildUrl(endpoints.preLogin.perforamceGraphNew, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

    STPCategory(data) {
        // console.log("In generateOtp", data)
        let url = this.buildUrl(endpoints.preLogin.STPCategory, 'full')
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response);
    }

}
