import {
    FABSCHEME_RESPONSE, FABSCHEME_RESPONSE_FAIL
    , FREQUENCYCALCULATOR_RESPONSE, FREQUENCYCALCULATOR_RESPONSE_FAIL
    , FABFOLIOLIST, INPUTCHANGE, INVESTMENTCALCULATOR, INVESTSCHEMEDATA, FOLIODETAILS_RESPONSE, FOLIODETAILS_RESPONSE_FAIL, BUCKETID, WITHDRAWPLOTTING_RESPONSE, WITHDRAWPLOTTING_RESPONSE_FAIL
} from "../types/fabPlan"

const initialState = {
    schemeSelection: {
        fabScheme: "",
    },
    investmentCal: {
        frequencyCalc: "",
        investmentCalculator: "",
        withdrawPlotting: ""
    },
    investmentSummary: {
        fabFolioList: "",
        folioDetails: "",
        bucketId: ""
    },
    fabInput: {
        fabInp: "",
    },
    investSchemeDetails: {
        investSchemeData: "",
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FABSCHEME_RESPONSE:
            return {
                ...state, schemeSelection: { ...state.schemeSelection, fabScheme: action.payload },
            }
        case FABSCHEME_RESPONSE_FAIL:
            return {
                ...state, schemeSelection: { ...state.schemeSelection, fabScheme: action.payload },
            }
        case FREQUENCYCALCULATOR_RESPONSE:
            return {
                ...state, investmentCal: { ...state.investmentCal, frequencyCalc: action.payload },
            }
        case FREQUENCYCALCULATOR_RESPONSE_FAIL:
            return {
                ...state, investmentCal: { ...state.investmentCal, frequencyCalc: action.payload },
            }
        case INVESTMENTCALCULATOR:
            return {
                ...state, investmentCal: { ...state.investmentCal, investmentCalculator: action.payload },
            }
        case WITHDRAWPLOTTING_RESPONSE:
            return {
                ...state, investmentCal: { ...state.investmentCal, withdrawPlotting: action.payload },
            }
        case WITHDRAWPLOTTING_RESPONSE_FAIL:
            return {
                ...state, investmentCal: { ...state.investmentCal, withdrawPlotting: action.payload },
            }    
        case FABFOLIOLIST:
            return {
                ...state, investmentSummary: { ...state.investmentSummary, fabFolioList: action.payload },
            }
        case INPUTCHANGE:
            return {
                ...state, fabInput: { ...state.fabInput, fabInp: action.payload },
            }
        case INVESTSCHEMEDATA:
            return {
                ...state, investSchemeDetails: { ...state.investSchemeDetails, investSchemeData: action.payload },
            }
        case FOLIODETAILS_RESPONSE:
            return {
                ...state, investmentSummary: { ...state.investmentSummary, folioDetails: action.payload },
            }
        case FOLIODETAILS_RESPONSE_FAIL:
            return {
                ...state, investmentSummary: { ...state.investmentSummary, folioDetails: action.payload },
            }
        case BUCKETID:
            return {
                ...state, investmentSummary: { ...state.investmentSummary, bucketId: action.payload },
            }
        default:
            return state
    }

}
