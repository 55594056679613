import * as type from '../../types/trans';


let init = {
    portfolioData: null,
    getAllPortfolio: [],
    schemeData: null,
    folioScheme: null,
    dropdown: null,
    foliowiseScheme: null,
    portfolioStatus: false,
    folio: null,
    schemeDropdownselect: "",
    optionCode: "",
    planCode: "",
    schemeCode: "",
    foloiDropdownSelect: "",
    holdingDropdownSelect: "",
    schemeSelected: "",
    singleData: null,
    newUserData: null,
    bankName: "",
    bankAcc: "",
    cartData: null,
    singleCartData: null,
    sipDashboard: null,
    sipDashboardStatus: false,
    stpDashboardStatus: false,
    swpDashboardStatus: false,
    swpDashboard: null,
    stpDashboard: null,
    statementData: null,
    holdingMode: null,
    processMode: null,
    transStatus: null,
    folioName: {},
    existingBox: true,
    zbfFolio: [],
    zbfLoader: false,
    guestMenu: false,
    stpDashboardLoader: false,
    swpDashboardLoader: false
};

let OptionSchemeCode = (getShemeCode) => {
    let optionCode = "";
    let planCode = "";
    let schemeCode = ""

    optionCode = getShemeCode.substr(7, 1);
    planCode = getShemeCode.substr(5, 2);
    schemeCode = getShemeCode.substr(3, 2);

    return { optionCode, planCode, schemeCode }


}

export default function PortfolioDetail(state = init, action) {


    switch (action.type) {

        case type.EXISTING_BOX:
            return {
                ...state,
                existingBox: action.payload
            }
        case type.GET_ALL_FOLIOS:
            return {
                ...state,
                zbfLoader: true

            }

        case type.ZBF_FOLIO_SUCCESS:
            return {
                ...state,
                zbfLoader: false,
                zbfFolio: action.payload
            }
        case type.ZBF_FOLIO_FAIL:
            return {
                ...state,
                zbfLoader: false,
                zbfFolio: action.payload
            }

        case type.NEW_FUND_SET:
            let { optionCode, planCode, schemeCode, foloiDropdownSelect, holdingDropdownSelect } = action.payload;
            return {
                ...state,
                optionCode: optionCode,
                planCode: planCode,
                schemeCode: schemeCode,
                foloiDropdownSelect: foloiDropdownSelect,
                holdingDropdownSelect: holdingDropdownSelect
            }

        case type.PORTFOLIO_DATA_PENDING:
            return {
                ...state,
                portfolioStatus: true,
            }

        case type.PORTFOLIO_DATA_SUCCESS:
            console.log(action.payload, 'PORTFOLIO_DATA_SUCCESS');
            let { dropdown, indexScheme, portfolioData, foliowiseScheme, schemeWiseFolio, portfolioAll, schemeData } = action.payload;
            console.log(dropdown, 'dropdown')
            let optionCode1 = null;
            let planCode1 = null;
            let schemeCode1 = null;
            let data = null;
            if (dropdown.length > 0) {
                data = dropdown[0].value;
                let getRes1 = OptionSchemeCode(data);
                optionCode1 = getRes1.optionCode;
                planCode1 = getRes1.planCode;
                schemeCode1 = getRes1.schemeCode;
            }
            console.log("portfolioAll", portfolioAll);

            return {
                ...state,
                getAllPortfolio: portfolioAll,
                portfolioStatus: false,
                portfolioData: portfolioData,
                folioScheme: indexScheme,
                dropdown: dropdown,
                foliowiseScheme: foliowiseScheme,
                folio: schemeWiseFolio,
                schemeDropdownselect: data,
                foloiDropdownSelect: "",
                optionCode: optionCode1,
                planCode: planCode1,
                schemeCode: schemeCode1,
                schemeData: schemeData,
                //singleData: dis
            }

        case type.PORTFOLIO_DATA_FAIL:
            // let { portfolioAll } = ;
            return {
                ...state,
                portfolioStatus: false,
                portfolioData: [],
                getAllPortfolio: action.payload.portfolioAll
            }

        case type.PORTFOLIO_CLEAR_REDUX:
            return {
                ...state,
                portfolioData: null,
                getAllPortfolio: [],
                foloiDropdownSelect: "",
                holdingDropdownSelect: "",
            }

        case type.PORTFOLIO_SCHEME_ACTION:
            // console.log(action.payload, 'actionclickReducer')
            let { folio, code } = action.payload;
            console.log(folio, '==Reducer', code);
            let getRess = OptionSchemeCode(code)
            // console.log('folioSujeet', folio, "code", code)
            return {
                ...state,
                schemeDropdownselect: code,
                foloiDropdownSelect: folio,
                optionCode: getRess.optionCode,
                planCode: getRess.planCode,
                schemeCode: getRess.schemeCode,

            }

        case type.PORTFOLIO_SINGLE:
            return {
                ...state,
                singleData: action.payload

            }
        case type.PORTFOLIO_SCHEME_CODE:
            let getShemeCode = action.payload;
            let getRes = OptionSchemeCode(getShemeCode)
            // console.log(getRes, 'getRessujeet', getShemeCode)

            return {
                ...state,
                schemeDropdownselect: action.payload,
                optionCode: getRes.optionCode,
                planCode: getRes.planCode,
                schemeCode: getRes.schemeCode,
                //singleData: dis
            }

        case type.PORTFOLIO_FOLIO_CODE:
            return {
                ...state,
                foloiDropdownSelect: action.payload,
                // singleData: dis
            }
        case type.PORTFOLIO_HOLDING_TYPE:
            return {
                ...state,
                holdingDropdownSelect: action.payload,
                // singleData: dis
            }
        case type.PORTFOLIO_SELECTED_BANK:
            return {
                ...state,
                bankName: action.payload.bankName,
                bankAcc: action.payload.bankAcc,

            }
        case type.ADD_TO_CART_PENDING:
            return {
                ...state,
            }
        case type.ADD_TO_CART_SUCCESS:
            return {
                ...state,
                cartData: action.payload
            }
        case type.ADD_TO_CART_FAILURE:
            return {
                ...state,
                cartData: action.payload
            }
        case type.SINGLE_CART_DATA:
            return {
                ...state,
                singleCartData: action.payload
            }

        case type.SIPDASHBOARD_PENDING:
            return {
                ...state,
                sipDashboard: null,
                sipDashboardStatus: true,
                holdingMode: null,
                processMode: null,
                transStatus: null
            }

        case type.SIPDASHBOARD_SUCCESS:
            return {
                ...state,
                sipDashboard: action.payload,
                sipDashboardStatus: true,
                holdingMode: action.holdingMode,
                processMode: action.processMode,
                transStatus: action.transStatus,
                statementData: action.statementData
            }

        case type.SIPDASHBOARD_FAILURE:
            return {
                ...state,
                sipDashboard: action.payload,
                sipDashboardStatus: false,
                holdingMode: null,
                processMode: null,
                transStatus: null
            }

        case type.SWPDASHBOARD_PENDING:
            return {
                ...state,
                swpDashboardStatus: true,
                swpDashboard: null,
                holdingMode: null,
                processMode: null,
                transStatus: null
            }

        case type.SWPDASHBOARD_SUCCESS:
            return {
                ...state,
                swpDashboardStatus: false,
                swpDashboard: action.payload,
                holdingMode: action.holdingMode,
                processMode: action.processMode,
                transStatus: action.transStatus
            }

        case type.SWPDASHBOARD_FAILURE:
            return {
                ...state,
                swpDashboardStatus: false,
                swpDashboard: action.payload,
                holdingMode: null,
                processMode: null,
                transStatus: null
            }

        case type.STPDASHBOARD_PENDING:
            return {
                ...state,
                stpDashboardLoader: true,
                stpDashboard: null,
                holdingMode: null,
                processMode: null,
                transStatus: null
            }

        case type.STPDASHBOARD_SUCCESS:
            return {
                ...state,
                stpDashboardLoader: false,
                stpDashboard: action.payload,
                holdingMode: action.holdingMode,
                processMode: action.processMode,
                transStatus: action.transStatus
            }

        case type.STPDASHBOARD_FAILURE:
            return {
                ...state,
                stpDashboardLoader: false,
                stpDashboard: action.payload,
                holdingMode: null,
                processMode: null,
                transStatus: null
            }

        case type.FOLIODESC_PENDING:
            return {
                ...state,
            }
        case type.FOLIODESC_SUCCESS:
            return {
                ...state,
                folioName: action.payload
            }
        case type.FOLIODESC_FAIL:
            return {
                ...state,
                folioName: action.payload
            }

        case type.NEW_USER_DATA:
            return {
                ...state,
                newUserData: action.payload
            }

        case type.UPDATE_GUEST_MENU:
            return {
                ...state,
                guestMenu: true,
                existingBox: false
            }
        case type.RESET_GUEST_MENU:
            return {
                ...state,
                guestMenu: false,
                existingBox: true
            }
        case type.RESET_SIP_DASHBOARD:
            return {
                ...state,
                sipDashboard: null,
            }
        default:
            return state
    }
}