import { ADD, RESET } from "../types/counter";

const initialState = {
    count: 0
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD:
            return { ...state, ...action.payload }
        case RESET:
            return { ...initialState }
        default:
            return state
    }
}