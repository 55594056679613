import React, { useEffect, useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import {

  isMobile
} from "react-device-detect";

const Sidebar = (props) => {
  const [open, setOpen] = useState('');
  console.log(props, 'privateRoutre', props.location.pathname)
  let getpathName = props.location.pathname;
  const isPathActive = (getpathName) => {
    //console.log(getpathName, 'isPathActivePATH')
    return props.location.pathname.startsWith(getpathName);
  }

  const menuHandler = (data, boolVal, link, label) => {

    if (label === "Feedback" || label === "Manual") {
      window.open(link, "_blank")
    }
    if (open === data) {
      setOpen('')
    } else {
      setOpen(data)
    }

    console.log(boolVal);
    if (boolVal === 0) {
      document.body.classList.add('sidebar-icon-only');
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }

  }

  // added by Nikesh
  const menuHandlertwist = () => {

    document.body.classList.add('sidebar-icon-only');
    document.querySelector('.sidebar-offcanvas').classList.remove('active');
  }


  useEffect(() => {
    const body = document.querySelector('body');
    document.querySelector('.nav-item').remove('hover-open');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          // alert('mouseover')
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [])

  let getOption = props.option;


  return (
    <React.Fragment>
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">

          {getOption.map((curr, index) => {
            let { menu, subMenu } = curr;
            let active = false;
            let innerActive = false;
            active = isPathActive(curr.menu.linkTo);

            let innermenu = "";
            let SubmenuLink = "";
            let innermenuCall = 0;
            if (subMenu) {
              innermenuCall = 1;
              innermenu = subMenu.map((Cursub, index1) => {
                console.log(Cursub.menu.linkTo, 'Cursub', getpathName)
                innerActive = getpathName === Cursub.menu.linkTo;
                if (getpathName === Cursub.menu.linkTo) {
                  SubmenuLink = Cursub.menu.linkTo;
                }

                return (
                  <li class="nav-item">
                    <Link class={`nav-link ${getpathName === Cursub.menu.linkTo ? "active" : ""}`} to={Cursub.menu.linkTo} onClick={() => menuHandlertwist()}>
                      {Cursub.menu.label}
                    </Link>
                  </li>
                )

              })
            }
            let tag = `Link`;
            if (curr.menu.label === "Feedback") {
              tag = `a`
            }
            console.log(innerActive, 'SideBarinnerActive,', SubmenuLink, '==', getpathName)
            return (
              <li key={index} className={`nav-item ${getpathName === curr.menu.linkTo || SubmenuLink === getpathName ? "active " : ""} ${open === 'menu' + index ? isMobile ? "hover-open open" : "open" : ""}`}>
                <Link to={curr.menu.url ? "#" : innermenu == "" ? curr.menu.linkTo : "#"}
                  className={`nav-link ${innermenu !== "" ? "dropsubmenu" : null}`}
                  onClick={() => menuHandler(`menu${index}`, innermenuCall, curr.menu.linkTo, curr.menu.label)}>
                  <span className="icon-bg">
                    {/* <i className="mdi mdi-crosshairs-gps menu-icon"></i> */}
                    <i className={curr.menu.class}></i>
                  </span>
                  <span className="menu-title">{curr.menu.label}</span>
                  {innermenu !== "" ? <i class="menu-arrow"></i> : null}
                </Link>
                {innermenu !== "" ?
                  <div className={`collapse ${open === 'menu' + index ? 'show' : null}`} >
                    <ul className="nav flex-column sub-menu">{innermenu}</ul>
                  </div>
                  : null}

              </li>
            )
          })
          }

          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-cube menu-icon"></i>
              </span>
              <span class="menu-title">Dashboard</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="collapse"
             
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span class="icon-bg">
                <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              </span>
              <span class="menu-title">UI Elements</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                 
                  <a class="nav-link" >
                    Buttons
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    Dropdowns
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    Typography
                  </a>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-contacts menu-icon"></i>
              </span>
              <span class="menu-title">Icons</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              </span>
              <span class="menu-title">Forms</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-chart-bar menu-icon"></i>
              </span>
              <span class="menu-title">Charts</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-table-large menu-icon"></i>
              </span>
              <span class="menu-title">Tables</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="collapse"
              
              aria-expanded="false"
              aria-controls="auth"
            >
              <span class="icon-bg">
                <i class="mdi mdi-lock menu-icon"></i>
              </span>
              <span class="menu-title">User Pages</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="auth">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    Blank Page
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    Login
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    Register
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    404
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    500
                  </a>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li class="nav-item documentation-link">
            <a
              class="nav-link"
              
              target="_blank"
            >
              <span class="icon-bg">
                <i class="mdi mdi-file-document-box menu-icon"></i>
              </span>
              <span class="menu-title">Documentation</span>
            </a>
          </li> */}
          {/* <li class="nav-item sidebar-user-actions">
            <div class="user-details">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="d-flex align-items-center">
                    <div class="sidebar-profile-img">
                     
                      <Image image="header/face/face28.png" alt="image" />
                    </div>
                    <div class="sidebar-profile-text">
                      <p class="mb-1">Henry Klein</p>
                    </div>
                  </div>
                </div>
                <div class="badge badge-danger">3</div>
              </div>
            </div>
          </li> */}
          {/* <li class="nav-item sidebar-user-actions">
            <div class="sidebar-user-menu">
              <a  class="nav-link">
                <i class="mdi mdi-settings menu-icon"></i>
                <span class="menu-title">Settings</span>
              </a>
            </div>
          </li> */}
          {/* <li class="nav-item sidebar-user-actions">
            <div class="sidebar-user-menu">
              <a  class="nav-link">
                <i class="mdi mdi-speedometer menu-icon"></i>
                <span class="menu-title">Take Tour</span>
              </a>
            </div>
          </li> 
          <li class="nav-item sidebar-user-actions">
            <div class="sidebar-user-menu">
              <a  class="nav-link">
                <i class="mdi mdi-logout menu-icon"></i>
                <span class="menu-title">Log Out</span>
              </a>
            </div>
          </li>*/}
        </ul>
      </nav>
    </React.Fragment>
  );

};

export default withRouter(Sidebar);