import { put, takeLatest } from 'redux-saga/effects';
import { CALL_CVL_FAILURE, CALL_CVL_SUCCESS, CALL_CVL_PENDING, 
    VALIDATE_NEWINV_PENDING, VALIDATE_NEWINV_SUCCESS, VALIDATE_NEWINV_FAILURE, 
PAN_DETAIL_FAILURE, PAN_DETAIL_SUCCESS, PAN_DETAIL_PENDING,
GET_CLIENTDET_FAILURE, GET_CLIENTDET_SUCCESS, GET_CLIENTDET_PENDING,
GET_ISFC_BANKDET_PENDING, GET_ISFC_BANKDET_SUCCESS, GET_ISFC_BANKDET_FAILURE, 
SAVE_BANK_PENDING, SAVE_BANK_SUCCESS, SAVE_BANK_FAILURE,
SAVE_NOMINEE_PENDING, SAVE_NOMINEE_SUCCESS, SAVE_NOMINEE_FAILURE,
SAVE_FATCA_PENDING, SAVE_FATCA_SUCCESS, SAVE_FATCA_FAILURE,
SAVE_PERSONAL_PENDING, SAVE_PERSONAL_SUCCESS, SAVE_PERSONAL_FAILURE,
SAVE_PERSONAL_DATA, SAVE_CONTACT_DATA, SAVE_FATCA_DATA, SAVE_NOMINEE_DATA, SAVE_BANK_DATA,
FOLIO_CREATE_FAILURE, FOLIO_CREATE_SUCCESS, FOLIO_CREATE_PENDING, SAVE_FOLIO_DATA,
GET_KYCFOLIODATA_SUCCESS, GET_KYCFOLIODATA_FAILURE, GET_KYCFOLIODATA_PENDING} from '../../redux/types/onboarding';
import Api from '../../services/onboarding/personal';

let allApi = new Api();

export function* cvl(data){    
    let apiresponse = yield allApi.callCVL(data.payload)          
    if(apiresponse.data.success){
        yield put({type: CALL_CVL_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: CALL_CVL_FAILURE, payload: {}})
    }   
}

export function* callCVL(data){    
    yield takeLatest(CALL_CVL_PENDING,cvl)
}

export function* validatePan(data){    
    let apiresponse = yield allApi.validateNewInvPAN(data.payload)        
    if(apiresponse.data.success){
        yield put({type: VALIDATE_NEWINV_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: VALIDATE_NEWINV_FAILURE, payload: {}})
    }   
}

export function* validateNewInvPAN(data){    
    yield takeLatest(VALIDATE_NEWINV_PENDING,validatePan)
}

export function* panDets(data){    
    let apiresponse = yield allApi.panDetail(data.payload)     
    if(apiresponse.data.success){
        yield put({type: PAN_DETAIL_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: PAN_DETAIL_FAILURE, payload: {}})
    }   
}

export function* panDetail(data){    
    yield takeLatest(PAN_DETAIL_PENDING,panDets)
}

export function* clientDet(data){    
    let apiresponse = yield allApi.clientDetails(data.payload)     
    if(apiresponse.data.success){
        yield put({type: GET_CLIENTDET_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: GET_CLIENTDET_FAILURE, payload: {}})
    }   
}

export function* clientDetails(data){    
    yield takeLatest(GET_CLIENTDET_PENDING,clientDet)
}

export function* kycFolioData(data){    
    let apiresponse = yield allApi.kycFolioData(data.payload)     
    if(apiresponse.data.success){
        yield put({type: GET_KYCFOLIODATA_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: GET_KYCFOLIODATA_FAILURE, payload: {}})
    }   
}

export function* getKycFolioData(data){    
    yield takeLatest(GET_KYCFOLIODATA_PENDING,kycFolioData)
}

export function* hij(data){      
    let apiresponse = yield allApi.getIfscBankDet(data.payload)     
    if(apiresponse.data.success){
        yield put({type: GET_ISFC_BANKDET_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: GET_ISFC_BANKDET_FAILURE, payload: {}})
    }   
}

export function* getIfscBankDet(data){     
    yield takeLatest(GET_ISFC_BANKDET_PENDING,hij)
}

export function* saveBank(data) {
    let apiresponse = yield allApi.saveBankDetails(data.payload)     
    if(apiresponse.data.success){
        yield put({type: SAVE_BANK_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: SAVE_BANK_FAILURE, payload: {}})
    }  
}

export function* saveBankDetails(data){
    yield takeLatest(SAVE_BANK_PENDING, saveBank)
}

export function* saveNominee(data) {
    let apiresponse = yield allApi.saveNomineeDetails(data.payload)     
    if(apiresponse.data.success){
        yield put({type: SAVE_NOMINEE_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: SAVE_NOMINEE_FAILURE, payload: {}})
    }  
}

export function* saveNomineeDetails(data){
    yield takeLatest(SAVE_NOMINEE_PENDING, saveNominee)
}

export function* saveFatca(data) {
    let apiresponse = yield allApi.saveFatcaDetails(data.payload)     
    if(apiresponse.data.success){
        yield put({type: SAVE_FATCA_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: SAVE_FATCA_FAILURE, payload: {}})
    }  
}

export function* saveFatcaDetails(data){
    yield takeLatest(SAVE_FATCA_PENDING, saveFatca)
}

export function* savePersonal(data) {
    let apiresponse = yield allApi.savePersonalDetails(data.payload)     
    if(apiresponse.data.success){
        yield put({type: SAVE_PERSONAL_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: SAVE_PERSONAL_FAILURE, payload: {}})
    }  
}

export function* savePersonalDetails(data){
    yield takeLatest(SAVE_PERSONAL_PENDING, savePersonal)
}

export function* savePersonalData(data){
    yield put({type: SAVE_PERSONAL_DATA, payload: data})   
}

export function* saveContactData(data){
    yield put({type: SAVE_CONTACT_DATA, payload: data})   
}

export function* saveFatcaData(data){
    yield put({type: SAVE_FATCA_DATA, payload: data})   
}

export function* saveNomineeData(data){
    yield put({type: SAVE_NOMINEE_DATA, payload: data})   
}

export function* saveBankData(data){
    yield put({type: SAVE_BANK_DATA, payload: data})   
}

export function* createFolioApi(data){
    let apiresponse = yield allApi.createFolioApi(data.payload)     
    if(apiresponse.data.success){
        yield put({type: FOLIO_CREATE_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: FOLIO_CREATE_FAILURE, payload: {}})
    }  
}

export function* createFolio(data){
    yield takeLatest(FOLIO_CREATE_PENDING, createFolioApi)
}

export function* saveFolioData(data){
    yield takeLatest({type: SAVE_FOLIO_DATA, payload: data})
}