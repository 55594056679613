import { CALL_MASTER_PENDING, CALL_MASTER_SUCCESS, CALL_MASTER_FAILURE } from '../../types/onboarding';

const initialStats = {
    master: null,
    loading: false
}

export default function masterReducer(state = initialStats, action) {
    switch (action.type) {

        case CALL_MASTER_FAILURE:
            return {
                ...state,
                master: action.payload,
                loading: false
            }

        case CALL_MASTER_SUCCESS:
            return {
                ...state,
                master: action.payload,
                loading: false
            }

        case CALL_MASTER_PENDING:
            return {
                ...state,
                loading: true
            }

        default:
            return state
    }
}