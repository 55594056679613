import {
    START_LOADING_RESPONSE,
    FINISH_LOADING_RESPONSE
} from "../types/loader";

const initialState = {
    start: false,
    stop: false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_RESPONSE:
            return { ...state, ...action.payload }
        case FINISH_LOADING_RESPONSE:
            return { ...state, ...action.payload }
        case 'RESET_LOADER':
            return { ...state, start: false, stop: false }
        default:
            return state
    }
}
