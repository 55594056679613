import Api from '../index';
import {endpoints} from '../endpoints';

export default class Master extends Api{
    callCVL(data){     
        let url = this.buildUrl(endpoints.ob.common, 'full');           
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    
    validateNewInvPAN(data){     
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    panDetail(data){        
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    clientDetails(data){        
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    kycFolioData(data){        
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getIfscBankDet(data){
        let url = this.buildUrl(endpoints.ob.ifscBankDet, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    saveBankDetails(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    saveNomineeDetails(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    saveFatcaDetails(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    savePersonalDetails(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    saveContactDetails(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    verifyBank(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    createFolioApi(data){
        let url = this.buildUrl(endpoints.ob.common, 'full');          
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}