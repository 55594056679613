import { takeLatest, put, call } from 'redux-saga/effects';
import appApi from "../services/appApi";
import { push } from 'connected-react-router';
import * as type from '../redux/types/validateotp';
import { AUTHENTICATE_USER_RESPONSE, AUTHENTICATE_USER_FAILED } from '../redux/types/authenticate';
import { VALIDATE_OTP_SUCCESS, VALIDATE_OTP_FAILURE, VALIDATE_OTP } from '../redux/types/validateotp';
import { VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE, VERIFY_OTP } from '../redux/types/redeemotp';

let api = new appApi();

const dummyAuthenticate = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({ data: { success: true } })
        }, 2000);
    })
}

export function* authenticateUser() {
    let responsePayload = {};
    try {
        let { data } = yield dummyAuthenticate();

        if (data.success) {

            yield put({ type: AUTHENTICATE_USER_RESPONSE })
        }
        else {
            yield put(push('/'));
            yield put({ type: AUTHENTICATE_USER_FAILED })
        }
    } catch (e) {
        yield put({ type: AUTHENTICATE_USER_FAILED })
    }
}
export function* ValidateRedeemOTP({ payload, resolve }) {
    console.log("start details")
    let responsePayload = {};
    try {
        let { data, code } = yield api.ValidateRedeemOTP(payload);
        console.log("datadatadatadatadata", data.data)
        if (data.success) {
            let responseData = data.data;
            console.log("responseddddddddhdh", responseData);
            responsePayload.ValidateRedeemOTP = responseData;
            yield put({ type: VALIDATE_OTP_SUCCESS, payload: responsePayload })
            resolve && resolve(data.data)
        }
        // if (code && code === 401) {
        //     window.location.href = `/dist/pms`;
        // }
        else {
            resolve && resolve(data)
            yield put({ type: VALIDATE_OTP_FAILURE, payload: responsePayload })
        }
    } catch (e) {
        yield put({ type: VALIDATE_OTP_FAILURE, payload: responsePayload })

    }
}
export function* redeemOtp({ payload, resolve }) {
    console.log("start details")
    let responsePayload = {};
    try {
        let { data, code } = yield api.redeemOtp(payload);
        console.log("datadatadatadatadata", data.data)
        if (data.success) {
            let responseData = data.data;
            console.log("responseddddddddhdh", responseData);
            responsePayload.redeemOtp = responseData;
            yield put({ type: VERIFY_OTP_SUCCESS, payload: responsePayload })
            resolve && resolve(data.data)
        }
        // if (code && code === 401) {
        //     window.location.href = `/dist/pms`;
        // }
        else {
            resolve && resolve(data)
            yield put({ type: VERIFY_OTP_FAILURE, payload: responsePayload })
        }
    } catch (e) {
        yield put({ type: VERIFY_OTP_FAILURE, payload: responsePayload })

    }
}
// export function* getSipDashboard(data) {
//     yield takeLatest(type.VALIDATE_OTP, redeemOtp)
// }