export const ADDLOGINMODE = "ADDLOGINMODE"
export const INIT_APP_RESPONSE = "INIT_APP_RESPONSE"
export const INIT_APP_RESPONSE_FAILED = "INIT_APP_RESPONSE_FAILED"
export const INIT_GET_FOLIO_LIST = "INIT_GET_FOLIO_LIST"
export const INIT_GET_FOLIO_LIST_RESPONSE = "INIT_GET_FOLIO_LIST_RESPONSE"
export const INIT_GET_FOLIO_LIST_FAILED = "INIT_GET_FOLIO_LIST_FAILED"

export const LOGGED_IN_SUCCESS = 'LOGGED_IN_SUCCESS'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_RESPONSE = 'LOGOUT_RESPONSE'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'


