const infoMedia = process.env.REACT_APP_INFOMEDIA;

const HeaderMain = {
    "PARTNER": [
        {
            "Heading": "Home",
            "url": "/",
            "class": "fas fa-home",
            "filter": "PARTNER"
        },
        {
            "Heading": "About Us",
            "url": "/about-us/overview",
            "class": "fas fa-users",
            "filter": "PARTNER"
        },
        {
            "Heading": "Nav",
            "url": "/nav/latest-nav",
            "class": "fas fa-coins",
            "filter": "PARTNER"
        },
        {
            "Heading": "Tools",
            "url": "/tools/sip-return-calculator",
            "class": "fas fa-calculator",
            "filter": "PARTNER"
        },
        {
            "Heading": "Contact Us ",
            "url": "/contactUs",
            "class": "fas fa-calculator",
            "filter": "PARTNER"
        },
        {
            "Heading": "NEWS & INSIGHTS",
            "url": "/Media-Room/Press-Release",
            "class": "fas fa-photo-video",
            "filter": "PARTNER",
            "dropDown": [
                {
                    "title": "Blog",
                    "url": infoMedia
                },
                {
                    "title": "Press-Release",
                    "url": "/Media-Room/Press-Release"
                },
                {
                    "title": "Interviews",
                    "url": "/Media-Room/Press-Release",
                    "subDropDown": [
                        {
                            "title": "Print",
                            "url": "/Media-Room/Interviews/Print"
                        },
                        {
                            "title": "Online",
                            "url": "/Media-Room/Interviews/Online"
                        },
                        {
                            "title": "Television",
                            "url": "/Media-Room/Interviews/TV"
                        }
                    ]
                },
                {
                    "title": "News",
                    "url": "/Media-Room/Press-Release",
                    "subDropDown": [
                        {
                            "title": "Print",
                            "url": "/Media-Room/News/Print"
                        },
                        {
                            "title": "Online",
                            "url": "/Media-Room/News/Online"
                        },
                        {
                            "title": "Television",
                            "url": "/Media-Room/News/TV"
                        }
                    ]
                }
            ]
        },
        {
            "Heading": "Nav",
            "url": "/nav/latest-nav",
            "class": "fas fa-coins",
            "filter": "PARTNER"
        },
        {
            "Heading": "Tools",
            "url": "/tools/sip-return-calculator",
            "class": "fas fa-calculator",
            "filter": "PARTNER"
        }
    ]
}

export default HeaderMain