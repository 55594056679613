import { put } from 'redux-saga/effects';
import triggerAPI from "../services/triggerApi";
import { endpoints } from "../services/endpoints";
import {
    FABSCHEME_RESPONSE, FABSCHEME_RESPONSE_FAIL, FOLIODETAILS_RESPONSE, FOLIODETAILS_RESPONSE_FAIL
    , FREQUENCYCALCULATOR_RESPONSE, FREQUENCYCALCULATOR_RESPONSE_FAIL,WITHDRAWPLOTTING_RESPONSE,WITHDRAWPLOTTING_RESPONSE_FAIL
} from "../redux/types/fabPlan";

let call = new triggerAPI();

export function* fabScheme({ type, resolve, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield call.triggerAPI(payload, endpoints.fabPlans.fabScheme);
        if (data.success) {
            let userData = data.data;
            responsePayload.userData = userData;
            yield put({ type: FABSCHEME_RESPONSE, payload: responsePayload })
            resolve && resolve(responsePayload)
        } else {
            yield put({ type: FABSCHEME_RESPONSE_FAIL, payload: data })
            resolve && resolve(responsePayload)
        }
    } catch (e) {
        yield put({ type: FABSCHEME_RESPONSE_FAIL })
        resolve && resolve(e)
    }
}

export function* frequencyCalculator({ type, resolve, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield call.triggerAPI(payload, endpoints.fabPlans.CashFlowCalculatorFrequency);
        if (data && data.success) {
            let userData = data.data;
            responsePayload = userData;
            yield put({ type: FREQUENCYCALCULATOR_RESPONSE, payload: responsePayload })
            resolve && resolve(responsePayload)
        } else {
            yield put({ type: FREQUENCYCALCULATOR_RESPONSE_FAIL, payload: data })
            resolve && resolve(responsePayload)
        }
    } catch (e) {
        yield put({ type: FREQUENCYCALCULATOR_RESPONSE_FAIL, payload: e.toString() })
        resolve && resolve(e)
    }
}

export function* folioDetails({ type, resolve, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield call.triggerAPI(payload, endpoints.fabPlans.folioDetails);
        if (data && data.success) {
            let userData = data.data;
            responsePayload = userData;
            yield put({ type: FOLIODETAILS_RESPONSE, payload: responsePayload })
            resolve && resolve(responsePayload)
        } else {
            yield put({ type: FOLIODETAILS_RESPONSE_FAIL, payload: data })
            resolve && resolve(responsePayload)
        }
    } catch (e) {
        yield put({ type: FOLIODETAILS_RESPONSE_FAIL, payload: e.toString() })
        resolve && resolve(e)
    }
}

export function* withdrawPlotting({ type, resolve, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield call.triggerAPI(payload, endpoints.fabPlans.withdrawPlotting);
        if (data && data.success) {
            let userData = data.data;
            responsePayload = userData;
            yield put({ type: WITHDRAWPLOTTING_RESPONSE, payload: responsePayload })
            resolve && resolve(responsePayload)
        } else {
            yield put({ type: WITHDRAWPLOTTING_RESPONSE_FAIL, payload: data })
            resolve && resolve(responsePayload)
        }
    } catch (e) {
        yield put({ type: WITHDRAWPLOTTING_RESPONSE_FAIL, payload: e.toString() })
        resolve && resolve(e)
    }
}