import { takeLatest, put, call } from 'redux-saga/effects';
import triggerAPI from "../../services/triggerApi";
import { endpoints } from "../../services/endpoints";
import { GET_CART_PENDING, EUIN_LIST_RESPONSE, EUIN_LIST_FAIL, GET_CART_SUCCESS, GET_CART_FAILURE } from '../../redux/types/trans';
import Api from '../../services/trans/trans';

let api = new triggerAPI();
let allApi = new Api();

async function fetchUser(data) {
    let res = await allApi.commonGet(data);
    return res;
}

function* fetchData(action) {

    let payload = {
        "apiName": "GetCartDetails",
        "params": ""
    }
    let apiresponse = yield call(fetchUser, payload)

    if (apiresponse.data.success === true) {
        let allData = apiresponse.data.data;

        console.log(allData, 'GetCartDetails')
        yield put({ type: GET_CART_SUCCESS, payload: allData })
    } else {
        yield put({ type: GET_CART_FAILURE, payload: {} })
    }


}

export function* getCartData() {
    yield takeLatest(GET_CART_PENDING, fetchData)
}

export function* getEUINList({ type, resolve, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield api.triggerAPI(payload, endpoints.euin.GetEuinDetail);
        if (data.success) {

            let list = data?.data?.euinDetail;
            if (list.length > 0) {
                responsePayload.list = formatEUINList(list);
                responsePayload.selectedEUIN = list.filter((li) => li.isDefault)[0]?.euin;
            }
            console.log("EUIN List saga", responsePayload)
            yield put({ type: EUIN_LIST_RESPONSE, payload: responsePayload })
            resolve && resolve(responsePayload)
        } else {
            yield put({ type: EUIN_LIST_FAIL, payload: data })
            resolve && resolve(responsePayload)
        }
    } catch (e) {
        yield put({ type: EUIN_LIST_FAIL })
        resolve && resolve(e)
    }
}

function formatEUINList(data) {
    let processedList = data.map(({ euin, name, isDefault, defaultDate }) => {
        return { label: euin, value: euin, name: name, isDefault: isDefault, defaultDate: defaultDate }
    })
    return processedList;
}

