import { INIT_APP, INIT_APP_RESPONSE, VARIFY_OTP, VARIFY_OTP_RESPONSE, PMS_CODE_RESPONSE, MENU_ROLE_WISE_RESPONSE, MENU_ROLE_WISE } from '../types/initApp';

const initialState = {
    initDetails: "",
    pmsScheme: "",
    menuRoleWise: {},
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case INIT_APP_RESPONSE:
            return { ...state, initDetails: action.payload }
        case VARIFY_OTP:
            return { ...state, ...action.payload }
        case VARIFY_OTP_RESPONSE:
            return { ...state, initDetails: action.payload }
        case PMS_CODE_RESPONSE:
            return { ...state, pmsScheme: action.payload }
        case MENU_ROLE_WISE_RESPONSE:
            return { ...state, menuRoleWise: action.payload }
        default:
            return state
    }

}
