import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router';
import loginApi from "../services/loginApi";
import { LOGOUT_RESPONSE, LOGOUT_FAILED } from '../redux/types/init';

let api = new loginApi();

export function* logout() {    
    try {
        let { data } = yield api.logout();
        if (data.success) {
            localStorage.clear();
            yield put({ type: LOGOUT_RESPONSE });
            // yield put(push('/'));
            yield put(push('/investonline/dist'));
        }
        else {
            yield put(push('/investonline/dist'));
            yield put({ type: LOGOUT_FAILED })
        }
    } catch (e) {
        yield put(push('/investonline/dist'));
        yield put({ type: LOGOUT_FAILED })
    }
}

