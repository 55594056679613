export const FABSCHEME = 'FABSCHEME'
export const FABSCHEME_RESPONSE = 'FABSCHEME_RESPONSE'
export const FABSCHEME_RESPONSE_FAIL = 'FABSCHEME_RESPONSE_FAIL'

export const FREQUENCYCALCULATOR = 'FREQUENCYCALCULATOR'
export const FREQUENCYCALCULATOR_RESPONSE = 'FREQUENCYCALCULATOR_RESPONSE'
export const FREQUENCYCALCULATOR_RESPONSE_FAIL = 'FREQUENCYCALCULATOR_RESPONSE_FAIL'

export const FABFOLIOLIST = 'FABFOLIOLIST'

export const INPUTCHANGE = 'INPUTCHANGE'

export const INVESTMENTCALCULATOR = 'INVESTMENTCALCULATOR'

export const INVESTSCHEMEDATA = 'INVESTSCHEMEDATA'


export const FOLIODETAILS = 'FOLIODETAILS'
export const FOLIODETAILS_RESPONSE = 'FOLIODETAILS_RESPONSE'
export const FOLIODETAILS_RESPONSE_FAIL = 'FOLIODETAILS_RESPONSE_FAIL'

export const BUCKETID = 'BUCKETID'

export const WITHDRAWPLOTTING = 'WITHDRAWPLOTTING'
export const WITHDRAWPLOTTING_RESPONSE = 'WITHDRAWPLOTTING_RESPONSE'
export const WITHDRAWPLOTTING_RESPONSE_FAIL = 'WITHDRAWPLOTTING_RESPONSE_FAIL'
