class StateUtility {
  constructor() {
    if (StateUtility.instance == null) {
      var events = {
        events: {},
        subscribe: function (eventName, fn) {
          this.events[eventName] = this.events[eventName] || [];
          this.events[eventName].push(fn);
        },
        unsubscribe: function (eventName, fn) {
          if (this.events[eventName]) {
            for (var i = 0; i < this.events[eventName].length; i++) {
              if (this.events[eventName][i] === fn) {
                this.events[eventName].splice(i, 1);
                break;
              }
            };
          }
        },
        publish: function (eventName, data) {
          if (this.events[eventName]) {
            this.events[eventName].forEach(function (fn) {
              fn(data);
            });
          }
        }
      }
      this.globalState = {
        // appid: ""
        events
      };
      StateUtility.instance = this;
    }
    return StateUtility.instance;
  }

  getPropertyByName(propertyName) {
    console.log('this.globalState', this.globalState, propertyName);
    return this.globalState[propertyName];
  }

  setPropertyValue(propertyName, propertyValue) {
    this.globalState[propertyName] = propertyValue;
  }
}

let stateUtilityObj = new StateUtility();
Object.freeze(stateUtilityObj);

export default stateUtilityObj;