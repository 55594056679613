import { createBrowserHistory } from "history";
let history;

if (process.env.IS_SERVER) {

} else {

    if (process.env.REACT_APP_PUBLIC_URL) {
        history = createBrowserHistory({ basename: process.env.REACT_APP_PUBLIC_URL });
    } else {
        history = createBrowserHistory();
    }


}

export default history;
