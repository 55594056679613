import {
  ABOUTUS_DATA_PENDING,
  NAV_PENDING,
  MF_DETAIL_PENDING,
  MF_CATEGORY_PENDING,
  MF_CATEGORY_SUCCESS,
  MF_CATEGORY_DROP_PENDING,
  MF_DETAIL,
  PASSIVE_CATEGORY_PENDING,
  SINGLE_SELECTION_PASSIVE,
  PASSIVE_DETAIL_PENDING,
  COMPARE_FUND,
  STP_SCHEME_PENDING,
  MF_DETAIL_KM_DROPDOWN,
} from "../types/preloginType";

export const callApi = () => {
  return {
    type: ABOUTUS_DATA_PENDING,
  };
};

export const NavApi = () => {
  console.log("navApi=Action");
  return {
    type: NAV_PENDING,
  };
};

export const MFDetail = (data) => {
  return {
    type: MF_DETAIL_PENDING,
    payload: data,
  };
};
export const MFCategory = (data) => {
  return {
    type: MF_CATEGORY_PENDING,
    paylaod: data,
  };
};

export const STPCategory = (data) => {
  console.info("calllling");
  return {
    type: STP_SCHEME_PENDING,
    paylaod: data,
  };
};

export const MFCatSuccuess = (data) => {
  console.log(data, "MFCatSuccuess");
  return {
    type: MF_CATEGORY_SUCCESS,
    paylaod: data,
  };
};

export const CatSelection = (data) => {
  console.log(data, "action");
  return {
    type: MF_CATEGORY_DROP_PENDING,
    paylaod: data,
  };
};

export function RedirectMFDetail(data) {
  console.log(data, "RedirectMFDetail");
  return {
    type: MF_DETAIL,
    payload: data,
  };

  // dispatch({
  //     type: MF_DETAIL,
  //     paylaod: data
  // })
  // return {

  // }
}

export function RedirectMFDetFundDropVal(data) {
  return {
    type: MF_DETAIL_KM_DROPDOWN,
    payload: data,
  };
}

export function CompareFund(data) {
  // alert()
  console.log(data, "data", "compare");
  return {
    type: COMPARE_FUND,
    payload: data,
  };
}

// passsive

export function PassiveCate(data) {
  return {
    type: PASSIVE_CATEGORY_PENDING,
    payload: data,
  };
}
export function PassiveSelection(data) {
  return {
    type: SINGLE_SELECTION_PASSIVE,
    payload: data,
  };
}

export function PassiveDetail(data) {
  return {
    type: PASSIVE_DETAIL_PENDING,
    paylaod: data,
  };
}
