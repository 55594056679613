import React, { useState, useEffect, useRef } from 'react';
import Image from './image';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = (props) => {
    const [menuHideHeader, setMenuHideHeader] = useState(false);
    const [isProWebApp, setisProWebApp] = useState(false);

    const handleMenuHideHeader = () => {
        if (props.path == '/confirmation') {
            setMenuHideHeader(true)
        } else {
            setMenuHideHeader(false)
        }
    }
    useEffect(() => {
        // setMenuHide(false)
    }, [])

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
            setisProWebApp(true);
        } else {
            setisProWebApp(false);
        }
    }, [])
    
    let votingPolicyLink = '/pdf/voting-policy.pdf';
    return (
        <React.Fragment>
            <section className="container-fluid d-none">
                <div className=" pad-footer">
                    <div className="row justify-content-center">
                        <div className="col-md-6 ">
                            <div className="scrollTop"> {/*  onClick={topFunction()} */}
                                <p className="scroll-div"><span>
                                    <Image image="Top Button Arrow.png" />

                                </span></p>
                                <p className="scroll-txt">Scroll to top</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid footer-quick-links py-4 d-none">
                <div className="container">
                    <div className="row align-items-end ">
                        <div className="col-md-3 col-6">
                            <Link to="/faq" target="_blank">
                                <Image image="footer-faqs-icon.svg" />

                                <h6>Faq<span className="text-lowercase">s</span></h6>
                            </Link>
                        </div>
                        <div className="col-md-3 col-6">
                            <a href="" target="_blank">
                                <Image image="footer-chatwithus-icon.svg" />
                                {/* <img src="images/postlogin/footer-chatwithus-icon.svg" alt="faqs" className="img-fluid" /> */}
                                <h6>Chat With Us</h6>
                            </a>
                        </div>
                        <div className="col-md-3 col-6">
                            <Link to="/Contact" target="_blank">
                                <Image image="footer-searchlocation-icon.svg" />
                                {/* <img src="images/postlogin/footer-searchlocation-icon.svg" alt="faqs" className="img-fluid" /> */}
                                <h6>Locate Our Branch</h6>
                            </Link>
                        </div>
                        <div className="col-md-3 col-6">
                            <a href="https://api.whatsapp.com/send?phone=919372205812&text=Hi" target="_blank">
                                <Image image="footer-whatsapp-icon.svg" />
                                {/* <img src="images/postlogin/footer-whatsapp-icon.svg" alt="faqs" className="img-fluid" /> */}
                                <h6>MF@Whatsapp</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {
                !isProWebApp ? 
                    <section className="container-fluid footersec">
                        <div className="row">
                            <div className="col-md-12 pad-footer  d-none">
                                <div className="row">
                                    <div className="col-md-6 col-lg-3 yg_footerMB20">
                                        <div className="footer-heading">
                                            <h5>MOBILE APP</h5>
                                        </div>
                                        <ul className="social-list no-space">
                                            <li>
                                                <a href="https://itunes.apple.com/in/app/moamc/id1410645960?mt=8" target="_blank">

                                                    <Image image="App Store.png" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.mosl.amc&hl=en_IN" target="_blank">
                                                    <Image image="Playstore Logo.png" />
                                                    {/* <img src="images/Homepage/Playstore Logo.png" alt="" className="img-fluid"> */}

                                                </a>
                                            </li>

                                            {/* <li>
                                                <a href="">
                                                    <Image image="Whatsapp Logo.png" />
                                                

                                                </a>
                                            </li> */}

                                        </ul>
                                    </div>
                                    <div className="col-md-6 col-lg-3 yg_footerMB20">
                                        <div className="footer-heading">
                                            <h5>Follow Us</h5>
                                        </div>
                                        <ul className="social-list sociallogo">
                                            <li>
                                                <a href="https://www.facebook.com/motilaloswalamc" target="_blank">
                                                    {/* <img src="images/postlogin/facebook-logo.svg" alt="" className="img-fluid "> */}
                                                    <Image image="facebook-logo.svg" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/MotilalOswalAMC" target="_blank">
                                                    {/* <img src="images/postlogin/twitter-logo.svg" alt="" className="img-fluid "> */}
                                                    <Image image="twitter-logo.svg" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://youtube.com/motilaloswalamc" target="_blank">
                                                    {/* <img src="images/postlogin/youtube-logo.svg" alt="" className="img-fluid "> */}
                                                    <Image image="youtube-logo.svg" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/motilaloswalamc" target="_blank">
                                                    {/* <img src="images/postlogin/instagram-logo.svg" alt="" className="img-fluid "> */}
                                                    <Image image="instagram-logo.svg" />
                                                </a>
                                            </li>
                                        </ul>

                                        {/* <h5 className="footer-heading">Product and Services</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="footer-list">
                                        <li><a href="">Portfolio Management Services</a></li>
                                        <li><a href="">Mutual Fund</a></li>
                                        <li><a href="">Debt Fund</a></li>
                                        <li><a href="">AIF</a></li>
                                        <li><a href="">Passive Funds</a></li>
                                        <li><a href="">Regulatory Updates</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="footer-list">
                                        <li><a href="">Financial Declarations</a></li>
                                        <li><a href="">Smart Plans</a></li>
                                        <li><a href="">FAQs</a></li>
                                    </ul>
                                </div>
                            </div> */}

                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="footer-heading">
                                            <h5>Contact Us</h5>
                                        </div>
                                        <ul className="footer-list tel-list">
                                            <li><span className="mr-1">
                                                {/* <img src="images/postlogin/call-icon.svg" /> */}
                                                <Image image="call-icon.svg" />
                                            </span> <a href="tel:912240548002">+91-22 40548002</a>/<a href="tel:918108622222">8108622222</a></li>
                                            <li><span className="mr-1">
                                                {/* <img src="images/postlogin/email-icon.svg" /> */}
                                                <Image image="email-icon.svg" />
                                            </span> MF : <a href="mailto:mfservice@motilaloswal.com">mfservice@motilaloswal.com</a></li>
                                            <li><span className="mr-1">
                                                <Image image="email-icon.svg" />
                                                {/* <img src="images/postlogin/email-icon.svg" /> */}
                                            </span> PMS : <a href="mailto:pmsquery@motilaloswal.com">pmsquery@motilaloswal.com</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="footer-heading">
                                            <h5>Newsletter</h5>
                                        </div>
                                        <ul className="footer-list tel-list below-noSpace">
                                            <li><a href="">Subscribe to our newsletter to get the latest News.</a></li>
                                        </ul>
                                        <div className="newsletter-box">
                                            <input type="email" className="news-email" placeholder="Email Address" />
                                            {/* <img
                                                src="images/Homepage/Newsletter Logo.png" alt="" className="logo-newsletter">  */}
                                            <Image image="Newsletter Logo.png" class="logo-newsletter" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mf-tnc-line">
                                        <p>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.<br /> KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI
                                            registered intermediary (broker, DP, Mutual Fund etc), you need not undergo the same process again when you approach another intermediary</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 pad-footer mf-tnc-line">
                                <p>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.<br /> KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI
                                    registered intermediary (broker, DP, Mutual Fund etc), you need not undergo the same process again when you approach another intermediary</p>
                            </div>
                            {props.path === "/confirmation" || props.path === "/sessionExpired" ?
                                null
                                :
                                <div className="col-md-12 footer-small pad-footer pt-2 pb-2">
                                    <div className="row  align-items-center ">
                                        <div className="col-12 col-md-auto mr-auto "><p>CIN-U67120MH2008PLC188186 </p></div>
                                        <div className="col-12 col-md-auto ">
                                            <ul className="subFooter-list">
                                                <li><a href="/disclaimer">Disclaimer</a></li>
                                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                                <li><a href={votingPolicyLink}>Voting Policy</a></li>
                                                {/* <li><a href="">Sitemap</a></li>
                                                <li><a href="">Subscribe our RSS Feed</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-md-12 pad-footer pt-3 pb-4 ">
                                <div className="row footer-copywrite align-items-center ">
                                    <div className="col-auto mr-auto "><p>© Copyright </p></div>
                                    <div className="col-auto"><p>Site best viewed in Google Chrome (Latest version), Firefox (Latest version), Safari (Latest version), Microsoft Edge(Latest version)</p></div>
                                </div>
                            </div>
                        </div>
                    </section>
                :
                ''
            }
        </React.Fragment >
    )
}

export default Footer