// Client Exist
export const CLIENT_EXIST_DATA = 'CLIENT_EXIST_DATA';
export const CLIENT_EXIST_DATA_RESPONSE = 'CLIENT_EXIST_DATA_RESPONSE';
export const CLIENT_EXIST_DATA_FAILURE = 'CLIENT_EXIST_DATA_FAILURE';

// Client MF
export const CLIENT_MF_DATA = 'CLIENT_MF_DATA';
export const CLIENT_MF_DATA_RESPONSE = 'CLIENT_MF_DATA_RESPONSE';
export const CLIENT_MF_DATA_FAILURE = 'CLIENT_MF_DATA_FAILURE';

// Account Statement Report Submit
export const GetMFAccountStatement_DATA = 'GetMFAccountStatement_DATA';
export const GetMFAccountStatement_DATA_RESPONSE = 'GetMFAccountStatement_DATA_RESPONSE';
export const GetMFAccountStatement_DATA_FAILURE = 'GetMFAccountStatement_DATA_FAILURE';

// Capital Gain Loss Report Submit
export const CapitalGainLoss_DATA = 'CapitalGainLoss_DATA';
export const CapitalGainLoss_DATA_RESPONSE = 'CapitalGainLoss_DATA_RESPONSE';
export const CapitalGainLoss_DATA_FAILURE = 'CapitalGainLoss_DATA_FAILURE';

// Sip Report Submit
export const SIPDashboard_DATA = 'SIPDashboard_DATA';
export const SIPDashboard_DATA_RESPONSE = 'SIPDashboard_DATA_RESPONSE';
export const SIPDashboard_DATA_FAILURE = 'SIPDashboard_DATA_FAILURE';

// BITALY_LINK
export const BITALY_LINK = 'BITALY_LINK';
export const BITALY_LINK_RESPONSE = 'BITALY_LINK_RESPONSE';
export const BITALY_LINK_FAILURE = 'BITALY_LINK_FAILURE';

// REPORTS SEND
export const REPORT_SEND = 'REPORT_SEND';
export const REPORT_SEND_RESPONSE = 'REPORT_SEND_RESPONSE';
export const REPORT_SEND_FAILURE = 'REPORT_SEND_FAILURE';