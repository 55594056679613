import React from 'react';

const Avatar = (props) => {
    let src = "";
    if (props.fullPath) {
        src = props.image
    } else {
        src = require(`./images/${props.image}`).default
    }
    return (
        <img
            src={src}
            // src={`${props.image}`}
            className={`img-fluid ${props.class}`}
            alt={props.alt}
            style={{ ...props.style }}
        />
    );
};
export default Avatar

