export const INIT_APP = 'INIT_APP'
export const INIT_APP_FAIL = 'INIT_APP_FAIL'

export const INIT_APP_RESPONSE = 'INIT_APP_RESPONSE'
export const INIT_APP_RESPONSE_FAIL = 'INIT_APP_RESPONSE_FAIL'

export const PMS_CODE = 'PMS_CODE'
export const PMS_CODE_RESPONSE = 'PMS_CODE_RESPONSE'
export const PMS_CODE_RESPONSE_FAIL = 'PMS_CODE_RESPONSE_FAIL'

export const VARIFY_OTP = "VARIFY_OTP"
export const VARIFY_OTP_RESPONSE = 'VARIFY_OTP_RESPONSE'
export const VARIFY_OTP_RESPONSE_FAIL = 'VARIFY_OTP_RESPONSE_FAIL'

export const MENU_ROLE_WISE = "MENU_ROLE_WISE"
export const MENU_ROLE_WISE_RESPONSE = 'MENU_ROLE_WISE_RESPONSE'
export const MENU_ROLE_WISE_RESPONSE_FAIL = 'MENU_ROLE_WISE_RESPONSE_FAIL'