import {CALL_CVL_FAILURE, CALL_CVL_SUCCESS, CALL_CVL_PENDING, 
VALIDATE_NEWINV_FAILURE, VALIDATE_NEWINV_SUCCESS, VALIDATE_NEWINV_PENDING,
PAN_DETAIL_FAILURE, PAN_DETAIL_SUCCESS, PAN_DETAIL_PENDING,
GET_CLIENTDET_FAILURE, GET_CLIENTDET_SUCCESS, GET_CLIENTDET_PENDING,
GET_ISFC_BANKDET_FAILURE, GET_ISFC_BANKDET_SUCCESS, GET_ISFC_BANKDET_PENDING,
SAVE_BANK_PENDING, SAVE_BANK_SUCCESS, SAVE_BANK_FAILURE,
SAVE_NOMINEE_PENDING, SAVE_NOMINEE_SUCCESS, SAVE_NOMINEE_FAILURE,
SAVE_FATCA_PENDING, SAVE_FATCA_SUCCESS, SAVE_FATCA_FAILURE,
SAVE_PERSONAL_PENDING, SAVE_PERSONAL_SUCCESS, SAVE_PERSONAL_FAILURE,
SAVE_PERSONAL_DATA, SAVE_FATCA_DATA, SAVE_CONTACT_DATA, SAVE_NOMINEE_DATA, SAVE_BANK_DATA,
FOLIO_CREATE_PENDING, FOLIO_CREATE_SUCCESS, FOLIO_CREATE_FAILURE, SAVE_FOLIO_DATA, RESET_OB_STATE,
GET_KYCFOLIODATA_SUCCESS, GET_KYCFOLIODATA_FAILURE, GET_KYCFOLIODATA_PENDING} from '../../types/onboarding';

const initialStats = {
    cvlData: null,
    validateNewInv: null,
    panDetails: null,
    clientDetails: null,
    clientDataAvailable: null,
    kycFolioDetails: null,
    ifscBankDet: null,
    loading: false,
    bankDetails: null,
    nomineeDetails: null,
    fatcaDetails: null,
    personalDetails: null,
    savePersonalData: null,
    saveFatcaData: null,
    saveNomineeData: null,
    saveBankData: null,
    contactData: null,
    folioDetails: null,
    folioData: null
}

export default function personalReducer (state=initialStats, action) {    
    switch (action.type) {
        
        case CALL_CVL_FAILURE:
            return{
                ...state,
                cvlData: action.payload,
                loading: false
            }
        
        case CALL_CVL_SUCCESS:
            return{
                ...state,
                cvlData: action.payload,
                loading: false
            }

        case CALL_CVL_PENDING:
            return{
                ...state,
                loading: true
            }

        case VALIDATE_NEWINV_FAILURE:
            return{
                ...state,
                validateNewInv: action.payload,
                loading: false
            }
        
        case VALIDATE_NEWINV_SUCCESS:
            return{
                ...state,
                validateNewInv: action.payload,
                loading: false
            }

        case VALIDATE_NEWINV_PENDING:
            return{
                ...state,
                loading: true
            }
        
        case PAN_DETAIL_FAILURE:
            return{
                ...state,
                panDetails: action.payload,
                loading: false
            }
        
        case PAN_DETAIL_SUCCESS:
            return{
                ...state,
                panDetails: action.payload,
                loading: false
            }

        case PAN_DETAIL_PENDING:
            return{
                ...state,
                loading: true
            }

        case GET_CLIENTDET_FAILURE:
            return{
                ...state,
                clientDetails: action.payload,
                loading: false
            }
        
        case GET_CLIENTDET_SUCCESS:
            let clientDetails = action.payload;
            let personalDetails = state.savePersonalData;
            let fatcaDetails = state.saveFatcaData;
            let nomineeDetails = state.saveNomineeData;
            let bankDetails = state.saveBankData;
            let clientDataAvailable = false;
            if (clientDetails.hasOwnProperty("invBasicDetails")) {	
                console.log("GET_CLIENTDET_SUCCESS invBasicDetails",clientDetails.invBasicDetails);	
                if (clientDetails.invBasicDetails.modeOfHolding !== null) {	
                  console.log("GET_CLIENTDET_SUCCESS invBasicDetails modeOfHolding",clientDetails.invBasicDetails.modeOfHolding);	
                  personalDetails = clientDetails.invBasicDetails;	
                  clientDataAvailable = true;	
                }	
              }
            if(clientDetails.hasOwnProperty('fatcaDetails')){
                if(clientDetails.fatcaDetails.length > 0){
                    fatcaDetails = clientDetails.fatcaDetails;                    
                }
            }
            if(clientDetails.hasOwnProperty('nomineeDetails')){                
                if(clientDetails.nomineeDetails.nomineeDetails.length > 0){
                    nomineeDetails = clientDetails.nomineeDetails;                    
                }
            }
            if(clientDetails.hasOwnProperty('bankDetail')){                
                if(clientDetails.bankDetail.accountNo !== null){
                    bankDetails = clientDetails.bankDetail;                    
                }
            }
            return{
                ...state,
                clientDetails: action.payload,
                savePersonalData: personalDetails,
                saveFatcaData: fatcaDetails,
                saveNomineeData: nomineeDetails,
                saveBankData: bankDetails,
                clientDataAvailable: clientDataAvailable,
                loading: false
            }

        case GET_CLIENTDET_PENDING:
            return{
                ...state,
                loading: true
            }

        case GET_KYCFOLIODATA_FAILURE:
            return{
                ...state,
                kycFolioDetails: action.payload,
                loading: false
            }
            
        case GET_KYCFOLIODATA_SUCCESS:
            console.log("in saga GET_KYCFOLIODATA_SUCCESS", action)
            let kclientDetails = action.payload;
            let kpersonalDetails = state.savePersonalData;
            let kfatcaDetails = state.saveFatcaData;
            let knomineeDetails = state.saveNomineeData;
            let kbankDetails = state.saveBankData;
            if(kclientDetails.hasOwnProperty('invBasicDetails')){
                console.log("kclientDetails", kclientDetails)
                console.log("kclientDetails.invBasicDetails", kclientDetails.invBasicDetails)
                if(kclientDetails.invBasicDetails !== null){
                    if(kclientDetails.invBasicDetails.holderDetails.length > 0){
                        kpersonalDetails = kclientDetails.invBasicDetails;    
                        console.log("kpersonalDetails--->", kpersonalDetails);                
                    }
                }
            }
            if(kclientDetails.hasOwnProperty('fatcaDetails')){
                if(kclientDetails.fatcaDetails !== null){
                    if(kclientDetails.fatcaDetails.length > 0){
                        kfatcaDetails = kclientDetails.fatcaDetails;                    
                    }
                }
            }
            if(kclientDetails.hasOwnProperty('nomineeDetails')){                
                if(kclientDetails.nomineeDetails !== null){                
                    if(kclientDetails.nomineeDetails.nomineeDetails.length > 0){
                        knomineeDetails = kclientDetails.nomineeDetails;                    
                    }
                }
            }
            if(kclientDetails.hasOwnProperty('bankDetail')){                
                if(kclientDetails.bankDetail !== null){
                    if(kclientDetails.bankDetail.accountNo !== null){
                        kbankDetails = kclientDetails.bankDetail;                    
                    }
                }
            }
            return{
                ...state,
                kycFolioDetails: action.payload,
                savePersonalData: kpersonalDetails,
                saveFatcaData: kfatcaDetails,
                saveNomineeData: knomineeDetails,
                saveBankData: kbankDetails,
                loading: false
            }
        
        case GET_KYCFOLIODATA_PENDING:
            return{
                ...state,
                loading: true
            }

        case GET_ISFC_BANKDET_FAILURE:
            return{
                ...state,
                ifscBankDet: action.payload,
                loading: false,
            }

        case GET_ISFC_BANKDET_SUCCESS:
            return{
                ...state,
                ifscBankDet: action.payload,
                loading: false
            }

        case GET_ISFC_BANKDET_PENDING:
            return{
                ...state,
                loading: true
            }

        case SAVE_BANK_PENDING: 
            return{
                ...state,
                loading: true
            }

        case SAVE_BANK_SUCCESS:
            return{
                ...state,
                bankDetails: action.payload,
                loading: false
            }

        case SAVE_BANK_FAILURE:
            return{
                ...state,
                bankDetails: action.payload,
                loading: false
            }

        case SAVE_NOMINEE_PENDING:
            return{
                ...state,
                loading: true
            }

        case SAVE_NOMINEE_SUCCESS:
            return{
                ...state,
                nomineeDetails: action.payload,
                loading: false
            }

        case SAVE_NOMINEE_FAILURE:
            return{
                ...state,
                nomineeDetails: action.payload,
                loading: false
            }
        
        case SAVE_FATCA_PENDING:
            return{
                ...state,
                loading: true
            }

        case SAVE_FATCA_SUCCESS:
            return{
                ...state,
                fatcaDetails: action.payload,
                loading: false
            }

        case SAVE_FATCA_FAILURE:
            return{
                ...state,
                fatcaDetails: action.payload,
                loading: false
            }

        case SAVE_PERSONAL_PENDING:
            return{
                ...state,
                loading: true
            }

        case SAVE_PERSONAL_SUCCESS:
            return{
                ...state,
                personalDetails: action.payload,
                loading: false
            }

        case SAVE_PERSONAL_FAILURE:
            return{
                ...state,
                personalDetails: action.payload,
                loading: false
            }

        case SAVE_PERSONAL_DATA: 
            return{
                ...state,
                savePersonalData: action.payload
            }

        case SAVE_FATCA_DATA: 
            return{
                ...state,
                saveFatcaData: action.payload
            }

        case SAVE_BANK_DATA: 
            return{
                ...state,
                saveBankData: action.payload
            }

        case SAVE_NOMINEE_DATA: 
            return{
                ...state,
                saveNomineeData : action.payload
            }

        case SAVE_CONTACT_DATA: 
            return{
                ...state,
                saveContactData: action.payload
            }
        
        case FOLIO_CREATE_PENDING: 
            return{
                ...state,
                loading: true
            }
        
        case FOLIO_CREATE_SUCCESS: 
            return{
                ...state,
                folioDetails: action.payload,
                loading: false
            }

        case FOLIO_CREATE_FAILURE: 
            return{
                ...state,
                folioDetails: action.payload,
                loading: false
            }

        case SAVE_FOLIO_DATA:
            return{
                ...state,
                folioData: action.payload,
                loading: false
            }

        case RESET_OB_STATE:
            return {
                ...state,
                clientDetails: null,
                savePersonalData: null,
                saveFatcaData: null,
                saveNomineeData: null,
                saveBankData: null,
                loading: false
            }

        default:
            return state
    }
}