import Api from '.';
import { endpoints } from './endpoints';
export default class confirmationApi extends Api {
    // getConfirmationInfo(data) {
    //     let url = this.buildUrl(endpoints.confirmation.pageInfo)
    //     return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    // }

    getConfirmationInfo(data) {
        let url = this.buildUrl(endpoints.confirmation.getTransaction)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    ditToken(data) {
        let url = this.buildUrl(endpoints.confirmation.ditToken)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    saveTransaction(data) {
        let url = this.buildUrl(endpoints.confirmation.saveTransaction)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    cancelTransaction(data) {
        let url = this.buildUrl(endpoints.confirmation.cancelTransaction)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }


}
