import { put } from 'redux-saga/effects';
import ditReportApi from "../services/ditReportApi";
import {
    GET_DIT_REPORT_LIST_RESPONSE, GET_DIT_REPORT_LIST_FAILED,
    SUBMIT_DIT_REPORT_RESPONSE, SUBMIT_DIT_REPORT_FAILED,
    ADD_REPORT_ISSUE_RESPONSE, ADD_REPORT_ISSUE_FAILED,
    ADD_SUGGESTION_RESPONSE, ADD_SUGGESTION_FAILED,
    FILE_UPLOAD_RESPONSE, FILE_UPLOAD_FAILED
} from '../redux/types/ditReport';
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError';


let api = new ditReportApi();

export function* getDitReportList({ payload, resolve }) {
    let responsePayload = {};

    try {
        let { data } = yield api.getDitReportList(payload);
        if (data.success) {
            let responseData = data.data;
            responsePayload = formatDITList(responseData)
            yield put({ type: GET_DIT_REPORT_LIST_RESPONSE, payload: responsePayload, })
            resolve && resolve(responsePayload)
        }
        else {
            yield put({ type: GET_DIT_REPORT_LIST_FAILED, payload: responsePayload })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: GET_DIT_REPORT_LIST_FAILED, payload: responsePayload })

    }
}


export function* submitDitReport({ payload, resolve }) {
    let responsePayload = {};
    let snackbarPayload = {};
    try {
        let { data } = yield api.submitDitReport(payload);
        if (data.success) {
            let responseData = data.data;
            responsePayload = responseData;

            var resMessage = JSON.parse(responseData.message);
            let parsedMessage = JSON.parse(resMessage);

            if (parsedMessage.Dtinformation[0].Error_Code == "0") {
                snackbarPayload = { open: true, severity: "success", message: parsedMessage.Dtinformation[0].Error_Message }
            } else {
                snackbarPayload = { open: true, severity: "error", message: parsedMessage.Dtinformation[0].Error_Message }
            }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: SUBMIT_DIT_REPORT_RESPONSE, payload: responsePayload, })
            resolve && resolve(data.data)
        }
        else {
            yield put({ type: SUBMIT_DIT_REPORT_FAILED, payload: responsePayload })
            responsePayload = { open: true, severity: "error", message: "Error occured while processing the Request" }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            resolve && resolve(data.data);
        }
    } catch (e) {
        yield put({ type: SUBMIT_DIT_REPORT_FAILED, payload: responsePayload })
        responsePayload = { open: true, severity: "error", message: "Something went wrong" }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload });
        resolve && resolve(responsePayload);

    }
}

export function* addReportIssue({ payload, resolve }) {
    let responsePayload = {};
    let snackbarPayload = {};
    try {
        let { data } = yield api.addReportIssue(payload);
        if (data.success) {
            let responseData = data;
            responsePayload = responseData;

            if (data.data.msgCode === "1") {
                snackbarPayload = { open: true, severity: "success", message: data.data.msgMessage }
            } else {
                snackbarPayload = { open: true, severity: "error", message: data.data.msgMessage }
            }

            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: ADD_REPORT_ISSUE_RESPONSE, payload: responsePayload, })
            resolve && resolve(responsePayload)
        }
        else {
            snackbarPayload = { open: true, severity: "error", message: data.data.msgMessage }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: ADD_REPORT_ISSUE_FAILED, payload: responsePayload })
            resolve && resolve(data)
        }
    } catch (e) {
        responsePayload = { open: true, severity: "error", message: "Something went wrong" }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload });
        yield put({ type: ADD_REPORT_ISSUE_FAILED, payload: responsePayload })
        resolve && resolve(responsePayload);

    }
}

export function* addSuggestionData({ payload, resolve }) {
    let responsePayload = {};
    let snackbarPayload = {};
    try {
        let { data } = yield api.addSuggestion(payload);
        if (data.success) {
            let responseData = data;
            responsePayload = responseData
            if (data.data.msgCode === "1") {
                snackbarPayload = { open: true, severity: "success", message: data.data.msgMessage }
            } else {
                snackbarPayload = { open: true, severity: "error", message: data.data.msgMessage }
            }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: ADD_SUGGESTION_RESPONSE, payload: responsePayload, })
            resolve && resolve(responsePayload)
        }
        else {
            snackbarPayload = { open: true, severity: "error", message: data.data.msgMessage }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: ADD_SUGGESTION_FAILED, payload: responsePayload })
            resolve && resolve(data)
        }
    } catch (e) {
        responsePayload = { open: true, severity: "error", message: "Something went wrong" }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload });
        yield put({ type: ADD_SUGGESTION_FAILED, payload: responsePayload })
        resolve && resolve(responsePayload);

    }
}

export function* fileUpload({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.uploadFeedbackFile(payload);
        console.log("data in file upload", data)
        if (data.success) {
            let responseData = data;
            responsePayload = responseData
            yield put({ type: FILE_UPLOAD_RESPONSE, payload: responsePayload, })
            resolve && resolve(responsePayload)
        }
        else {
            yield put({ type: FILE_UPLOAD_FAILED, payload: responsePayload })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FILE_UPLOAD_FAILED, payload: responsePayload })
        resolve && resolve(responsePayload);

    }
}

function formatDITList(data) {
    let processedList = data.map(({ reportDesc, reportCode }) => {
        return { label: reportDesc, value: reportCode }
    })
    return processedList;
}
