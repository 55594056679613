import { put } from 'redux-saga/effects';
import confirmationApi from "../services/confirmationApi";
import { push } from 'connected-react-router';
import {
    GET_CONFIRMATION_INFO_FAILED, GET_CONFIRMATION_INFO_RESPONSE,
    GET_DIT_TOKEN_FAILED, GET_DIT_TOKEN_RESPONSE,
    SAVE_TRANSACTION_FAILED, SAVE_TRANSACTION_RESPONSE,
    CANCEL_TRANSACTION_FAILED, CANCEL_TRANSACTION_RESPONSE,
} from '../redux/types/confirmation';

import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError';

let api = new confirmationApi();

export function* getConfirmationInfo({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.getConfirmationInfo(payload);
        if (data.success) {
            let responseData = data.data;
            responsePayload = responseData[0];
            yield put({ type: GET_CONFIRMATION_INFO_RESPONSE, payload: responsePayload })
            resolve && resolve(data.data)
        }
        else {
            yield put(push('/sessionExpired'));
            responsePayload = { status: false };
            yield put({ type: GET_CONFIRMATION_INFO_FAILED, payload: responsePayload })
            resolve && resolve(responsePayload)
        }
    } catch (e) {
        yield put(push('/sessionExpired'));
        responsePayload = { status: false };
        yield put({ type: GET_CONFIRMATION_INFO_FAILED, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* ditToken({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.ditToken(payload);
        if (data.success) {
            let responseData = data.data.data;
            responsePayload = responseData
            yield put({ type: GET_DIT_TOKEN_RESPONSE, payload: responsePayload, })
            resolve && resolve(data.data)
        }
        else {
            yield put(push('/sessionExpired'));
            yield put({ type: GET_DIT_TOKEN_FAILED, payload: responsePayload })
        }
    } catch (e) {
        yield put(push('/sessionExpired'));
        yield put({ type: GET_DIT_TOKEN_FAILED, payload: responsePayload })
    }
}

export function* saveTransaction({ payload, resolve }) {
    let responsePayload = {};
    let snackbarPayload = {};
    let placeHolder = [];
    try {
        let { data } = yield api.saveTransaction(payload);
        if (data.success) {
            let responseData = data.data;
            placeHolder = responseData.data;
            responsePayload = placeHolder;
            yield put({ type: SAVE_TRANSACTION_RESPONSE, payload: responsePayload, })
            resolve && resolve(data.data)
        }
        else {
            snackbarPayload = { open: true, severity: "error", message: "Error occured while processing the Request" }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: SAVE_TRANSACTION_FAILED, payload: responsePayload })

            resolve && resolve(data.data)
        }
    } catch (e) {
        snackbarPayload = { open: true, severity: "error", message: "Something Went Wrong" }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
        yield put({ type: SAVE_TRANSACTION_FAILED, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* cancelTransaction({ payload, resolve }) {
    let responsePayload = {};
    let placeHolder = [];
    let snackbarPayload = {};
    try {
        let { data } = yield api.cancelTransaction(payload);
        if (data.success) {
            let responseData = data.data;
            placeHolder = responseData.data;
            responsePayload = placeHolder;
            yield put({ type: CANCEL_TRANSACTION_RESPONSE, payload: responsePayload, })
            resolve && resolve(data.data)
        }
        else {
            snackbarPayload = { open: true, severity: "error", message: "Error occured while processing the Request" }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
            yield put({ type: CANCEL_TRANSACTION_FAILED, payload: responsePayload })
            resolve && resolve(data.data)
        }
    } catch (e) {
        snackbarPayload = { open: true, severity: "error", message: "Something Went Wrong" }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: snackbarPayload })
        yield put({ type: CANCEL_TRANSACTION_FAILED, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}