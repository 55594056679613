import { getReadyServiceWorker } from "./serviceWorkerHelper";

const NotificationData = {
  title: "Would you like to receive Push Notifications?",
  description:
    "We promise to only send you relevant content and give you updates on your transactions",
  primaryText: "Sign me up!",
  secondaryText: "No thanks",
};

export async function getCurrentPushSubscription() {
  const sw = await getReadyServiceWorker();
  return sw.pushManager.getSubscription();
}

export async function registerPushNotifications() {
  // requestPermission();

  console.log("registerPushNotifications", typeof window);

  if (typeof window !== "undefined") {
    if (!("PushManager" in window)) {
      console.info("Push Notification are not supported by this browser");
    }

    const existingSubscription = await getCurrentPushSubscription();
    console.log(
      "existingSubscription",
      existingSubscription,
      process.env?.REACT_APP_NOTIFICATION_APPLICATION_SERVER_KEY
    );

    if (existingSubscription) {
      console.info("Existing Push Notification found");
    }

    const sw = await getReadyServiceWorker();

    const subscription = sw?.pushManager?.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        process.env?.REACT_APP_NOTIFICATION_APPLICATION_SERVER_KEY ||''  });

    await sendPushSubscriptionToServer(subscription);
  }
}

export async function unregisterPushNotification() {
  const existingSubscription = await getCurrentPushSubscription();

  if (!existingSubscription) {
    console.info("No existing push notification found");
  }

  await deletePushSubscriptionFromServer(existingSubscription);

  await existingSubscription.unsubscribe();
}

export async function sendPushSubscriptionToServer(subscription) {
  console.log("Sending push subscription to server", subscription);
  setTimeout(async () => {
    await pushNotificationAlert(NotificationData);
  }, 2000);
}

export async function deletePushSubscriptionFromServer(subscription) {
  console.log("Deleting push subscription from server", subscription);
}

export const pushNotificationAlert = async ({
  title,
  description,
  primaryText,
  secondaryText,
}) => {
  console.log("pushNotificationAlert Push subscription");
  try {
    if (typeof window !== "undefined") {
      const clevertap = window?.clevertap;
      console.log("clevertap>>>", clevertap);
      if (clevertap) {
        clevertap?.notifications?.push({
          // "apnsWebPushId":"<apple web push id>", //only for safari browser
          // "apnsWebPushServiceUrl":"<safari package service url>",//only for safari browser
          titleText: title || "Would you like to receive Push Notifications?",
          bodyText:
            description ||
            "We promise to only send you relevant content and give you updates on your transactions",
          okButtonText: primaryText || "Sign me up!",
          rejectButtonText: secondaryText || "No thanks",
          okButtonColor: "#f28046",
        });
        // console.log("clevertap", clevertap);
        // clevertap.privacy.push({ optOut: true });
        // clevertap.privacy.push({ useIP: true });
      }
    }
  } catch (error) {
    console.log("pushNotificationAlert error:", error);
  }
};

//For Safari
// function requestPermission() {
//     if ('safari' in window && 'pushNotification' in window.safari) {

//         window.safari.pushNotification.requestPermission(
//             'https://localhost:3000/',
//             'https://mf.moamc.com/', {}, function(subscription) {
//                 console.log(subscription);
//                 if(subscription.permission === 'granted') {
//                     let subscriptionInfoSafari = subscription;
//                    let safariToken = subscription.deviceToken;
//                     // sending(safariToken);
//                     // updateSubscriptionOnServer(subscription);
//                 }
//                 else if(subscription.permission === 'denied') {
//                     // TODO:
//                 }
//             });

//     } else {
//         alert("Push notifications not supported.");
//     }
// }
