import { SET_BANK_DETAILS, SET_FOLIO_DETAILS, SET_FOLIO_VALUES } from "../types/manageBank";

export default function reducer(state = null, action) {
    switch (action.type) {
        case SET_BANK_DETAILS:
            return { ...state, newBankDetails: action.payload }
        case SET_FOLIO_DETAILS:
            return { ...state, folioDetails: action.payload }
        case SET_FOLIO_VALUES:
            return { ...state, selectedFolio: action.payload }
        default:
            return state
    }
}
