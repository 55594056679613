

import {
    VALIDATE_OTP, VALIDATE_OTP_SUCCESS, VALIDATE_OTP_FAILURE
} from '../types/validateotp'


// const  ValidateRedeemOTP = (payload) => ({
//     type: VALIDATE_OTP,
//     payload
// })
// export default ValidateRedeemOTP;
let init = {

    data: ""
};

export default function ValidateRedeemOTP(state = init, action) {
    switch (action.type) {
        case VALIDATE_OTP:
            console.info("pending")
            return {
                ...state,
                data: "PENDING",
            }

        case VALIDATE_OTP_SUCCESS:
            console.info("success")
            return {
                ...state,
                data: action.payload,
                // aboutUs: action.payload
            }

        case VALIDATE_OTP_FAILURE:
            return {
                ...state,
                data: "VALIDATE OTP FALIED",
                // aboutUs: action.payload
            }

        default:
            return state
    }
}