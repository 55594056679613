// VERIFY PAN
export const VERIFY_PAN_PENDING = 'VERIFY_PAN_PENDING';
export const VERIFY_PAN_SUCCESS = 'VERIFY_PAN_SUCCESS';
export const VERIFY_PAN_FAILURE = 'VERIFY_PAN_FAILURE';

// MASTER PAN

export const CALL_MASTER_PENDING = 'CALL_MASTER_PENDING';
export const CALL_MASTER_SUCCESS = 'CALL_MASTER_SUCCESS';
export const CALL_MASTER_FAILURE = 'CALL_MASTER_FAILURE';

export const CALL_CVL_PENDING = 'CALL_CVL_PENDING';
export const CALL_CVL_SUCCESS = 'CALL_CVL_SUCCESS';
export const CALL_CVL_FAILURE = 'CALL_CVL_FAILURE';

export const VALIDATE_NEWINV_PENDING = 'VALIDATE_NEWINV_PENDING';
export const VALIDATE_NEWINV_SUCCESS = 'VALIDATE_NEWINV_SUCCESS';
export const VALIDATE_NEWINV_FAILURE = 'VALIDATE_NEWINV_FAILURE';

export const PAN_DETAIL_PENDING = 'PAN_DETAIL_PENDING';
export const PAN_DETAIL_SUCCESS = 'PAN_DETAIL_SUCCESS';
export const PAN_DETAIL_FAILURE = 'PAN_DETAIL_FAILURE';

export const GET_CLIENTDET_PENDING = 'GET_CLIENTDET_PENDING';
export const GET_CLIENTDET_SUCCESS = 'GET_CLIENTDET_SUCCESS';
export const GET_CLIENTDET_FAILURE = 'GET_CLIENTDET_FAILURE';

export const GET_KYCFOLIODATA_PENDING = 'GET_KYCFOLIODATA_PENDING'
export const GET_KYCFOLIODATA_SUCCESS = 'GET_KYCFOLIODATA_SUCCESS'
export const GET_KYCFOLIODATA_FAILURE = 'GET_KYCFOLIODATA_FAILURE'

export const GET_ISFC_BANKDET_PENDING = "GET_ISFC_BANKDET_PENDING";
export const GET_ISFC_BANKDET_SUCCESS = "GET_ISFC_BANKDET_SUCCESS";
export const GET_ISFC_BANKDET_FAILURE = "GET_ISFC_BANKDET_FAILURE";

export const SAVE_BANK_PENDING = "SAVE_BANK_PENDING";
export const SAVE_BANK_SUCCESS = "SAVE_BANK_SUCCESS";
export const SAVE_BANK_FAILURE = "SAVE_BANK_FAILURE";

export const SAVE_NOMINEE_PENDING = "SAVE_NOMINEE_PENDING";
export const SAVE_NOMINEE_SUCCESS = "SAVE_NOMINEE_SUCCESS";
export const SAVE_NOMINEE_FAILURE = "SAVE_NOMINEE_FAILURE";

export const SAVE_FATCA_PENDING = "SAVE_FATCA_PENDING";
export const SAVE_FATCA_SUCCESS = "SAVE_FATCA_SUCCESS";
export const SAVE_FATCA_FAILURE = "SAVE_FATCA_FAILURE";

export const SAVE_PERSONAL_PENDING = "SAVE_PERSONAL_PENDING";
export const SAVE_PERSONAL_SUCCESS = "SAVE_PERSONAL_SUCCESS";
export const SAVE_PERSONAL_FAILURE = "SAVE_PERSONAL_FAILURE";

export const SAVE_PERSONAL_DATA = "SAVE_PERSONAL_DATA";
export const SAVE_FATCA_DATA = "SAVE_FATCA_DATA";
export const SAVE_CONTACT_DATA = "SAVE_CONTACT_DATA";
export const SAVE_NOMINEE_DATA = "SAVE_NOMINEE_DATA";
export const SAVE_BANK_DATA = "SAVE_BANK_DATA";

export const FOLIO_CREATE_PENDING = "FOLIO_CREATE_PENDING"; 
export const FOLIO_CREATE_SUCCESS = "FOLIO_CREATE_SUCCESS"; 
export const FOLIO_CREATE_FAILURE = "FOLIO_CREATE_FAILURE";

export const SAVE_FOLIO_DATA = "SAVE_FOLIO_DATA";

// auth
export const AUTHMOH='AUTHMOH';
export const AUTHFOLIO="AUTHFOLIO";
export const AUTHRESP = "AUTHRESP"

export const RESET_OB_STATE = "RESET_OB_STATE";

export const TYPE_LOGIN='TYPE_LOGIN';