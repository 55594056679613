import Api from '.';
import { endpoints } from './endpoints';
export default class ditReportApi extends Api {
    getDitReportList(data) {
        let url = this.buildUrl(endpoints.dirReports.getDitReportList)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    submitDitReport(data) {
        let url = this.buildUrl(endpoints.dirReports.submitDitReport)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }
    addReportIssue(data) {
        let url = this.buildUrl(endpoints.dirReports.addReportIssueData)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }
    addSuggestion(data) {
        let url = this.buildUrl(endpoints.dirReports.addSuggestionData)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    uploadFeedbackFile(data) {
        let url = this.buildUrl(endpoints.dirReports.uploadFeedbackFile)
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }

}