import { NAV_PENDING, NAV_SUCCESS, NAV_FAILURE } from '../types/preloginType';

let init = {
    navStatus: false,
    navData: null
};

export default function Nav(state = init, action) {
    switch (action.type) {
        case NAV_PENDING:
            console.info("pending")
            return {
                ...state,
                navStatus: true,
            }

        case NAV_SUCCESS:
            console.info("success")
            return {
                ...state,
                navStatus: false,
                navData: action.payload
            }

        case NAV_FAILURE:
            return {
                ...state,
                navStatus: false,
                navData: action.payload
            }

        default:
            return state
    }
}