import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import './css/global.css';
import './assets/vendor/mdi/css/materialdesignicons.min.css';
import Router from './components';
import * as serviceWorker from './lib/serviceWorker';
import { exceptionInit } from './lib/exceptionHandler';
import { Provider } from 'react-redux';
// import configureStore from './redux/store';
import { store, persistor } from './redux/store';
// import persistor from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from './lib/history';
import { PersistGate } from 'redux-persist/integration/react'
import { registerAesKey } from './lib/crypto';
import './services/router/shared/shared.css'
import { QueryClient, QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query-devtools";
registerAesKey(); // intiate the bad ass person in the world
const queryClient = new QueryClient({});
// Let the reducers handle initial state
// const initialState = {}
// const store = configureStore(initialState)
// npm run build:prod
// npm run server:build:prod
// npm run start:server

ReactDOM.render(

    <Provider store={store}>
    
        {/* <BrowserRouter> */}
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
            <QueryClientProvider client={queryClient}>  
                <Router />
            </QueryClientProvider>  
            </ConnectedRouter>
        </PersistGate>
     
        {/* </BrowserRouter> */}
    </Provider>
  
 
   ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

exceptionInit();