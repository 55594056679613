// stp category

export const STP_SCHEME_PENDING = 'STP_SCHEME_PENDING';
export const STP_SCHEME_SUCCESS = 'STP_SCHEME_SUCCESS';
export const STP_SCHEME_FAILURE = 'STP_SCHEME_FAILURE';

// about us

export const ABOUTUS_DATA_PENDING = 'ABOUTUS_DATA_PENDING';
export const ABOUTUS_DATA_SUCCESS = 'ABOUTUS_DATA_SUCCESS';
export const ABOUTUS_DATA_FAILURE = 'ABOUTUS_DATA_FAILURE';


// NAV
export const NAV_PENDING = 'NAV_PENDING';
export const NAV_SUCCESS = 'NAV_SUCCESS';
export const NAV_FAILURE = 'NAV_FAILURE';


// MF DETAIL PAGE

export const MF_DETAIL_PENDING = 'MF_DETAIL_PENDING';
export const MF_DETAIL_SUCCESS = 'MF_DETAIL_SUCCESS';
export const MF_DETAIL_FAILURE = 'MF_DETAIL_FAILURE';


// MF Category page

export const MF_CATEGORY_PENDING = 'MF_CATEGORY_PENDING';
export const MF_CATEGORY_SUCCESS = 'MF_CATEGORY_SUCCESS';
export const MF_CATEGORY_FAILURE = 'MF_CATEGORY_FAILURE';

// MF Category page

export const MF_CATEGORY_DROP_PENDING = 'MF_CATEGORY_DROP_PENDING';
export const MF_CATEGORY_DROP_SUCCESS = 'MF_CATEGORY_DROP_SUCCESS';
export const MF_DETAIL = 'MF_DETAIL';
export const COMPARE_FUND = 'COMPARE_FUND';
export const MF_DETAIL_KM_DROPDOWN = 'MF_DETAIL_KM_DROPDOWN';


// passive Category page

export const PASSIVE_CATEGORY_PENDING = 'PASSIVE_CATEGORY_PENDING';
export const PASSIVE_CATEGORY_SUCCESS = 'PASSIVE_CATEGORY_SUCCESS';
export const PASSIVE_CATEGORY_FAILURE = 'PASSIVE_CATEGORY_FAILURE';
export const SINGLE_SELECTION_PASSIVE = 'SINGLE_SELECTION_PASSIVE';
// passiveDetail
export const PASSIVE_DETAIL_PENDING = 'PASSIVE_DETAIL_PENDING';
export const PASSIVE_DETAIL_SUCCESS = 'PASSIVE_DETAIL_SUCCESS';
export const PASSIVE_DETAIL_FAILURE = 'PASSIVE_DETAIL_FAILURE';

export const HEADER_KEY = "HEADER_KEY";




