export const GET_CONFIRMATION_INFO = "GET_CONFIRMATION_INFO";
export const GET_CONFIRMATION_INFO_RESPONSE = "GET_CONFIRMATION_INFO_RESPONSE";
export const GET_CONFIRMATION_INFO_FAILED = "GET_CONFIRMATION_INFO_FAILED";

export const GET_DIT_TOKEN = "GET_DIT_TOKEN";
export const GET_DIT_TOKEN_RESPONSE = "GET_DIT_TOKEN_RESPONSE";
export const GET_DIT_TOKEN_FAILED = "GET_DIT_TOKEN_FAILED";

export const SAVE_TRANSACTION = "SAVE_TRANSACTION";
export const SAVE_TRANSACTION_RESPONSE = "SAVE_TRANSACTION_RESPONSE";
export const SAVE_TRANSACTION_FAILED = "SAVE_TRANSACTION_FAILED";

export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
export const CANCEL_TRANSACTION_RESPONSE = "CANCEL_TRANSACTION_RESPONSE";
export const CANCEL_TRANSACTION_FAILED = "CANCEL_TRANSACTION_FAILED";
