import { INIT_APP } from "../types/login";
import { LOGOUT } from '../types/init'

export const initApp = (payload) => ({
    type: INIT_APP,
    payload
})

export const logout = () => ({
    type: LOGOUT,
})




