import { put, takeLatest } from 'redux-saga/effects';
import { CALL_MASTER_PENDING, CALL_MASTER_SUCCESS, CALL_MASTER_FAILURE } from '../../redux/types/onboarding';
import Api from '../../services/onboarding/master';

let allApi = new Api();

export function* abc(){
    let apiresponse = yield allApi.getMasters({}) 
    console.info("apiresponse", apiresponse); 
    if(apiresponse.data.success){
        yield put({type: CALL_MASTER_SUCCESS, payload: apiresponse.data.data})
    }else{
        yield put({type: CALL_MASTER_FAILURE, payload: {}})
    }
    
}

export function* getMaster(){
    console.info("saga ob master")
    yield takeLatest(CALL_MASTER_PENDING,abc)
}