import * as type from '../../types/trans';


let init = {
    orderBook:[],
    inprocessOrder:[],
    investmentInprocess:0,
    investmentCounter:0,
    RedemtionInprocess:0,
    orderStatus:false,
    holdingMode: null,
    processMode: null,
    transStatus: null,
    transType: null,
    orderFolio:[]
    
};



export default function orderDetailDetail(state = init, action) {
    switch (action.type) {
        case type.ORDERBOOK_PENDING:
            return{
                ...state,
                orderStatus:true,
            }
            case type.ORDERBOOK_SUCCESS:
                let {investment,inprocess,redemption,counter, holdingMode, processMode, transStatus, transType,AllFolio}=action.Data
                console.log(action.Data,'orderDetail',action.payload)
            return{
                ...state,
                orderStatus:false,
                orderBook:action.Paylaod,
                inprocessOrder:inprocess,
                investmentInprocess:investment,
                RedemtionInprocess:redemption,
                investmentCounter:counter,
                holdingMode: holdingMode,
                processMode: processMode,
                transStatus: transStatus,
                transType: transType,
                orderFolio:AllFolio

            }
            case type.ORDERBOOK_FAIL:
            return{
                ...state,
                orderStatus:false,
                orderBook:action.Paylaod,
                holdingMode: null,
                processMode: null,
                transStatus: null,
                transType: null,
                orderFolio:[]
            }
        default:
            return state
    }
}