import React from 'react'
import { Snackbar, makeStyles, Slide } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    // snackbar: {
    //     position: 'static',
    // },
}));


const MSnackBar = (props) => {
    const classes = useStyles();
    const { hideDuration = 2000 } = props;

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={hideDuration}
            onClose={props.handleClose}
            className={classes.snackbar}
            anchorOrigin={props?.anchorOrigin ?
                props?.anchorOrigin
                :{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        // TransitionComponent={SlideTransition}
        >
            <Alert onClose={props.handleClose} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default MSnackBar;