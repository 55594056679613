import { combineReducers } from 'redux';
import counter from './counter';
import init from './init';
import history from '../../lib/history';
import { connectRouter } from 'connected-react-router';
import authenticateReducer from './authenticate';
import drawerReducer from './drawer';
import loader from './loader';
import responseError from './responseError';
import preloginReducer from './preloginReducers'
import Nav from './navReducer'
import MfDetail from './mfDetailReducer';
import PassiveDetail from './passiveReducer';
import masterReducer from './onboarding/masters';
import personalReducer from './onboarding/personal'
import Trans from './trans/transReducer';
import OrderBook from './trans/orderBook';
import Category from './trans/category';
import manageBank from './manageBank'
import CartDetails from './trans/cart';
import Auth from './trans/authReducer';
import confirmation from './confirmation';
import ditReport from './ditReport';
import initApp from './initApp';
import reports from './reports';
import validateotp from './validateotp';
import redeemOtp from './redeemotp';
import fabPlan from './fabPlan';


let historyR = !process.env.IS_SERVER ? { router: connectRouter(history) } : {};



export default combineReducers({
  counter,
  ...historyR,
  init,
  authenticateUser: authenticateReducer,
  isDrawerOpen: drawerReducer,
  responseError,
  loader,
  aboutUs: preloginReducer,
  Nav: Nav,
  MfDetail: MfDetail,
  passive: PassiveDetail,
  masterReducer,
  personalReducer,
  trans: Trans,
  orderbook: OrderBook,
  category: Category,
  manageBank,
  CartDetails: CartDetails,
  Auth: Auth,
  confirmation,
  ditReport,
  initApp,
  reports,
  validateotp,
  redeemOtp,
  fabPlan
})
