import { OPEN_DRAWER, CLOSE_DRAWER } from "../types/drawer";

const initialState = false

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_DRAWER:
            return true;
        case CLOSE_DRAWER:
            return false;
        default:
            return state
    }
}