import Api from '.';
import { endpoints } from './endpoints';
export default class AppApi extends Api {

    init(data) {
        let url = this.buildUrl(endpoints.app.init)
        console.info("endpoints", endpoints)
        console.info("init url", url);
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    initFolioList() {
        let url = this.buildUrl(endpoints.additionalPurchase.getFolioList)
        console.log('folio list url', url)
        return this.fetch(url, "GET").then(response => response)
    }
    ValidateRedeemOTP(data) {
        let url = this.buildUrl(endpoints.preLogin.ValidateRedeemOTP)
        console.log("start details111", url)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }
    redeemOtp(data) {
        let url = this.buildUrl(endpoints.preLogin.redeemOtp)
        console.log("start details111", url)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

}
