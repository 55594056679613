//let url = "https://" + window.location.host + "/moslcms/cms/";
let url = process.env.CMS_BASE_URL;
//let baseAPI = "http://dxtechsol.com:8083/api/v1/";
let baseAPI = process.env.REACT_APP_BASE_URL + '/';
let manual = process.env.REACT_APP_MANUAL_URL;
console.log(url, "<--urlEndpoint-->", baseAPI);
export const endpoints = {
    baseUrl: baseAPI, //process.env.REACT_APP_BASE_URL,
    manual_URL: manual,
    app: {
        init: '/init'
    },
    user: {
        getUser: '/users'
    },
    login: {
        generateOtp: '/login/generateOtp',
        verifyOtp: '/login/authenticateUser',
        logout: `/login/logout`,
        common: `${baseAPI}login/common`,
        menuRoleWise: '/menuRoleWise'

    },
    additionalPurchase: {
        getFolioList: '/additionalPurchase/folioList',
    },
    preLogin: {
        internationalBusiness: `${baseAPI}getIntBusiness`,
        aboutUs: `${baseAPI}Aboutus`,
        faq: `${baseAPI}getFAQ`,
        NavDetail: `${baseAPI}NAVDetails`,
        HistoricalFilter: `${baseAPI}HistoricalFilter`,
        MFHome: `${baseAPI}MFHome`,
        passiveHome: `${baseAPI}PassiveFundHome`,
        fundDetail: `${baseAPI}FundDetail`,
        FundCategory: `${baseAPI}FundCategory`,
        //FundCategoryNew:" https://mf.moamc.com/mutualfund/api/v1/FundCategoryNew" ,
    // FundCategoryNew: 'https://www.motilaloswalmf.com/mutualfund/api/v1/FundCategoryNew',
       FundCategoryNew: `${baseAPI}FundCategoryNew`,
       STPCategory: `${baseAPI}SchemesForSTP`,
        GetFundsForFilter: `${baseAPI}GetFundsForFilter`,
        CompareFunds: `${baseAPI}CompareFunds`,
        download: `${baseAPI}getDownloads`,
        SearchDownloads: `${baseAPI}SearchDownloads`,
        ContactUs: `${baseAPI}ContactUs`,
        enquiry: `${baseAPI}enquiry`,
        perforamceGraph: `${baseAPI}PerformanceGraph`,
        perforamceGraphNew: `${baseAPI}PerformanceGraphNew`,
        ValidateRedeemOTP: `/ValidateRedeemOTP`,
        redeemOtp: `/redeemOtp`,
    },
    ob: {
        masters: `${baseAPI}ob/getMasters`,
        common: `${baseAPI}ob/common`,
        ifscBankDet: `${baseAPI}ob/getBankFromIFSC`
    },
    trans: {
        common: `${baseAPI}trans/common`,
        commonGet: `${baseAPI}trans/commonGet`,
        BulkUploadTransaction: `${baseAPI}trans/bulkUploadDit`,
        StopAccountDetails: `/c/trans/StopAccountDetails`
    },
    extra: {
        GetSchemeDetForSIPDashboard: `${baseAPI}/GetSchemeDetForSIPDashboard`,
        getSchemeName: `${baseAPI}getSchemeName`,
    },
    confirmation: {
        getTransaction: "/getTransaction",
        ditToken: "/ditToken",
        saveTransaction: "/saveTransaction",
        cancelTransaction: "/cancelTransaction",
    },
    dirReports: {
        getDitReportList: `/getDitReportList`,
        submitDitReport: `/submitDitReport`,
        addReportIssueData: '/addReportIssueData',
        addSuggestionData: '/addSuggestionData',
        uploadFeedbackFile: 'uploadFeedbackFile',
    },
    checkCookies: `${baseAPI}chklogin`,
    mediaRoom: {
        AMCPressClick: `${baseAPI}AMCPressClick`,
        GetAMCMedia: `${baseAPI}GetAMCMedia`,
    },
    reports: {
        CheckMFPanDist: `${baseAPI}CheckMFPanDist`,
        ClientMF: `${baseAPI}ClientMF`,
        GetMFAccountStatement: `${baseAPI}GetMFAccountStatement`,
        CapitalGainLoss: `${baseAPI}capitalGainLossStatementV2`,
        SIPDashboard: `${baseAPI}SIPDashboard`,
        bitlyLink: `/bitlyLink`,
        reportSend: `/reportSend`,
    },
    fabPlans: {
        fabScheme: "c/cms/fabScheme",
        CashFlowCalculatorFrequency: "c/fabPlan/CashFlowCalculatorFrequency",
        folioDetails: "c/fabPlan/folioDetails",
        withdrawPlotting: "c/fabPlan/withdrawPlotting",
    },
    euin: {
        GetEuinDetail: "c/euin/GetEuinDetail",
        SaveEuinDefault: "c/euin/SaveEuinDefault",
    },
    logout: {
        removeCookie: '/removeCookie'
    }

}
