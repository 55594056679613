import * as type from '../../types/trans';

let init = {
    schemeCode: null,
    cartBoxData: [],
    activeInvestor: "",
    investorDrop: [],
    panFolio: [],
    folioSelection: "",
    obFolio: null,
    obHolding: null,
    quickDemat: null

}

export default function categoryReducer(state = init, action) {
    switch (action.type) {

        case type.CAT_SAVE_SCHEMECODE:
            return {
                ...state,
                schemeCode: action.payload,
            }

        case type.FOLIO_SELECTION_DEMAT:
            return {
                ...state,
                quickDemat: action.payload,
            }
        case type.SAVE_CART_BOX:
            let aaa = state.cartBoxData;
            aaa.push(action.payload)
            return {
                ...state,
                cartBoxData: aaa,
            }

        case type.DEL_CART_BOX:
            /* let bbb = [];
            state.cartBoxData.map((curr, ind)=> {
                console.log("curr--->", curr);
                if(curr.OrderId !== action.payload){
                    bbb.push(curr);
                }
            }) */
            let bbb = state.cartBoxData;
            bbb = bbb.filter(function (obj) {
                return Object.keys(obj)[0] !== action.payload;
            })
            return {
                ...state,
                cartBoxData: bbb,
            }

        case type.RESET_CART_BOX:
            return {
                ...state,
                cartBoxData: [],
            }
        case type.ACTIVE_INVESTOR:
            return {
                ...state,
                activeInvestor: action.payload,
            }
        case type.ACTIVE_PANDROPDOWN:
            return {
                ...state,
                investorDrop: action.payload,
            }
        case type.PAN_AGAIN_FOLIO:
            return {
                ...state,
                panFolio: action.payload,
            }
        case type.FOLIO_SELECTION_CART:
            return {
                ...state,
                folioSelection: action.payload,
            }

        case type.OB_FOLIOHOLDING_DATA:
            return {
                ...state,
                obFolio: action.payload.folioNumber,
                obHolding: action.payload.holdingMode
            }
        default:
            return state
    }
}