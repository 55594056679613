import { endpoints } from "./endpoints";
import client from "../lib/crypto/client";
import globalVariable from "./globalVariable";

const getFetchBadAssSecure = () => {
  if ("object" === typeof window) {
    let getBadObj =
      "object" === typeof window.badAssGuys
        ? window.badAssGuys.badAssSecure
        : ""; // "6693614066929259"
    return getBadObj;
  } else {
    return "";
  }
};

const fetchHeader = {
  "Content-Type": "application/json",
  "User-Agent": "DITWEB",
  "user-agent": "DITWEB",
  UserAgent: "DITWEB",
}; //"appId":data
const fetchHeaderFile = {
  "Content-Type": "application/json",
  mimeType: "multipart/form-data",
};

const isCrypto = process.env.REACT_APP_CRYPTO === "on" ? true : false;

export default class Api {
  constructor() {
    this.crypto = new client(); //crypto instance
  }

  fetch = (url, method, body) => {
    let opt = {
      method: method,
      headers: fetchHeader,
      body: body,
      //credentials: 'same-origin'
    };
    // let appid = "";
    let getBadAssGuySecure = getFetchBadAssSecure(); // fetch the bad ass secure guy
    // if (getBadAssGuySecure) { // add the encrypted AES key to header
    //     opt.headers.meta = getBadAssGuySecure;
    // }

    if (getBadAssGuySecure) {
      // add the encrypted AES key to header
      opt.headers.meta = getBadAssGuySecure;
    }

    // isCrypto = true;
    if (body && isCrypto) {
      // add encrypted body if crypto is on
      let encryptedBody = { body: this.crypto.encryptBody(body) };
      console.log("encryptedBody", encryptedBody);
      opt.body = JSON.stringify(encryptedBody);
    }
    console.log("fetchSujeet", url, opt);

    return fetch(url, opt).then((response) =>{
      // let events = globalVariable?.getPropertyByName("events");
      // events.publish('httpError', 'failed');
         return response.json()
      }
    ).then(res => {
      if (res.hasOwnProperty('code')) {
        if (res?.data?.code === 401 || res?.code === 401 || res?.status === 401) {
          let events = globalVariable?.getPropertyByName("events");
          events.publish('httpError', 'failed');
          return res;
        } else {
          return res;
        }
      } else {
        return res;
      }
    });
  };

  fetchUpload = (url, myHeaders, body) => {
    // let opt = {
    //     method: method,
    //     headers: fetchHeader,
    //     body: body,
    //     //credentials: 'same-origin'
    // }
    // let appid = "";

    var opt = {
      method: "POST",
      headers: myHeaders,
      body: body,
      // redirect: 'follow'
    };

    var myHeaders = new Headers();

    let getBadAssGuySecure = getFetchBadAssSecure(); // fetch the bad ass secure guy
    if (getBadAssGuySecure) {
      // add the encrypted AES key to header
      opt.headers.meta = getBadAssGuySecure;
      opt.headers.appid = "FFED4F7CMAED1A43CEM9DAAC14A4D3FCBCCC";
      opt.headers.token =
        "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkFSTi0wMDMyIiwicm9sZSI6IkQiLCJhcHBpZCI6IkFBMkY5MzYzTThGOENBNDA4RU04MzlCQzFFMEQ4NjBCNDVERiIsIm5iZiI6MTY2MzY3MjM1MCwiZXhwIjoxNjYzNjc1MDUwLCJpYXQiOjE2NjM2NzMyNTAsImlzcyI6Im1vc2xfYW1jX2FwaSIsImF1ZCI6ImFtY19hcGkifQ.p0j9Da0KUdt36UE-fqn19tQkksKUYtnqLubnE73pxOLCy0mpziU3uQNg6Yc6hBXBuKLk16VCUK61DbqiVQo2ofs1nD4mfZS6JeG_4my3SDJaRqG-ekU8AUmHUuwj7tXpN7TRW6tOU2-KuydU1Aeutx1lX3JCbAD_bYVcqEOvqWtLl3eFVT7iHe_UjZVojet2NIhhf35RZbwYVHw2tTWiAsG0uMNhVXqwvnC909Zv2oKZedWWkvyvcT_TIIWnjF2A1ImlaMhycIkJHtrAOclk4cXxbHk587mqg1Cb9ks3QTyNSRoJpHS12-4zxipGKmK_oN6yyQrDZx7VNaxE03IhMg";
      opt.headers.refreshToken =
        "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkFSTi0wMDMyIiwicm9sZSI6IkQiLCJhcHBpZCI6IkFBMkY5MzYzTThGOENBNDA4RU04MzlCQzFFMEQ4NjBCNDVERiIsInR0eXAiOiJyZWZyZXNoX3Rva2VuIiwibmJmIjoxNjYzNjcyMzUxLCJleHAiOjE2NjM3ODEyNTEsImlhdCI6MTY2MzY3MzI1MSwiaXNzIjoibW9zbF9hbWNfYXBpIiwiYXVkIjoiYW1jX2FwaSJ9.P65pwntB30WF4EmTYwYYf7EUvgFPwXOFUGT21FJ0F_Ksq_9VliXaEsuhMycCDlXHGS2BhZOJnsrCQqYdZiceiG4FIAnzXlqn3RcyOlQyIkaI5zT7gmz6k9IsDOqUNiRsok3nNIjHMmunWOUqJC2XVl5F6P_bEj3iPclMv4Jgd1FjWsspAxObSoC-CAle-1s-_rcVcovlAc8khA6aL4N6_G4JnYYEqh1Z-rJc_E-5b0b04Q9IFkAaBkgYnVFd47CYfYfEVsuyEIIGuKwcRzbs561_rJk9yyTs4b0dQ1X0r7lXscGJi94XL6O5-XYZ9Vi9Bdu_ula2Mv5_gr3Etg-NVg";
    }

    console.log("fetchUpload", url, opt);
    return fetch(url, opt).then((response) => response.json());
  };

  fetchFile = (url, method, body) => {
    let opt = {
      method: method,
      headers: fetchHeaderFile,
      body: body,
      credentials: "same-origin",
    };

    return fetch(url, opt).then((response) => response.json());
  };

  fetchNormal = (url, method, body) => {
    let opt = {
      method: method,
      headers: fetchHeader,
      body: body,
      //credentials: 'same-origin'
    };

    return fetch(url, opt).then((response) => response.json());
  };

  buildUrl = (path, urlType = "") => {
    if (urlType === "full") {
      return `${path}`;
    } else {
      return `${endpoints.baseUrl}${path}`;
    }
  };
}
