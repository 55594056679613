import Api from '.';
import { endpoints } from './endpoints';

export default class reportsApi extends Api {

    CheckMFPanDist(data) {
        let url = this.buildUrl(endpoints.reports.CheckMFPanDist, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    ClientMF(data) {
        let url = this.buildUrl(endpoints.reports.ClientMF, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    GetMFAccountStatement(data) {
        let url = this.buildUrl(endpoints.reports.GetMFAccountStatement, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    CapitalGainLoss(data) {
        let url = this.buildUrl(endpoints.reports.CapitalGainLoss, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    SIPDashboard(data) {
        let url = this.buildUrl(endpoints.reports.SIPDashboard, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    Report_Send(data) {
        let url = this.buildUrl(endpoints.reports.reportSend)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    Bitaly_Link(data) {
        let url = this.buildUrl(endpoints.reports.bitlyLink)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }



}