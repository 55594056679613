import Api from '../index';
import { endpoints } from '../endpoints';

export default class TransApi extends Api {
    common(data) {
        console.log('ddddd', data)
        let url = this.buildUrl(endpoints.trans.common, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    commonGet(data) {
        console.log("commonGetdata->", data)
        let url = this.buildUrl(endpoints.trans.commonGet, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    fundDetail(data) {
        let url = this.buildUrl(endpoints.preLogin.FundCategory, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getSIPScheme(data) {
        let url = this.buildUrl(endpoints.extra.GetSchemeDetForSIPDashboard, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getSchemeName(data) {
        let url = this.buildUrl(endpoints.extra.getSchemeName, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    checkCookies(data) {
        let url = this.buildUrl(endpoints.checkCookies, "full")
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    uploadBulkFile(headers, payload){   
        let url = this.buildUrl(endpoints.trans.BulkUploadTransaction, "full")
        return this.fetchUpload(url, headers, payload).then(response => response);
    }
}
