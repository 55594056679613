export const PORTFOLIO_DATA_PENDING = 'PORTFOLIO_DATA_PENDING';
export const PORTFOLIO_DATA_SUCCESS = 'PORTFOLIO_DATA_SUCCESS';
export const PORTFOLIO_DATA_FAIL = 'PORTFOLIO_DATA_FAIL';

export const PORTFOLIO_CLEAR_REDUX = 'PORTFOLIO_CLEAR_REDUX';


export const INVESTER_PAN_ADD = "INVESTER_PAN_ADD"


export const ZBF_FOLIO_SUCCESS = 'ZBF_FOLIO_SUCCESS';
export const ZBF_FOLIO_FAIL = 'ZBF_FOLIO_FAIL';
export const NEW_FUND_SET = "NEW_FUND_SET";
// existing constiner

export const EXISTING_BOX = "EXISTING_BOX"

// order book

export const ORDERBOOK_PENDING = "ORDERBOOK_PENDING";
export const ORDERBOOK_SUCCESS = "ORDERBOOK_SUCCESS";
export const ORDERBOOK_FAIL = "ORDERBOOK_FAIL";

// folio Desc
export const FOLIODESC_PENDING = "FOLIODESC_PENDING";
export const FOLIODESC_SUCCESS = "FOLIODESC_SUCCESS";
export const FOLIODESC_FAIL = "FOLIODESC_FAIL";

// end folio Desc


export const PORTFOLIO_SINGLE = "PORTFOLIO_SINGLE";

// singleSchemeCode
export const PORTFOLIO_SCHEME_CODE = "PORTFOLIO_SCHEME_CODE";

// select single folio

export const PORTFOLIO_FOLIO_CODE = "PORTFOLIO_FOLIO_CODE";

export const PORTFOLIO_HOLDING_TYPE = "PORTFOLIO_HOLDING_TYPE";

// single bank
export const PORTFOLIO_SELECTED_BANK = "PORTFOLIO_SELECTED_BANK";



export const ADD_TO_CART_PENDING = "ADD_TO_CART_PENDING";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const SINGLE_CART_DATA = "SINGLE_CART_DATA";

export const SIPDASHBOARD_PENDING = "SIPDASHBOARD_PENDING";
export const SIPDASHBOARD_SUCCESS = "SIPDASHBOARD_SUCCESS";
export const SIPDASHBOARD_FAILURE = "SIPDASHBOARD_FAILURE";

export const SWPDASHBOARD_PENDING = "SWPDASHBOARD_PENDING";
export const SWPDASHBOARD_SUCCESS = "SWPDASHBOARD_SUCCESS";
export const SWPDASHBOARD_FAILURE = "SWPDASHBOARD_FAILURE";

export const STPDASHBOARD_PENDING = "STPDASHBOARD_PENDING";
export const STPDASHBOARD_SUCCESS = "STPDASHBOARD_SUCCESS";
export const STPDASHBOARD_FAILURE = "STPDASHBOARD_FAILURE";

export const FILL_FOLIO_SCHEME = 'FILL_FOLIO_SCHEME';

// action

export const PORTFOLIO_SCHEME_ACTION = "PORTFOLIO_SCHEME_ACTION";

// action category

export const CAT_SAVE_SCHEMECODE = "CAT_SAVE_SCHEMECODE";


// cart
export const GET_CART_PENDING = "GET_CART_PENDING";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const SIP_AMOUNT = "SIP_AMOUNT";
export const LUM_AMOUNT = "LUM_AMOUNT";
export const ISIP_AMOUNT = "ISIP_AMOUNT";
export const CART_ID = "CART_ID";
export const CART_ARRAY = "CART_ARRAY";
export const BANK_NAME = "BANK_NAME";
export const BANK_NUMBER = "BANK_NUMBER";
export const CHEQUE_MODE = "CHEQUE_MODE";
export const ISIP_TYPE = "ISIP_TYPE";
export const LUMP_TYPE = "LUMP_TYPE";
export const SIP_TYPE = "SIP_TYPE";
export const UTR_NO = "UTR_NO";
export const BROKER_CART_ID = "BROKER_CART_ID";



export const NEW_USER_DATA = "NEW_USER_DATA";

export const SAVE_CART_BOX = "SAVE_CART_BOX";
export const DEL_CART_BOX = "DEL_CART_BOX";
export const RESET_CART_BOX = "RESET_CART_BOX";

export const PUSH_DIST_CARTID_REG_BROKER = "PUSH_DIST_CARTID_REG_BROKER"
export const PUSH_DIST_CARTID_DIR_BROKER = "PUSH_DIST_CARTID_DIR_BROKER"

// cart page validation

export const SHOW_BANK_ERR = "SHOW_BANK_ERR";
export const SHOW_UTRNO_ERR = "SHOW_UTRNO_ERR";

export const UPDATE_GUEST_MENU = "UPDATE_GUEST_MENU"
export const SET_DIST_FLAG = "SET_DIST_FLAG"

export const GET_ALL_FOLIOS = "GET_ALL_FOLIOS";

export const PROCESS_FOLIOS_FOR_SELECT = "PROCESS_FOLIOS_FOR_SELECT";

// active Investor


export const ACTIVE_INVESTOR = 'ACTIVE_INVESTOR';
export const ACTIVE_PANDROPDOWN = "ACTIVE_PANDROPDOWN";

// local action
export const SAVE_CVL_DATA = "SAVE_CVL_DATA";

export const RESET_SIP_DASHBOARD = "RESET_SIP_DASHBOARD";
export const RESET_GUEST_MENU = "RESET_GUEST_MENU";

export const PAN_AGAIN_FOLIO = "PAN_AGAIN_FOLIO";
export const FOLIO_SELECTION_CART = "FOLIO_SELECTION_CART"
export const OB_FOLIOHOLDING_DATA = "OB_FOLIOHOLDING_DATA"
export const FOLIO_SELECTION_DEMAT = "FOLIO_SELECTION_DEMAT"

export const HIDE_INVESTED_FUND = "HIDE_INVESTED_FUND"
export const ACTIVE_LIST_CONTAINER_DATA = "ACTIVE_LIST_CONTAINER_DATA";

export const EUIN_LIST = "EUIN_LIST";
export const EUIN_LIST_RESPONSE = "EUIN_LIST_RESPONSE";
export const EUIN_LIST_FAIL = "EUIN_LIST_FAIL";
