import React from 'react';

const Footer=(props)=>{
    return (
        <footer className="footer">
        <div className="footer-inner-wraper">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright ©</span>            
            </div>
        </div>
        <div className="footer-inner-wraper">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-left best-site-txt">Site best viewed in Google Chrome (Latest version), Firefox (Latest version), Safari (Latest version), Microsoft Edge(Latest version)</span>
            </div>
        </div>
        </footer>
    )
}

export default Footer