import {
    SUBMIT_DIT_REPORT_RESPONSE, SUBMIT_DIT_REPORT_FAILED,
    GET_DIT_REPORT_LIST_RESPONSE, GET_DIT_REPORT_LIST_FAILED,
    ADD_REPORT_ISSUE_RESPONSE, ADD_REPORT_ISSUE_FAILED,
    ADD_SUGGESTION_RESPONSE, ADD_SUGGESTION_FAILED,
    FILE_UPLOAD_RESPONSE, FILE_UPLOAD_FAILED
} from "../types/ditReport";

const initialState = {
    submitReport: {},
    reportList: {},
    addReportIsuue: {},
    addSuggestion: {},
    fileUpload: {},
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SUBMIT_DIT_REPORT_RESPONSE:
            return { ...state, submitReport: action.payload }
        case SUBMIT_DIT_REPORT_FAILED:
            return { ...state, submitReport: action.payload }

        case GET_DIT_REPORT_LIST_RESPONSE:
            return { ...state, reportList: action.payload }
        case GET_DIT_REPORT_LIST_FAILED:
            return { ...state, reportList: action.payload }

        case ADD_REPORT_ISSUE_RESPONSE:
            return { ...state, addReportIsuue: action.payload }
        case ADD_REPORT_ISSUE_FAILED:
            return { ...state, addReportIsuue: action.payload }

        case ADD_SUGGESTION_RESPONSE:
            return { ...state, addSuggestion: action.payload }
        case ADD_SUGGESTION_FAILED:
            return { ...state, addSuggestion: action.payload }

        case FILE_UPLOAD_RESPONSE:
            return { ...state, fileUpload: action.payload }
        case FILE_UPLOAD_FAILED:
            return { ...state, fileUpload: action.payload }
        default:
            return state
    }

}