import React, { useState, useEffect, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
//import { HeaderKey } from '../../redux/actions/trans/authAction';
import TopJson from "./top.json";
import Image from "../atom/image";
import HeaderMain from "./menuheader";
import "./header.css";

const Header = (props) => {
  const [topKey, setTopKey] = useState("PARTNER");
  const [activeNav, setActiveNav] = useState("home");
  const [mobileMode, setmobileMode] = useState(false);
  const [isProWebApp, setisProWebApp] = useState(false);
  const history = useHistory()
  //const router = useRouter();
  console.log(props.location.pathname, "routerCHECKING");
  useEffect(() => {
    let getKey = localStorage.getItem("topKey");
    if (getKey !== null) {
      setTopKey("PARTNER"); //getKey
    } else {
      localStorage.setItem("topKey", "PARTNER");
    }
    console.log(getKey, "getKey");
    // localStorage.setItem('topKey', "MF");
  }, []);
  useEffect(() => {
    let urlName = props.location.pathname;
    TopJson.map((curr, index) => {
      if (curr.url === urlName) {
        setTopKey(curr.filter);

      }
    });
  }, [topKey]);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      setisProWebApp(true);
    } else {
      setisProWebApp(false);
    }
  }, [])

  useEffect(() => {
    let urlName = props.location.pathname; //router.pathname;
    if (HeaderMain[topKey] !== undefined) {
      HeaderMain[topKey].map((curr, index) => {
        console.log(curr, "sujeetINDEX,", urlName);
        if (curr.url == urlName) {
          setActiveNav(curr.Heading.toLowerCase());
        }
      });
    }
    localStorage.setItem("topKey", "PARTNER");
  }, [props.location.pathname, topKey]);

  useEffect(() => {
    if (window.innerWidth <= "991") {
      // mobile screen
      setmobileMode(true)
    } else {
      // desktop screen
      setmobileMode(false)
    }
  }, []);
  // router.pathname
  //let dispatch = useDispatch();
  //const topKey = useSelector(state => state.Auth.headerKey)
  const headerUP = () => {
    localStorage.setItem("topKey", "PARTNER");
  };

  const openMobile = () => {
    document.querySelector("body").classList.toggle("wsactive");
  };
  const topHeadingMobile = (e) => {
    let getCur = e.target.value;
    let getObj = "";
    TopJson.map((curr, index) => {
      if (curr.filter === getCur) {
        getObj = curr;
      }
    });
    if (getObj !== "") {
      let { internal, filter, url } = getObj;
      if (internal) {
        setTopKey(filter);
        localStorage.setItem("topKey", filter);
        window.location.href = url;
      } else {
        window.location.href = url;
      }
    }
  };
  const topHeading = (data, redirectFlag = true) => {
    //alert('')
    console.log(data, "data");
    let { internal, filter, url } = data;
    if (internal) {
      //alert(internal,'internal')
      setTopKey(filter);
      localStorage.setItem("topKey", filter);
      window.location.href = url;
      //props.history.push(url)
      //dispatch(HeaderKey(filter))
    } else {
      if (redirectFlag) {
        window.location.href = url;
      }
    }
  };

  let loginFlag = true;
  let quickInvest = true;
  let pageURL = "/login";
  if (topKey === "ETF") {
    loginFlag = false;
    quickInvest = false;
  }
  if (topKey === "AIF") {
    loginFlag = true;
    quickInvest = false;
    pageURL = "https://mfs.kfintech.com/MotilalAif/";
  }
  if (topKey === "PARTNER") {
    loginFlag = true;
    quickInvest = false;
    pageURL = "/investonline/dist";
  }
  console.log(HeaderMain, "HeaderMain", topKey);
  return (
    <React.Fragment>
      {/* <div className="headtoppart clearfix">
                <div className="headerwp clearfix">
                    
                </div>
            </div> */}
      <div className="headerfull">
        <div className="desktoplogo">
          <div className="row desktoplogopanel">
            <div className="col-md-2 col-auto moheader-logo-sec">
              <a
                id="wsnavtoggle"
                onClick={openMobile}
                className={`${!isProWebApp ? 'wsanimated-arrow' : ''}`}
              >
                <span></span>
              </a>
              <a href="/" className="logo-a">
                <Image
                  image="prelogin-header/moamc-logo-new.png"
                  class="d-none d-sm-inline-block"
                />
                <Image
                  image="prelogin-header/moamc-logo-new.png"
                  class="d-inline-block d-sm-none"
                />
              </a>
            </div>
            <div className="col-auto mob-buttons">
              {/* Mobile Header */}
              <div className="wsmobileheader clearfix">
                <div className="mob-login-sec">
                  {/* <a
                    href="/login?returnUrl=/transact/quick-invest"
                    className="headerquickinvest-btn-mob"
                  >
                    <Image image="prelogin-header/quick-invest-icon.svg" />
                  </a> */}
                  <a
                    href={pageURL}
                    onClick={() => headerUP()}
                    className="headerlogin-btn-mob">
                    <Image image="prelogin-header/login-icon.svg" />
                  </a>
                </div>
              </div>
              {/* Mobile Header */}
            </div>
            <div class="col pr-0">
              <div class="moamc-menu-sec">
                <div class="moamc-menu-productbox">
                  <div className="headertopleft clearfix">
                    <ul
                      className="jeg_menu jeg_top_menu sf-js-enabled sf-arrows amc-product-desktop"
                      style={{ touchAction: "pan-y" }}
                    >
                      {TopJson.map((curr, index) => {
                        return (
                          <li
                            id="menu-item-704"
                            key={index}
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704"
                          >
                            <a
                              to="#"
                              className={`${curr.filter === topKey ? "webdomain-active" : ""
                                }`}
                              onClick={() => topHeading(curr)}
                            >
                              {curr.Heading}
                            </a>
                          </li>
                        );
                      })}
                      {/* <li id="menu-item-703" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-703"><a href="#" className="webdomain-active">MUTAL FUND</a></li>
                                            <li id="menu-item-704" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704"><a href="#">PMS</a></li>
                                            <li id="menu-item-705" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-705"><a href="#">Partners</a></li>
                                            <li id="menu-item-706" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-706"><a href="#">International Business</a></li>
                                            <li id="menu-item-711" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-711"><a href="#">LEARNING &amp; INSIGHTS</a></li>
                                            <li id="menu-item-712" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-712"><a href="#">ABOUT US</a></li> */}
                    </ul>
                    <ul className="groupwebsite-list amc-product-mob">
                      <li aria-haspopup="true" className="groupwebsitedropdown">
                        <select
                          className="mobilet"
                          value={topKey}
                          onChange={(e) => topHeadingMobile(e)}
                        >
                          {TopJson.map((curr, index) => {
                            return (
                              <option
                                value={curr.filter}
                                id={`menu-item-70${index}`}
                                key={index}
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704"
                              >
                                {curr.Heading}
                              </option>
                            );
                          })}
                        </select>
                        {/* <a href="#">MUTUAL FUND<i className="fas fa-angle-down"></i></a> */}
                        {/* <ul className="sub-menu">
                                                    {TopJson.map((curr, index) => {
                                                        return (
                                                            <li id={`menu-item-70${index}`} key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704"><a  onClick={() => topHeading(curr)}>{curr.Heading}</a></li>
                                                        )
                                                    })}
                                                
                                                </ul> */}
                      </li>
                    </ul>
                  </div>
                  <div class="col-auto des-search-sec">
                    {/*<div class="jeg_nav_item jeg_search_wrapper jeg_search_popup_expand">
                                <a href="#" class="jeg_search_toggle"><i class="fas fa-search"></i></a>
                                <form action="#" method="get" class="jeg_search_form" target="_top">
                                 <input name="s" class="jeg_search_input" placeholder="Search..." type="text" value="" autocomplete="off"> 
                                <button type="submit" class="jeg_search_button btn"><i class="fa fa-search"></i></button>
                            </form>
                            <div class="jeg_search_result jeg_search_hide with_result">
                                <div class="search-result-wrapper">
                                </div>
                                <div class="search-link search-noresult">
                                    No Result    </div>
                                <div class="search-link search-all-button">
                                    <i class="fa fa-search"></i> View All Result    </div>
                            </div>
                            </div> */}
                    <div className="quickicons-sec">
                      <a href="/faq" class="jeg_faq_toggle">
                        <span class="help-tip topfaqtxt">
                          <i class="fas fa-question-circle"></i>
                          <div class="infoHoverText">Faqs</div>
                        </span>
                      </a>
                      {/* <a href="/contact-us" class="jeg_contact_toggle">
                        <span class="help-tip topcontacttxt">
                          <i class="fas fa-phone-volume"></i>
                          <div class="infoHoverText">Contact Us</div>
                        </span>
                      </a> */}
                    </div>
                  </div>
                  <div className="headertopright clearfix">
                    <ul className="groupwebsite-list">
                      <li aria-haspopup="true" className="groupwebsitedropdown">
                        <a href="#">
                          Group Websites <i className="fas fa-angle-down"></i>
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <a
                              href="http://www.motilaloswalgroup.com/"
                              target="_blank"
                            >
                              Financial Services
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswal.com/"
                              target="_blank"
                            >
                              Broking &amp; Distribution
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswalgroup.com/Our-Businesses/Private-Wealth-Management"
                              target="_blank"
                            >
                              Private Wealth Management{" "}
                            </a>
                          </li>
                          <li>
                            <a href="http://www.ahfcl.com/" target="_blank">
                              Home Finance{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswalgroup.com/Our-Businesses/Institutional-Equities"
                              target="_blank"
                            >
                              Institutional Equities{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswalpe.com/"
                              target="_blank"
                            >
                              Private Equity
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswalgroup.com/Our-Businesses/Investment-Banking"
                              target="_blank"
                            >
                              Investment Banking{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswal.com/markets/commodity-market-live.aspx"
                              target="_blank"
                            >
                              Commodities{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.motilaloswalre.com/"
                              target="_blank"
                            >
                              Real Estate{" "}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="moamc-menu-menubox">
                  <div className="wsmain clearfix">
                    <nav className="wsmenu clearfix">
                      <a href="#" class="wsmenu_menu_close" onClick={openMobile}><i class="wsmenu_closeicon"></i></a>
                      <div
                        className="overlapblackbg"
                        onClick={openMobile}
                      ></div>
                      <ul className="groupwebsite-list amc-product-mob">
                        <li
                          aria-haspopup="true"
                          className="groupwebsitedropdown"
                        >
                          <label class="lbl">Choose</label>
                          <select
                            className="mobilet"
                            value={topKey}
                            onChange={(e) => topHeadingMobile(e)}
                          >
                            {TopJson.map((curr, index) => {
                              return (
                                <option
                                  value={curr.filter}
                                  id={`menu-item-70${index}`}
                                  key={index}
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704"
                                >
                                  {curr.Heading}
                                </option>
                              );
                            })}
                          </select>
                          {/* <a href="#">MUTUAL FUND<i className="fas fa-angle-down"></i></a> */}
                          {/* <ul className="sub-menu">
                                                    {TopJson.map((curr, index) => {
                                                        return (
                                                            <li id={`menu-item-70${index}`} key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-704"><a  onClick={() => topHeading(curr)}>{curr.Heading}</a></li>
                                                        )
                                                    })}
                                                
                                                </ul> */}
                        </li>
                      </ul>
                      {/* {HeaderMain[topKey] !== undefined ? (
                        <ul className="wsmenu-list">
                          {HeaderMain[topKey].map((curr, index) => {
                            console.log(HeaderMain, "HeaderMain");
                            return (
                              <li aria-haspopup="true" key={index}>
                                <a
                                  to={curr.url}
                                  className={`navtext ${
                                    activeNav.toLowerCase() ===
                                    curr.Heading.toLowerCase()
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => topHeading(curr)}
                                >
                                  <i className={curr.class}></i>{" "}
                                  <span>{curr.Heading}</span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null} */}

                      {HeaderMain[topKey] !== undefined ? (
                        <ul className="wsmenu-list">
                          {HeaderMain[topKey].map((curr, index) => {
                            console.log(HeaderMain, 'HeaderMain')
                            if (curr.Heading === "NEWS & INSIGHTS") {
                              return (
                                <li aria-haspopup="true" key={index}>
                                  <a
                                    // to={!mobileMode ? curr.url : ""}
                                    className={`navtext dropDownMainContainer ${(activeNav.toLowerCase() ===
                                      curr.Heading.toLowerCase() || history.location.pathname.includes("Media-Room"))
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={!mobileMode ? () => topHeading(curr) : () => topHeading(curr, false)}
                                  >
                                    <i className={curr.class}></i>{" "}
                                    <span className="dropDownHeader">{curr.Heading}</span>
                                    <div className="dropDownContainer">
                                      {
                                        curr.dropDown.map(item => {
                                          if (item.subDropDown) {
                                            return (
                                              <div className="subDropDownMainContainer">
                                                {
                                                  !mobileMode ?
                                                    <a href={item.url} className="dropDownItem">{item.title}</a>
                                                    :
                                                    <a className="dropDownItem">{item.title}</a>
                                                }
                                                <div className="subDropDownContainer">
                                                  {
                                                    item.subDropDown.map(subItem => {
                                                      return (
                                                        <a href={subItem.url} className="subDropDownItem">{subItem.title}</a>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <a href={item.url} className="dropDownItem">{item.title}</a>
                                            )
                                          }
                                        })
                                      }
                                    </div>
                                  </a>
                                </li>
                              )
                            } else {
                              return (
                                <li aria-haspopup="true" key={index}>
                                  <a
                                    to={curr.url}
                                    className={`navtext ${(activeNav.toLowerCase() ===
                                      curr.Heading.toLowerCase() && !history.location.pathname.includes("Media-Room"))
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() => topHeading(curr)}
                                  >
                                    <i className={curr.class}></i>{" "}
                                    <span>{curr.Heading}</span>
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      ) : null}

                      <ul className="wsmenu-list d-none">
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-home"></i> <span>Home</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i class="fas fa-users"></i> <span>About Us</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-piggy-bank"></i>{" "}
                            <span>Our Funds</span>
                          </a>
                          <div
                            className="wsshoptabing wtsdepartmentmenu clearfix"
                            style={{ display: "none" }}
                          >
                            <div className="wsshopwp clearfix">
                              <ul className="wstabitem clearfix">
                                <li className="wsshopa-active">
                                  <a href="#"> Equity funds</a>
                                  <div className="wstitemright clearfix wstitemrightactive">
                                    <div className="container-fluid">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <ul className="wstliststy02 clearfix">
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Multi Asset Fund{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Large & Midcap
                                                Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Liquid Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Focused 25 Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Equity Hybrid Fund
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <ul className="wstliststy02 clearfix">
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Long Term Equity
                                                Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Dynamic Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Ultra Short Term
                                                Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Midcap 30 Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Flexi cap Fund{" "}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <div className="wstbootslider clearfix">
                                            <div
                                              id="demo"
                                              className="carousel slide"
                                              data-ride="carousel"
                                            >
                                              {/* The slideshow  */}
                                              <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                  <Image image="prelogin-header/basket-banner.gif" />
                                                </div>
                                              </div>
                                              {/* Left and right controls */}
                                              <a
                                                className="carousel-control-prev"
                                                href="#demo"
                                                data-slide="prev"
                                              >
                                                {" "}
                                                <span className="carousel-control-prev-icon"></span>
                                              </a>
                                              <a
                                                className="carousel-control-next"
                                                href="#demo"
                                                data-slide="next"
                                              >
                                                {" "}
                                                <span className="carousel-control-next-icon"></span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <a href="#">Index funds</a>
                                  <div className="wstitemright clearfix">
                                    <div className="container-fluid">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <ul className="wstliststy02 clearfix">
                                            <li>
                                              <a href="#">
                                                Motilal Oswal S & P 500 Index
                                                Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty Midcap 150
                                                Index Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty Smallcap 250
                                                Index Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty Midcap 150
                                                Index Fund
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <ul className="wstliststy02 clearfix">
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty 500 Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty Next 50
                                                Index Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty 50 Index
                                                Fund
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nifty Bank Index
                                                Fund
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <div className="wstbootslider clearfix">
                                            <div
                                              id="demo"
                                              className="carousel slide"
                                              data-ride="carousel"
                                            >
                                              {/* The slideshow  */}
                                              <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                  <Image image="prelogin-header/basket-banner.gif" />
                                                </div>
                                              </div>
                                              {/* Left and right controls */}
                                              <a
                                                className="carousel-control-prev"
                                                href="#demo"
                                                data-slide="prev"
                                              >
                                                {" "}
                                                <span className="carousel-control-prev-icon"></span>
                                              </a>
                                              <a
                                                className="carousel-control-next"
                                                href="#demo"
                                                data-slide="next"
                                              >
                                                {" "}
                                                <span className="carousel-control-next-icon"></span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <a href="#">ETF FUNDS</a>
                                  <div className="wstitemright clearfix">
                                    <div className="container-fluid">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <ul className="wstliststy02 clearfix">
                                            <li>
                                              <a href="#">
                                                Motilal 5 Year G-Sec ETF
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal M50 ETF{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Midcap 100 ETF
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nasdaq 100 ETF{" "}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <div className="wstbootslider clearfix">
                                            <div
                                              id="demo"
                                              className="carousel slide"
                                              data-ride="carousel"
                                            >
                                              {/* The slideshow */}
                                              <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                  <Image image="prelogin-header/basket-banner.gif" />
                                                </div>
                                              </div>
                                              {/* Left and right controls */}
                                              <a
                                                className="carousel-control-prev"
                                                href="#demo"
                                                data-slide="prev"
                                              >
                                                {" "}
                                                <span className="carousel-control-prev-icon"></span>
                                              </a>
                                              <a
                                                className="carousel-control-next"
                                                href="#demo"
                                                data-slide="next"
                                              >
                                                {" "}
                                                <span className="carousel-control-next-icon"></span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <a href="#">FOF FUNDS</a>
                                  <div className="wstitemright clearfix">
                                    <div className="container-fluid">
                                      <div className="row">
                                        <div className="col-lg-8 col-md-12 clearfix">
                                          <ul className="wstliststy02 clearfix">
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Asset Allocation
                                                Passive FOF – Conservative
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Asset Allocation
                                                Passive FOF – Aggressive
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                Motilal Oswal Nasdaq 100 FOF
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <div className="wstbootslider clearfix">
                                            <div
                                              id="demo"
                                              className="carousel slide"
                                              data-ride="carousel"
                                            >
                                              {/* The slideshow */}
                                              <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                  <Image image="prelogin-header/basket-banner.gif" />
                                                </div>
                                              </div>
                                              {/* Left and right controls */}
                                              <a
                                                className="carousel-control-prev"
                                                href="#demo"
                                                data-slide="prev"
                                              >
                                                {" "}
                                                <span className="carousel-control-prev-icon"></span>
                                              </a>
                                              <a
                                                className="carousel-control-next"
                                                href="#demo"
                                                data-slide="next"
                                              >
                                                {" "}
                                                <span className="carousel-control-next-icon"></span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <a href="#">Special plans</a>
                                  <div className="wstitemright clearfix">
                                    <div className="container-fluid">
                                      <div className="row">
                                        <div className="col-lg-8 col-md-12 clearfix">
                                          <div className="card-container">
                                            <div
                                              id="card-cashflow"
                                              className="card"
                                            >
                                              <a href="#">
                                                <div className="card-content">
                                                  {/* dxp-icon */}
                                                  <div className="overlaybg"></div>
                                                  <Image image="prelogin-header/cashflow-img.svg" />
                                                  {/* dxp-icon-end */}
                                                  <div className="cta-a-container">
                                                    <p className="cta-a">
                                                      Cashflow
                                                      <svg
                                                        className="cta-icon"
                                                        height="10"
                                                        width="8"
                                                      >
                                                        {/* <use xa:href="#caret"></use> */}
                                                      </svg>
                                                    </p>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                            <div
                                              id="card-movi"
                                              className="card"
                                            >
                                              <a href="#">
                                                <div className="card-content">
                                                  <div className="overlaybg"></div>
                                                  <Image image="prelogin-header/movi-img.svg" />
                                                  <div className="cta-a-container">
                                                    <p className="cta-a">
                                                      MOVI
                                                      <svg
                                                        className="cta-icon"
                                                        height="10"
                                                        width="8"
                                                      >
                                                        {/* <use xa:href="#caret"></use> */}
                                                      </svg>
                                                    </p>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 clearfix">
                                          <div className="wstbootslider clearfix">
                                            <div
                                              id="demo"
                                              className="carousel slide"
                                              data-ride="carousel"
                                            >
                                              {/* The slideshow */}
                                              <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                  <Image image="prelogin-header/basket-banner.gif" />
                                                </div>
                                              </div>

                                              {/* Left and right controls */}
                                              <a
                                                className="carousel-control-prev"
                                                href="#demo"
                                                data-slide="prev"
                                              >
                                                {" "}
                                                <span className="carousel-control-prev-icon"></span>
                                              </a>
                                              <a
                                                className="carousel-control-next"
                                                href="#demo"
                                                data-slide="next"
                                              >
                                                {" "}
                                                <span className="carousel-control-next-icon"></span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-coins"></i> <span>Nav</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-file-download"></i>{" "}
                            <span>Downloads</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-calculator"></i>{" "}
                            <span>Tools</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-shield-alt"></i>{" "}
                            <span>Regulatory Updates</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-question-circle"></i>{" "}
                            <span>FAQ's</span>
                          </a>
                        </li>
                        <li aria-haspopup="true">
                          <a href="#" className="navtext">
                            <i className="fas fa-phone-volume"></i>{" "}
                            <span>Contact us</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  <div className="col-auto des-login-sec">
                    {quickInvest ? (
                      <a
                        href="/login?returnUrl=/transact/quick-invest"
                        className="headerquickinvest-btn"
                      >
                        <Image image="prelogin-header/quick-invest-icon.svg" />
                        Quick Invest
                      </a>
                    ) : null}
                    {loginFlag ? (
                      <a
                        href={pageURL}
                        onClick={() => headerUP()}
                        className="headerloginsec-btn"
                      >
                        <Image image="prelogin-header/login-icon.svg" /> LOGIN
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="col-auto des-search-sec">
                                <div className="jeg_nav_item jeg_search_wrapper jeg_search_popup_expand">
                                    <a href="#" className="jeg_search_toggle"><i className="fas fa-search"></i></a>
                                    <form action="#" method="get" className="jeg_search_form" target="_top">
                                        <input name="s" className="jeg_search_input" placeholder="Search..." type="text" value="" autocomplete="off" />
                                        <button type="submit" className="jeg_search_button btn"><i className="fa fa-search"></i></button>
                                    </form>

                                    <div className="jeg_search_result jeg_search_hide with_result">
                                        <div className="search-result-wrapper"></div>
                                        <div className="search-a search-noresult">
                                            No Result
                                        </div>
                                        <div className="search-a search-all-button">
                                            <i className="fa fa-search"></i> View All Result
                                        </div>
                                    </div>
                                </div> 
                            </div>*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Header);
