import { INIT_APP, VARIFY_OTP, PMS_CODE, MENU_ROLE_WISE } from "../types/initApp";

export const initApp = (payload) => ({
    type: INIT_APP,
    payload
})

export const varifyOtp = (payload) => ({
    type: VARIFY_OTP,
    payload
})
export const getPMSCode = (payload) => ({
    type: PMS_CODE,
    payload
})
export const menuRoleWise = (payload) => ({
    type: MENU_ROLE_WISE,
    payload,
})






