import { takeLatest, put, call, delay } from 'redux-saga/effects';
import * as type from '../../redux/types/trans';
import Api from '../../services/trans/trans';
import { push } from 'connected-react-router';

let allApi = new Api();

async function fetchUser(data) {
    let res = await allApi.common(data);
    return res;

}

function* fetchData(action) {
    let getMOH = action.payload;
    let payload = {
        "apiName": "OrderBook_DIT",
        "body": {
            ...getMOH
        }
    };

    let apiresponse = yield call(fetchUser, payload)
    console.log("apiresponse", apiresponse);
    let investment = 0;
    let redemption = 0;
    let counterInvestment = 0;
    if (apiresponse.hasOwnProperty('code') && apiresponse.code === 401) {
        yield put(push('/investonline/dist'));
        yield put({ type: type.ORDERBOOK_FAIL, Paylaod: "Logout", Data: { inprocess: [], investment: investment, redemption: redemption, counter: counterInvestment, holdingMode: null, processMode: null, transStatus: null } })
    } else {
        if (apiresponse.data.success) {
            let getRes = apiresponse.data.data.data;
            let inprocessData = [];
            let folioList = [];
            getRes.map((curr, index) => {
                folioList.push(curr.folio)
                if (curr.transtatus === "In process") {
                    inprocessData.push(curr)

                    if (curr.buyOrSell === "Buy") {
                        counterInvestment = counterInvestment + 1
                        investment = parseFloat(investment) + parseFloat(curr.amount)
                    } else {
                        redemption = parseFloat(redemption) + parseFloat(curr.amount)
                    }
                }
                //console.log(curr,'currOrderBook')
            })
            yield delay(100)
            let RemoveDublicateFolio = [...new Set(folioList)]
            let responseColl = collectHoldingMode(getRes);
            console.log("from saga", responseColl);
            yield put({ type: type.ORDERBOOK_SUCCESS, Paylaod: getRes, Data: { inprocess: inprocessData, investment: investment, redemption: redemption, counter: counterInvestment, holdingMode: responseColl.holdingMode, processMode: responseColl.processMode, transStatus: responseColl.transStatus, transType: responseColl.transType, AllFolio: RemoveDublicateFolio } })

        } else {
            yield put({ type: type.ORDERBOOK_FAIL, Paylaod: [], Data: { inprocess: [], investment: investment, redemption: redemption, counter: counterInvestment, holdingMode: null, processMode: null, transStatus: null, AllFolio: [] } })
        }
    }
}

export function* orderBookCall() {

    yield takeLatest(type.ORDERBOOK_PENDING, fetchData)
}

function collectHoldingMode(data) {
    console.log(data, "collectHoldingMode")
    let holdingMode = [];
    let processMode = [];
    let transStatus = [];
    let transType = [];

    if (data.length > 0) {
        data.map((curr, ind) => {
            console.log("curr.modeofHolding", curr.modeofHolding)
            if (holdingMode.indexOf(curr.modeofHolding) === -1) {
                holdingMode.push(curr.modeofHolding);
            }
            if (processMode.indexOf(curr.channel) === -1) {
                processMode.push(curr.channel)
            }
            if (transStatus.indexOf(curr.transtatus) === -1) {
                transStatus.push(curr.transtatus)
            }
            if (transType.indexOf(curr.txnType) === -1) {
                transType.push(curr.txnType)
            }

        })
        console.log("holdingMode->", holdingMode)
        console.log("processMode->", processMode)
    }

    return { holdingMode: holdingMode, processMode: processMode, transStatus: transStatus, transType: transType };
}

