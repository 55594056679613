import {
  takeLatest,
  put,
  call,
  delay,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  MF_DETAIL_PENDING,
  MF_DETAIL_SUCCESS,
  MF_DETAIL_FAILURE,
  MF_CATEGORY_PENDING,
  MF_CATEGORY_SUCCESS,
  MF_CATEGORY_FAILURE,
  MF_CATEGORY_DROP_PENDING,
  MF_CATEGORY_DROP_SUCCESS,
  STP_SCHEME_FAILURE,
  STP_SCHEME_SUCCESS,
  STP_SCHEME_PENDING,
} from "../redux/types/preloginType";
import { MFCatSuccuess } from "../redux/actions/preloginAction";
import Api from "../services/preLoginApi";

let allApi = new Api();

async function getFetch(data) {
  let ResData = await allApi.fundDetail(data);
  return ResData;
}

function* fetchData(action) {
  console.log("mfDetailSaga", action);
  let payload = action.payload;
  let apiresponse = yield call(getFetch, payload);
  console.log(apiresponse, "apiresponse");
  if (apiresponse.data.success) {
    yield put({ type: MF_DETAIL_SUCCESS, payload: apiresponse.data.data });
  } else {
    yield put({ type: MF_DETAIL_FAILURE, payload: null });
  }
  // yield put({ type: ABOUTUS_DATA_SUCCESS, payload: apiresponse.data })
}

export function* MFDetail() {
  console.info("MFDetail");
  yield takeLatest(MF_DETAIL_PENDING, fetchData);
}

// category page

function CreateDataSet(dropFilter, staticData) {
  console.log(dropFilter, "dropFilter");
  console.log(staticData, "staticData");
  let allKey = Object.keys(dropFilter);
  let modifyArray = [];
  console.log(allKey, "allKeyCustom");
  let dropOpt = [];

  console.log(allKey, "allKeySaga", staticData);
  allKey.forEach((element) => {
    let arrayData = [];
    console.log(element, "element");
    console.log(staticData[element], "foreach", dropFilter[element]);
    staticData[element].map((curr) => {
      console.log(curr, "currEch");
      arrayData.push(curr.fundoptionmerge);
      dropOpt.push(curr);
      if (curr.fundoptionmerge === dropFilter[element]) {
        modifyArray.push({ ...curr, selectBox: arrayData });
      }
    });
  });
  console.log("dropOpt", dropOpt);
  console.log(modifyArray, "modifyArray");
  return { modifyArray: modifyArray, dropOpt: dropOpt };
}

function* CategoryData() {
  let payload = {
    api_name: "FundCategoryNew",
    categoryType: "MF",
    type: "R", //ARN="R" IN=>"D"
  };
  let apiresponse = yield allApi.Category(payload);

  if (apiresponse.data.success) {
    let dataset = apiresponse.data.data;

    //  let dataset = {
    //         "CP": [{
    //             "schid": 2,
    //             "amccode": "127",
    //             "schcode": "CP",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Multicap 35 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 5,
    //             "prodcode": "CPGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 48615400000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 48615400000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MULTI CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 2,
    //             "amccode": "127",
    //             "schcode": "CP",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Multicap 35 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Dividend",
    //             "navid": 7,
    //             "prodcode": "CPPD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 954118000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 954118000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MULTI CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 2,
    //             "amccode": "127",
    //             "schcode": "CP",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Multicap 35 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 6,
    //             "prodcode": "CPGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 63339800000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 63339800000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MULTI CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 2,
    //             "amccode": "127",
    //             "schcode": "CP",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Multicap 35 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Dividend",
    //             "navid": 3,
    //             "prodcode": "CPDP",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 2806700000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 2806700000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MULTI CAP",
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "DE": [{
    //             "schid": 3,
    //             "amccode": "127",
    //             "schcode": "DE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Dynamic Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 15,
    //             "prodcode": "DEGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 1036840000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 1036840000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Smart Savings",
    //             "risk": "Moderately High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 3,
    //             "amccode": "127",
    //             "schcode": "DE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Dynamic Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Direct - Dividend - Annual",
    //             "navid": 11,
    //             "prodcode": "DEDA",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 17978900,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 17978900,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Smart Savings",
    //             "risk": "Moderately High",
    //             "fundoption": "Dividend",
    //             "divfreq": "Annual",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 3,
    //             "amccode": "127",
    //             "schcode": "DE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Dynamic Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Direct - Dividend - Quarterly",
    //             "navid": 13,
    //             "prodcode": "DEDQ",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 22863700,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 22863700,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Smart Savings",
    //             "risk": "Moderately High",
    //             "fundoption": "Dividend",
    //             "divfreq": "Quarterly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 3,
    //             "amccode": "127",
    //             "schcode": "DE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Dynamic Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 16,
    //             "prodcode": "DEGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 10314600000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 10314600000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Smart Savings",
    //             "risk": "Moderately High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 3,
    //             "amccode": "127",
    //             "schcode": "DE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Dynamic Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Regular - Dividend - Annual",
    //             "navid": 9,
    //             "prodcode": "DEAD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 306267000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 306267000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Smart Savings",
    //             "risk": "Moderately High",
    //             "fundoption": "Dividend",
    //             "divfreq": "Annual",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 3,
    //             "amccode": "127",
    //             "schcode": "DE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Dynamic Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Regular - Dividend - Quarterly",
    //             "navid": 17,
    //             "prodcode": "DEQD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 174282000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 174282000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Smart Savings",
    //             "risk": "Moderately High",
    //             "fundoption": "Dividend",
    //             "divfreq": "Quarterly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "FM": [{
    //             "schid": 5,
    //             "amccode": "127",
    //             "schcode": "FM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Midcap 30 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 22,
    //             "prodcode": "FMGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 8377520000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 8377520000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 5,
    //             "amccode": "127",
    //             "schcode": "FM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Midcap 30 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Dividend",
    //             "navid": 24,
    //             "prodcode": "FMPD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 46393100,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 46393100,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 5,
    //             "amccode": "127",
    //             "schcode": "FM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Midcap 30 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 23,
    //             "prodcode": "FMGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 8431740000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 8431740000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 5,
    //             "amccode": "127",
    //             "schcode": "FM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Midcap 30 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Dividend",
    //             "navid": 20,
    //             "prodcode": "FMDP",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 323067000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 323067000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "HE": [{
    //             "schid": 7,
    //             "amccode": "127",
    //             "schcode": "HE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Equity Hybrid Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 29,
    //             "prodcode": "HEGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 270735000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 270735000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Smart Savings",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 7,
    //             "amccode": "127",
    //             "schcode": "HE",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Equity Hybrid Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Hybrid Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 30,
    //             "prodcode": "HEGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 3527140000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 3527140000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Smart Savings",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "LF": [{
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 39,
    //             "prodcode": "LFGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 4106460000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 4106460000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Daily",
    //             "navid": 32,
    //             "prodcode": "LFDD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 51630900,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 51630900,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Daily",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Fortnightly",
    //             "navid": 33,
    //             "prodcode": "LFDF",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 114919,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 114921,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Fortnightly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Monthly",
    //             "navid": 34,
    //             "prodcode": "LFDM",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 7259330,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 7259330,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Monthly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Quarterly",
    //             "navid": 36,
    //             "prodcode": "LFDQ",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 727588,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 727590,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Quarterly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Weekly",
    //             "navid": 38,
    //             "prodcode": "LFDW",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 2335420,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 2335420,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Weekly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 31,
    //             "prodcode": "LFD3",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 514454,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 514457,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 40,
    //             "prodcode": "LFGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 4145910000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 4145910000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 41,
    //             "prodcode": "LFR3",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 2369530,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 2369530,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": null,
    //             "prodcode": null,
    //             "nav": null,
    //             "navrecdt": null,
    //             "navchng": null,
    //             "latestaum": null,
    //             "latestaumasondt": null,
    //             "monthlyaum": null,
    //             "monthlyaumasondt": null,
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": null,
    //             "prodcode": null,
    //             "nav": null,
    //             "navrecdt": null,
    //             "navchng": null,
    //             "latestaum": null,
    //             "latestaumasondt": null,
    //             "monthlyaum": null,
    //             "monthlyaumasondt": null,
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Daily",
    //             "navid": 42,
    //             "prodcode": "LFRD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 207882000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 207882000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Daily",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Fortnightly",
    //             "navid": 43,
    //             "prodcode": "LFRF",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 411077,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 411080,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Fortnightly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Monthly",
    //             "navid": 44,
    //             "prodcode": "LFRM",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 5764800,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 5764810,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Monthly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Quarterly",
    //             "navid": 46,
    //             "prodcode": "LFRQ",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 4013480,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 4013480,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Quarterly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 8,
    //             "amccode": "127",
    //             "schcode": "LF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Liquid fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Weekly",
    //             "navid": 48,
    //             "prodcode": "LFRW",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 12239000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 12239000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low to Moderate",
    //             "fundoption": "Dividend",
    //             "divfreq": "Weekly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "LM": [{
    //             "schid": 9,
    //             "amccode": "127",
    //             "schcode": "LM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Large and Midcap Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 51,
    //             "prodcode": "LMGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 1391120000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 1391120000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE & MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 9,
    //             "amccode": "127",
    //             "schcode": "LM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Large and Midcap Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Dividend",
    //             "navid": 53,
    //             "prodcode": "LMPD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 4301350,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 4301350,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE & MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 9,
    //             "amccode": "127",
    //             "schcode": "LM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Large and Midcap Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 52,
    //             "prodcode": "LMGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 3596430000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 3596430000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE & MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 9,
    //             "amccode": "127",
    //             "schcode": "LM",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Large and Midcap Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Dividend",
    //             "navid": 49,
    //             "prodcode": "LMDP",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 144392000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 144392000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE & MID CAP",
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "LT": [{
    //             "schid": 10,
    //             "amccode": "127",
    //             "schcode": "LT",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Long Term Equity Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "ELSS",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 56,
    //             "prodcode": "LTGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 4551020000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 4551020000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Tax Savings",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 10,
    //             "amccode": "127",
    //             "schcode": "LT",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Long Term Equity Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "ELSS",
    //             "fundoptionmerge": "Direct - Dividend",
    //             "navid": 58,
    //             "prodcode": "LTPD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 139477000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 139477000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Tax Savings",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 10,
    //             "amccode": "127",
    //             "schcode": "LT",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Long Term Equity Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "ELSS",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 57,
    //             "prodcode": "LTGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 12904700000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 12904700000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Tax Savings",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 10,
    //             "amccode": "127",
    //             "schcode": "LT",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Long Term Equity Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "ELSS",
    //             "fundoptionmerge": "Regular - Dividend",
    //             "navid": 55,
    //             "prodcode": "LTDP",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 548734000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 548734000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Tax Savings",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "MA": [{
    //             "schid": 11,
    //             "amccode": "127",
    //             "schcode": "MA",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Multi Asset Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 59,
    //             "prodcode": "MAGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 239768000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 239768000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Smart Savings",
    //             "risk": "Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 11,
    //             "amccode": "127",
    //             "schcode": "MA",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Multi Asset Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 60,
    //             "prodcode": "MAGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 1732760000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 1732760000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Smart Savings",
    //             "risk": "Moderate",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "MF": [{
    //             "schid": 13,
    //             "amccode": "127",
    //             "schcode": "MF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Focused 25 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 64,
    //             "prodcode": "MFGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 6390070000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 6390070000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 13,
    //             "amccode": "127",
    //             "schcode": "MF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Focused 25 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Direct - Dividend",
    //             "navid": 66,
    //             "prodcode": "MFPD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 65768300,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 65768300,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 13,
    //             "amccode": "127",
    //             "schcode": "MF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Focused 25 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 65,
    //             "prodcode": "MFGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 8110830000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 8110830000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE CAP",
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 13,
    //             "amccode": "127",
    //             "schcode": "MF",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Focused 25 Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Equity Fund",
    //             "fundoptionmerge": "Regular - Dividend",
    //             "navid": 62,
    //             "prodcode": "MFDP",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 362851000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 362851000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Dividend",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": "LARGE CAP",
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "N1": [{
    //             "schid": 15,
    //             "amccode": "127",
    //             "schcode": "N1",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Nasdaq 100 Fund of Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Fund Of Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 70,
    //             "prodcode": "N1GD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 8725220000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 8725220000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 15,
    //             "amccode": "127",
    //             "schcode": "N1",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Nasdaq 100 Fund of Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Fund Of Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 71,
    //             "prodcode": "N1GP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 7520480000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 7520480000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Wealth Creation",
    //             "risk": "Very High",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ],
    //         "US": [{
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Growth",
    //             "navid": 90,
    //             "prodcode": "USGD",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 121521000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 121521000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Daily",
    //             "navid": 83,
    //             "prodcode": "USDD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 2540900,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 2540900,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Daily",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Fortnightly",
    //             "navid": 84,
    //             "prodcode": "USDF",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 5617.07,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 5619.57,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Fortnightly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Monthly",
    //             "navid": 85,
    //             "prodcode": "USDM",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 166643,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 166645,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Monthly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Quarterly",
    //             "navid": 87,
    //             "prodcode": "USDQ",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 399563,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 399565,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Quarterly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Direct - Dividend - Weekly",
    //             "navid": 89,
    //             "prodcode": "USDW",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 3256670,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 3256680,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Direct",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Weekly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Growth",
    //             "navid": 91,
    //             "prodcode": "USGP",
    //             "nav": 0,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 161369000,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 161369000,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Growth",
    //             "divfreq": null,
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Daily",
    //             "navid": 92,
    //             "prodcode": "USRD",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 12469700,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 12469700,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Daily",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Fortnightly",
    //             "navid": 93,
    //             "prodcode": "USRF",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 205336,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 205339,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Fortnightly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Monthly",
    //             "navid": 94,
    //             "prodcode": "USRM",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 2309720,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 2309720,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Monthly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Quarterly",
    //             "navid": 96,
    //             "prodcode": "USRQ",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 467715,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 467717,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Quarterly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         },
    //         {
    //             "schid": 22,
    //             "amccode": "127",
    //             "schcode": "US",
    //             "amcname": "Motilal Oswal Mutual Fund",
    //             "schname": "Ultra Short Term Fund",
    //             "fundcategory": "Mutual Fund",
    //             "sebicategory": "Debt Fund",
    //             "fundoptionmerge": "Regular - Dividend - Weekly",
    //             "navid": 98,
    //             "prodcode": "USRW",
    //             "nav": 1,
    //             "navrecdt": "2020-12-21",
    //             "navchng": 1.2,
    //             "latestaum": 14888700,
    //             "latestaumasondt": "2021-01-15",
    //             "monthlyaum": 14888700,
    //             "monthlyaumasondt": "2020-12-31",
    //             "plan": "Regular",
    //             "objective": "Savings",
    //             "risk": "Low",
    //             "fundoption": "Dividend",
    //             "divfreq": "Weekly",
    //             "fundtype": "Open Ended",
    //             "marketcap": null,
    //             "schdesc": "This is Scheme Description"
    //         }
    //         ]
    //     };
    console.log(dataset, "datasetDaga");
    let enObj = {};
    for (let [key, value] of Object.entries(dataset)) {
      value.map((curr, index) => {
        if (curr.schcode === key) {
          enObj[key] = curr.fundoptionmerge;
        }
      });

      // SetDropFilter(enObj)
    }
    yield delay(100);
    let createdDataSet = CreateDataSet(enObj, dataset);
    console.log(enObj, "enObjSaga", createdDataSet.modifyArray);
    console.info("createdDataSet", createdDataSet);
    //yield put(MFCatSuccuess(dataset))
    yield put({
      type: MF_CATEGORY_SUCCESS,
      payload: {
        dropdown: enObj,
        modifyCat: createdDataSet.modifyArray,
        CatData: dataset,
        dropOpt: createdDataSet.dropOpt,
      },
    });
  } else {
    yield put({
      type: MF_CATEGORY_FAILURE,
      payload: { dropdown: {}, modifyCat: [], CatData: null },
    });
  }
  console.log(apiresponse, "apiresponse==>CategoryPAge");
}

export function* Category() {
  yield takeLatest(MF_CATEGORY_PENDING, CategoryData);
}

export function* STPCategoryData(action) {
  console.info("StpHOC");
  let payload = action.paylaod;
  // let payload = {
  //     "api_name": "SchemesForSTP"
  // };
  let apiresponse = yield allApi.STPCategory(payload);
  if (apiresponse.data.success) {
    let dataset = apiresponse.data.data;

    yield put({ type: STP_SCHEME_SUCCESS, payload: dataset });
  } else {
    yield put({ type: STP_SCHEME_FAILURE, payload: null });
  }
  console.log(apiresponse, "apiresponse==>CategoryPAge");
}

export function* STPCategory() {
  console.info("STPCategory calling");
  yield takeLatest(STP_SCHEME_PENDING, STPCategoryData);
}

// dropDown Selection
export const getProject = (state) => state.MfDetail.category;
function* FilterDrop(action) {
  console.log(action, "action.payload FilterDrop");
  let CatData = yield select();
  let stateCate = CatData.MfDetail.category;
  console.log(CatData, "select");
  let getActionDrop = action.paylaod;
  console.info("saga CategoryPAge", CatData, "=", getActionDrop);
  let mData = yield CreateDataSet(getActionDrop, stateCate);
  console.log("mDatasujeet", mData);
  yield put({
    type: MF_CATEGORY_SUCCESS,
    payload: {
      dropdown: getActionDrop,
      modifyCat: mData.modifyArray,
      CatData: stateCate,
    },
  });
  console.log("FilterDrop", action, "==>", mData);
}

export function* DropFilter() {
  yield takeEvery(MF_CATEGORY_DROP_PENDING, FilterDrop);
}
