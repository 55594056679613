import {
    GET_CONFIRMATION_INFO_FAILED, GET_CONFIRMATION_INFO_RESPONSE,
    GET_DIT_TOKEN_FAILED, GET_DIT_TOKEN_RESPONSE,
    SAVE_TRANSACTION_FAILED, SAVE_TRANSACTION_RESPONSE,
    CANCEL_TRANSACTION_FAILED, CANCEL_TRANSACTION_RESPONSE,
} from "../types/confirmation";

const initialState = {
    confirmationInfo: {},
    placeholser2: {},
    saveTransaction: {},
    cancelTransaction: {},
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_CONFIRMATION_INFO_RESPONSE:
            return { ...state, confirmationInfo: action.payload }
        case GET_CONFIRMATION_INFO_FAILED:
            return { ...state, confirmationInfo: action.payload }

        case GET_DIT_TOKEN_RESPONSE:
            return { ...state, placeholser2: action.payload }
        case GET_DIT_TOKEN_FAILED:
            return { ...state, placeholser2: action.payload }

        case SAVE_TRANSACTION_RESPONSE:
            return { ...state, saveTransaction: action.payload }
        case SAVE_TRANSACTION_FAILED:
            return { ...state, saveTransaction: action.payload }

        case CANCEL_TRANSACTION_RESPONSE:
            return { ...state, cancelTransaction: action.payload }
        case CANCEL_TRANSACTION_FAILED:
            return { ...state, cancelTransaction: action.payload }

        default:
            return state
    }

}