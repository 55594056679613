import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
// import Header from '../../components/headerNew/header';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import { useDispatch } from 'react-redux';
import { fetchWithWait } from '../../helper/methods';
import { removeCookie } from '../../redux/actions/removeCookies';
import MSnackBar from "../../components/molecules/MSnackBar";
import './sessionExpiryPopup.css';
import globalVariable from "../globalVariable";

const loginPagePathName = process.env.REACT_APP_MODE === 'production' ? '/investonline/dist' : '/investonline/dist';

const PublicRoute = ({ component: Component, restricted, path, ...rest }) => {
    let dispatch = useDispatch();
    const [showPopup, setshowPopup] = useState(false);
    const [snackBar, setSnackBar] = useState({ message: 'Your session has been expired!! Kindly login again.', severity: 'error' })
    const singleTimeRef = useRef(false);

    useEffect(() => {
        // Subscribe to the 'httpError' event
        console.log("rest.location.pathname", rest.location.pathname)
        // if (rest.location?.pathname != "/onboarding/personal") {
        const onHttpError = (status) => {
            if (status === "failed" && !singleTimeRef.current) {
                singleTimeRef.current = true;
                setshowPopup(true);
                localStorage.setItem('sessionExpired', true);
                localStorage.setItem('triggered401', true);
            }
        };

        let events = globalVariable.getPropertyByName("events");
        events.subscribe('httpError', onHttpError);


        // }
        console.log('mounting PrivateRoute');

        return (() => {
            // Clean up the subscription when the component unmounts
            events.unsubscribe('httpError', onHttpError);
            console.log('unmounting PrivateRoute');
        })

    }, []);

    const popupClose = (e) => {
        fetchWithWait({ dispatch, action: removeCookie() }).then(res => {
            setshowPopup(false);
            localStorage.removeItem('sessionExpired');
            localStorage.removeItem('triggered401');

            if (window.location.pathname !== loginPagePathName) {
                window.location.href = loginPagePathName;
            }
        }).catch(err => {
            console.log('err in removeCookie: ', err);
            setshowPopup(false);
            localStorage.removeItem('sessionExpired');
            localStorage.removeItem('triggered401');
            if (window.location.pathname !== loginPagePathName) {
                window.location.href = loginPagePathName;
            }
        })
    }

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <React.Fragment>
            <Header path={path} />
            <Route {...rest} render={props => (
                restricted ?
                    <Redirect to="/dashboard" />
                    : <Component {...props} />
            )} />
            <Footer path={path} />

            {
                showPopup ?
                    <>
                        <MSnackBar
                            open={showPopup}
                            hideDuration={1000}
                            handleClose={popupClose}
                            severity={snackBar.severity}
                            message={snackBar.message}
                        />
                    </>
                    :
                    ''
            }
        </React.Fragment>
    );
};

export default PublicRoute;