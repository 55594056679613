import * as type from '../../types/trans';


let init = {
    sipAmount: 0,
    LumsumAmount: 0,
    ISIPAmount: 0,
    cartIdArray: [],
    CART_ARRAY: [],
    utrNo: "",
    cartId: null,
    showButton: null,
    dirDistCartId_new: [],
    regDistCartId_new: [],
    error: {},
    addDist: "false",
    selectFolio: null,
    euin: [],
};



export default function cartDetails(state = init, action) {
    switch (action.type) {
        case type.SIP_AMOUNT:
            console.log(action.payload, 'payloadSIP')
            return {
                ...state,
                sipAmount: action.payload.sipAmount
            }

        case type.LUM_AMOUNT:
            console.log(action.payload, 'lumsumAmount')
            return {
                ...state,
                LumsumAmount: action.payload.LumsumAmount
            }

        case type.ISIP_AMOUNT:
            console.log(action.payload, 'ISIP_AMOUNT')
            return {
                ...state,
                ISIPAmount: action.payload.isipAmount
            }

        case type.CART_ID:
            console.log(action.payload, 'cartId_Array----->')
            return {
                ...state,
                cartIdArray: action.payload.cartIdArray
            }

        case type.CART_ARRAY:
            console.log(action.payload.CART_ARRAY, 'CART_ARRAYACTION')
            return {
                ...state,
                CART_ARRAY: action.payload.CART_ARRAY
            }

        case type.BANK_NAME:
            console.log(action.payload, 'BANK_NAME')
            return {
                ...state,
                BANK_NAME: action.payload
            }

        case type.BANK_NUMBER:
            console.log(action.payload, 'BANK_NUMBER')
            return {
                ...state,
                BANK_NUMBER: action.payload
            }
        case type.CHEQUE_MODE:
            console.log(action.payload, 'CHEQUE_MODE')
            return {
                ...state,
                CHEQUE_MODE: action.payload
            }

        case type.ISIP_TYPE:
            console.log(action.payload, 'ISIP_TYPE')
            return {
                ...state,
                ISIP_TYPE: action.payload
            }

        case type.SIP_TYPE:
            console.log(action.payload, 'SIP_TYPE')
            return {
                ...state,
                SIP_TYPE: action.payload
            }

        case type.LUMP_TYPE:
            console.log(action.payload, 'LUMP_TYPE')
            return {
                ...state,
                LUMP_TYPE: action.payload
            }

        case type.UTR_NO:
            console.log(action.payload, 'UTR_NO')
            return {
                ...state,
                utrNo: action.payload
            }

        case type.BROKER_CART_ID:
            console.log(action.payload, 'BROKER_CART_ID')
            return {
                ...state,
                cartId: action.payload.Id,
                showButton: action.payload.flag
            }

        case type.PUSH_DIST_CARTID_REG_BROKER:
            console.log("PUSH_DIST_CARTID_REG", action.payload);
            return {
                ...state,
                regDistCartId_new: action.payload
            }

        case type.PUSH_DIST_CARTID_DIR_BROKER:
            console.log("PUSH_DIST_CARTID_DIR", action.payload);
            return {
                ...state,
                dirDistCartId_new: action.payload
            }

        case type.SHOW_BANK_ERR:
            return {
                ...state,
                error: action.payload
            }

        case type.SHOW_UTRNO_ERR:
            return {
                ...state,
                error: action.payload
            }

        case type.SET_DIST_FLAG:
            return {
                ...state,
                addDist: action.payload
            }

        case type.PROCESS_FOLIOS_FOR_SELECT:
            console.log("PROCESS_FOLIOS_FOR_SELECT", action);
            return {
                ...state,
                selectFolio: action.payload
            }

        case type.EUIN_LIST_RESPONSE:
            return {
                ...state,
                euin: action.payload
            }

        case type.EUIN_LIST_FAIL:
            return {
                ...state,
                euin: action.payload
            }

        default:
            return state
    }
}