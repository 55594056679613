import React, { useState, useEffect } from 'react';
import Image from '../../../components/atom/image';
import { Link, withRouter } from 'react-router-dom';
import './shared.css';
import { useSelector } from 'react-redux';

const Navbar = (props) => {
  const [navType, setNavType] = useState('');
  let getArn = useSelector(state => state.Auth.arnDetails);

  const [hit, setHit] = useState(false);

  const navTypeClick = (data) => {
    if (navType === data) {
      setNavType('')
    } else {
      setNavType(data)
    }

  }
  const handleLogout = () => {

    props.logout();
    // props.history.push({
    //     pathname: '/mf',
    //     // your data array of objects
    // })
  }
  const closePOpUp = () => {
    // setHit(hit?false:true)
    let getNavbar = document.querySelectorAll('.navbarTop .nav-item');
    console.log(getNavbar, 'getNavbar')
    getNavbar.forEach((curr) => {
      console.log(curr.childNodes, 'Sujeet')
      if (curr.childNodes[1] !== undefined) {
        curr.childNodes[1].classList.remove('show');
      }

      //  let checkClass= curr.getElementByClassName('.show');
      //console.log(checkClass,'checkClass')
    })


  }
  useEffect(() => {
    console.log(props, 'navbarProps')
    document.getElementById('mobileBAR').classList.remove("open");
  }, [props])
  useEffect(() => {
    //document.querySelector('button.navbar-toggler.navbar-toggler-right').classList.toggle('open');


    let getNavbar = document.querySelectorAll('.navbarTop .nav-item');
    getNavbar.forEach((el) => {
      el.addEventListener('mouseover', function (curr) {
        console.log(el.childNodes, 'el.childNodes', curr)
        if (el.childNodes[1] !== undefined) {
          el.childNodes[1].classList.add('show');
          el.classList.add('show');
        }

      });
      el.addEventListener('mouseout', function (curr) {
        console.log(el.childNodes, 'el.childNodesover', curr)
        if (el.childNodes[1] !== undefined) {
          el.childNodes[1].classList.remove('show');
          el.classList.remove('show');
        }

      });
    })
  }, [])

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    document.querySelector('button.navbar-toggler.navbar-toggler-right').classList.toggle('open');
  }

  let localData = localStorage.getItem('uniqueKey');
  let customerName = "";
  if (getArn !== null) {

    customerName = getArn.fName
    // customerName = jsonData.nameAsOnPan;
  }
  // if (localData) {
  //     let jsonData = JSON.parse(localData);
  //     customerName =getArn.fName
  //    // customerName = jsonData.nameAsOnPan;
  // }

  return (
    <React.Fragment>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/business-dashboard">
            {/* <img src="assets/images/logo.svg" alt="logo" /> */}
            <Image image="nav/moamc-logo-new.png" alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="/business-dashboard">
            <Image image="nav/mf-m-min-logo.svg" alt="logo" />
            {/* <img src="assets/images/logo-mini.svg" alt="logo" /> */}
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <span className="mdi mdi-menu"></span>
          </button>
          {/* <div className="search-field d-none d-xl-block">
      <form className="d-flex align-items-center h-100" action="#">
        <div className="input-group">
          <div className="input-group-prepend bg-transparent">
            <i className="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" className="form-control bg-transparent border-0" placeholder="Search products"/>
        </div>
      </form>
    </div> */}
          <ul className="navbar-nav navbar-nav-right navbarTop">
            {/* <li className="nav-item  dropdown d-none d-md-block" onClick={()=>{navTypeClick('report')}}>
        <a className="nav-link dropdown-toggle" id="reportDropdown" href="#" data-toggle="dropdown" aria-expanded="false"> Reports </a>
        <div className="dropdown-menu navbar-dropdown" aria-labelledby="reportDropdown">
          <a className="dropdown-item" href="#">
            <i className="mdi mdi-file-pdf mr-2"></i>PDF </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">
            <i className="mdi mdi-file-excel mr-2"></i>Excel </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">
            <i className="mdi mdi-file-word mr-2"></i>doc </a>
        </div>
      </li>
      <li className="nav-item  dropdown d-none d-md-block">
        <a className="nav-link dropdown-toggle" id="projectDropdown" href="#" data-toggle="dropdown" aria-expanded="false"> Projects </a>
        <div className="dropdown-menu navbar-dropdown" aria-labelledby="projectDropdown">
          <a className="dropdown-item" href="#">
            <i className="mdi mdi-eye-outline mr-2"></i>View Project </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">
            <i className="mdi mdi-pencil-outline mr-2"></i>Edit Project </a>
        </div>
      </li>
      <li className="nav-item nav-language dropdown d-none d-md-block">
        <a className="nav-link dropdown-toggle" id="languageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <div className="nav-language-icon">
            <i className="flag-icon flag-icon-us" title="us" id="us"></i>
          </div>
          <div className="nav-language-text">
            <p className="mb-1 text-black">English</p>
          </div>
        </a>
        <div className="dropdown-menu navbar-dropdown" aria-labelledby="languageDropdown">
          <a className="dropdown-item" href="#">
            <div className="nav-language-icon mr-2">
              <i className="flag-icon flag-icon-ae" title="ae" id="ae"></i>
            </div>
            <div className="nav-language-text">
              <p className="mb-1 text-black">Arabic</p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">
            <div className="nav-language-icon mr-2">
              <i className="flag-icon flag-icon-gb" title="GB" id="gb"></i>
            </div>
            <div className="nav-language-text">
              <p className="mb-1 text-black">English</p>
            </div>
          </a>
        </div>
      </li> 
      <li className="nav-item dropdown">
        <a className="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-email-outline"></i>
          <span className="count-symbol bg-success"></span>
        </a>
        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
          <h6 className="p-3 mb-0 bg-primary text-white py-4">Messages</h6>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item preview-item">
            <div className="preview-thumbnail">
              <Image class="profile-pic" image="header/face/face4.jpg" alt="image"/>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Mark send you a message</h6>
              <p className="text-gray mb-0"> 1 Minutes ago </p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item preview-item">
            <div className="preview-thumbnail">
                <Image class="profile-pic" image="header/face/face2.jpg" alt="image"/>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Cregh send you a message</h6>
              <p className="text-gray mb-0"> 15 Minutes ago </p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item preview-item">
            <div className="preview-thumbnail">
                <Image class="profile-pic" image="header/face/face3.jpg" alt="image"/>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Profile picture updated</h6>
              <p className="text-gray mb-0"> 18 Minutes ago </p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <h6 className="p-3 mb-0 text-center">4 new messages</h6>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
          <i className="mdi mdi-bell-outline"></i>
          <span className="count-symbol bg-danger"></span>
        </a>
        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
          <h6 className="p-3 mb-0 bg-primary text-white py-4">Notifications</h6>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item preview-item">
            <div className="preview-thumbnail">
              <div className="preview-icon bg-success">
                <i className="mdi mdi-calendar"></i>
              </div>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject font-weight-normal mb-1">Event today</h6>
              <p className="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item preview-item">
            <div className="preview-thumbnail">
              <div className="preview-icon bg-warning">
                <i className="mdi mdi-settings"></i>
              </div>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject font-weight-normal mb-1">Settings</h6>
              <p className="text-gray ellipsis mb-0"> Update dashboard </p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item preview-item">
            <div className="preview-thumbnail">
              <div className="preview-icon bg-info">
                <i className="mdi mdi-link-variant"></i>
              </div>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject font-weight-normal mb-1">Launch Admin</h6>
              <p className="text-gray ellipsis mb-0"> New admin wow! </p>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <h6 className="p-3 mb-0 text-center">See all notifications</h6>
        </div>
      </li>*/}
            {/* <li className="nav-item">
              <Link to="xyz" className="nav-link count-indicator " id="">
                <i className="feedback_headericon"></i> Feedback
              </Link>
            </li>
            <li className="nav-item">
              <Link to="abc" className="nav-link count-indicator " id="">
                <i className="manual_headericon"></i> Manual
              </Link>
            </li> */}

            <li className="nav-item">
              <Link to="/Cart" className="nav-link count-indicator " id="cartDropdown">
                <i className="mastercart_headericon"></i> <div className="cart-text">Master Cart</div>
                {/* <span class="badge badge-up badge-pill">6</span> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Cart" className="nav-link count-indicator " id="cartDropdown">
                <i className="mastercart_headericon"></i> <div className="cart-text">Master Cart</div>
                {/* <span class="badge badge-up badge-pill">6</span> */}
              </Link>
            </li>
            <li className="nav-item nav-profile dropdown">
              <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <div className="nav-profile-img">
                  <Image image="nav/profile-icon.png" alt="image" />
                  {/* <img src="assets/images/faces/face28.png" alt="image"> */}
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{customerName}</p>
                </div>
              </a>
              <div className="dropdown-menu navbar-dropdown dropdown-menu-right p-0 border-0 font-size-sm" aria-labelledby="profileDropdown" data-x-placement="bottom-end">
                {/* <div className="p-3 text-center bg-primary">
            
            <Image class="img-avatar img-avatar48 img-avatar-thumb" image="header/face/face28.png" alt="image"/>
          </div> */}
                <div className="p-2">
                  <h5 onClick={closePOpUp} className="dropdown-header  text-uppercase pl-2 text-dark">User Options</h5>
                  {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="#">
              <span>Inbox</span>
              <span className="p-0">
                <span className="badge badge-primary">3</span>
                <i className="mdi mdi-email-open-outline ml-1"></i>
              </span>
            </a> */}

                  <Link to="/profile?euinlist" onClick={closePOpUp} className="dropdown-item py-1 d-flex align-items-center justify-content-between">
                    <span>EUIN List</span>

                  </Link>

                  <Link to="/profile" onClick={closePOpUp} className="dropdown-item py-1 d-flex align-items-center justify-content-between">
                    <span>Profile</span>
                    <span className="p-0">
                      {/* <span className="badge badge-success">1</span> */}
                      <i className="mdi mdi-account-outline ml-1"></i>
                    </span>
                  </Link>
                  {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="javascript:void(0)">
              <span>Settings</span>
              <i className="mdi mdi-settings"></i>
            </a> */}
                  <div role="separator" className="dropdown-divider"></div>
                  <h5 className="dropdown-header text-uppercase  pl-2 text-dark mt-2">Actions</h5>
                  {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="#">
              <span>Lock Account</span>
              <i className="mdi mdi-lock ml-1"></i>
            </a> */}
                  <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" onClick={handleLogout}>
                    <span>Log Out</span>
                    <i className="mdi mdi-logout ml-1"></i>
                  </a>
                </div>
              </div>
            </li>

          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" id="mobileBAR" type="button" data-toggle="offcanvas" onClick={() => toggleOffcanvas()}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default withRouter(Navbar)