import { MF_DETAIL_PENDING, MF_DETAIL_SUCCESS, MF_DETAIL_FAILURE, MF_CATEGORY_PENDING, MF_CATEGORY_SUCCESS, MF_CATEGORY_FAILURE, MF_DETAIL, COMPARE_FUND,
STP_SCHEME_SUCCESS, STP_SCHEME_FAILURE, STP_SCHEME_PENDING,MF_DETAIL_KM_DROPDOWN } from '../types/preloginType';


let init = {
    fundDetailStatus: false,
    fundDetail: null,
    category: null,
    categoryStatus: false,
    modifyCat: [],
    dropDown: {},
    dropOpt: {},
    fundCategory: null,
    catWiseFund: null,
    mfDetailDropDown: null,
   
    compareFund: [],
    stpCategory: []
};

export default function MfDetail(state = init, action) {
    switch (action.type) {
        case MF_DETAIL_PENDING:
            console.info("pending")
            return {
                ...state,
                fundDetailStatus: true,
            }

        case MF_DETAIL_SUCCESS:
            console.info("success")
            return {
                ...state,
                fundDetailStatus: false,
                fundDetail: action.payload
            }

        case MF_DETAIL_FAILURE:
            return {
                ...state,
                fundDetailStatus: false,
                fundDetail: action.payload
            }

        case MF_CATEGORY_PENDING:
            // console.info("pending")
            return {
                ...state,
                categoryStatus: true,
            }

        case MF_CATEGORY_SUCCESS:
            console.info(action.payload, 'MF_CATEGORY_SUCCESS')
            let { dropdown, modifyCat, CatData, dropOpt } = action.payload
            console.log(modifyCat, 'modifyCatReducer')

            return {
                ...state,
                categoryStatus: false,
                category: CatData,
                modifyCat: modifyCat,
                dropDown: dropdown,
                dropOpt: dropOpt
            }

        case MF_CATEGORY_FAILURE:
            return {
                ...state,
                categoryStatus: false,
                category: CatData,
                modifyCat: modifyCat,
                dropDown: dropdown,
            }
        case MF_DETAIL:
            let { category } = { ...state };
            // let mfDetailDrop = category[action.payload];
            // let modefy = []
            // mfDetailDrop.map((curr) => {
            //     modefy.push({ label: curr.schname + "-" + curr.fundoptionmerge, value: curr.prodcode })
            // })
            // console.log(modefy, 'data')
            return {
                ...state,
                fundCategory: action.payload,
                // catWiseFund: mfDetailDrop,
                // mfDetailDropDown: modefy
            }
        case MF_DETAIL_KM_DROPDOWN:
            return {
                ...state,
                mfDetailDropDown: action.payload
            }     
        case COMPARE_FUND:
            console.log(state.modifyCat, 'statemodifyCat')
            let { compareFund } = { ...state };

            let dataArray = compareFund;
            let dataCheck = compareFund.indexOf(action.payload);

            if (dataCheck === -1) {

                dataArray.push(action.payload)


            } else {
                const index = dataArray.indexOf(action.payload);
                if (index > -1) {
                    dataArray.splice(index, 1);
                }
                //dataArray.pop()
            }
            console.log('redux', dataArray, action.payload)
            return {
                ...state,
                compareFund: dataArray
            }

        case STP_SCHEME_SUCCESS:
            return{
                ...state,
                stpCategory: action.payload
            }
        
        case STP_SCHEME_FAILURE:
            return{
                ...state,
                stpCategory: action.payload
            }

        case STP_SCHEME_PENDING:
            return{
                ...state,
            }
        
        default:
            return state
    }
}